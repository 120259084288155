import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Button, notification, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { RouteType } from "src/constants/routeTypes"
import { setHeaderBackIcon } from "src/utils/slices/appHeaderSlice"
import { DEEP_LINK_URL } from "src/constants"

import {
  useTopupSart,
  useRefundSart,
  useAddTransaction,
  useSendDigitalCurrencyToExternalWallet
} from "../api/user"
import TransactionSummaryComponent from "./TransactionSummaryComponent"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const TransactionConfirmationScreen: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  const {
    screenType,
    transactionType,
    amountRequested,
    requestPayload,
    totalAmount,
    feeTitle,
    netAmountCalulated,
    uniqueList
  } = location?.state || {}

  const {
    mutate: topupSart,
    isLoading: topupSartLoading,
    error: topupSartError,
    isSuccess: topupSartSuccess,
    data: topupSartData
  } = useTopupSart()

  const {
    mutate: refundToken,
    isLoading: refundSartLoading,
    error: refundSartError,
    isSuccess: refundSartSuccess,
    data: refundSartData
  } = useRefundSart()

  const {
    mutate: addTransaction,
    isLoading: isAddTransactionDataLoading,
    error: isAddTransactionDataError,
    isSuccess,
    data: addTransactionData
  } = useAddTransaction()

  const {
    mutate: sendDigitalCurrencyExternalWallet,
    isLoading: isSendExternalWalletLoading,
    error: sendExternalWalletError,
    isSuccess: isSendExternalWalletSuccess,
    data: sendExternalWalletData
  } = useSendDigitalCurrencyToExternalWallet()

  useEffect(() => {
    setLoading(false)

    if (topupSartData) {
      // navigate to payment success screen
      const transactionId = topupSartData?.uuid
      navigate(`${RouteType.INTERNALTRANSPAYMENTDETAILS}`, {
        state: { from: RouteType.DASHBOARD, transactionId }
      })

      dispatch(setHeaderBackIcon(false))
    }
    if (refundSartData) {
      // navigate to payment success screen
      const transactionId = refundSartData?.uuid
      navigate(`${RouteType.INTERNALTRANSPAYMENTDETAILS}`, {
        state: { from: RouteType.DASHBOARD, transactionId }
      })

      dispatch(setHeaderBackIcon(false))
    }
    if (addTransactionData) {
      // navigate to payment success screen
      const transactionId = addTransactionData?.uuid
      navigate(`${RouteType.INTERNALTRANSPAYMENTDETAILS}`, {
        state: { from: RouteType.DASHBOARD, transactionId }
      })

      dispatch(setHeaderBackIcon(false))
    }
    if (sendExternalWalletData) {
      // navigate to payment success screen
      const transactionId = sendExternalWalletData?.uuid
      navigate(`${RouteType.INTERNALTRANSPAYMENTDETAILS}`, {
        state: { from: RouteType.DASHBOARD, transactionId }
      })

      dispatch(setHeaderBackIcon(false))
    }
  }, [
    topupSartData,
    refundSartData,
    addTransactionData,
    sendExternalWalletData
  ])

  // Handle the error
  useEffect(() => {
    setLoading(false)
    let message = ""
    if (
      topupSartError ||
      refundSartError ||
      isAddTransactionDataError ||
      sendExternalWalletError
    ) {
      if (topupSartError) {
        message = topupSartError.message
      }
      if (refundSartError) {
        message = refundSartError.message
      }
      if (isAddTransactionDataError) {
        message = isAddTransactionDataError.message
      }
      if (sendExternalWalletError) {
        message = sendExternalWalletError.message
      }

      // Remore previusLocation
      localStorage.removeItem("previusLocation")
      dispatch(setHeaderBackIcon(false))

      notification["error"]({
        message: t("anErrorOccurred"),
        description:
          message === "Failed to fetch"
            ? t(`ERROR_MESSAGE.${"Network request failed"}`)
            : message,
        placement: "bottom"
      })
    }
  }, [
    topupSartError,
    refundSartError,
    isAddTransactionDataError,
    sendExternalWalletError
  ])

  function routeToRequest(screenType: string | undefined) {
    setLoading(true)
    dispatch(setHeaderBackIcon(true))
    // set previus route
    localStorage.setItem("previusLocation", "Dashboard")

    // Remore formdata
    localStorage.removeItem("formData")

    const institution = false
    //
    if (institution) {
      const { bankAccountNumber, fiatAmount, amount } = requestPayload
      const deepLinkURL = `${DEEP_LINK_URL}?src=${encodeURIComponent(
        bankAccountNumber
      )}&ben=${null}&amo=${encodeURIComponent(
        fiatAmount
      )}&sar=${encodeURIComponent(amount)}`

      // Navigate to the deep link URL
      window.location.href = deepLinkURL
    } else {
      switch (screenType) {
        case "loadToken":
          topupSart(requestPayload)
          break
        case "refundUnspent":
          refundToken(requestPayload)
          break
        case "addTransaction":
          addTransaction(requestPayload)
          break
        case "merchantOnline":
        case "merchantOffline":
        case "peer_2_peer":
          sendDigitalCurrencyExternalWallet(requestPayload)
          break
        default:
          break
      }
    }
  }

  return (
    <div
      className="geidea mb-50 internal-transfer-payment-details"
      style={{ alignItems: "center", marginTop: "70px" }}
    >
      <TransactionSummaryComponent
        transactionType={transactionType}
        amountRequested={amountRequested}
        feeConfigList={
          screenType == "loadToken" ? uniqueList : requestPayload?.feeConfigData
        }
        netFeeKeyName={feeTitle || ""}
        totalAmount={totalAmount}
        totalAmountText={
          transactionType == "Transfer"
            ? t("tokensToBeTransferred")
            : transactionType === "Refund"
            ? t("amountToBeRedeem")
            : transactionType == "Transfer" || transactionType === "Payment"
            ? t("tokensToBePaid")
            : t("tokensToBeReceived")
        }
        netAmountCalulated={netAmountCalulated}
      />

      <Button
        size="large"
        className={"active-btn-style"}
        style={{
          position: "absolute",
          top: "69%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          marginTop: "50%",
          width: "90%",
          fontWeight: 600,
          height: "45px"
        }}
        onClick={() => routeToRequest(screenType)}
        disabled={loading}
      >
        {t("confirm")}{" "}
        {loading ? (
          <Spin
            className="spin-style"
            style={{ marginLeft: "5px" }}
            indicator={antIcon}
          />
        ) : null}
      </Button>
    </div>
  )
}

export default TransactionConfirmationScreen
