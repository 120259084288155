import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Radio, Button, notification, Popconfirm, Skeleton } from "antd"
import { useTranslation } from "react-i18next"
import { UserBalanceContext, UserDetailsContext } from "src/context/User"
import { RouteType } from "src/constants/routeTypes"

import { TransactionTypeControl } from "../../api/constants"
import {
  useGetFiatExchangeRate,
  useTransactionValidationConfig,
  useGetUserProfileDetails
} from "../../api/user"
import {
  getAmountTwoDecimal,
  findPercentage,
  sartTxt,
  getFiatCurrency
} from "../../utils/Utils"
import PrivacyPolicyAgreement from "../PrivacyPolicyAgreement"
import Accounts from "../Accounts"
import AlertPopupModal from "../AlertPopupModal"

const RefundPage = () => {
  const { t } = useTranslation()
  const navigation = useNavigate()
  const [refundClicked] = useState(false)
  const [isWalletSelected] = useState(false)
  const [isBankAccountSelected, setIsBankAccountSelected] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const [isChecked, setChecked] = useState(false)
  const { userBalances } = useContext(UserBalanceContext)
  const { userDetails } = useContext(UserDetailsContext)
  const [isValidationErrorTextInput, setIsValidationErrorTextInput] =
    useState(false)
  const [isFinalAmountNegative, setIsFinalAmountNegative] = useState(false)
  const [totalAmount, setTotalAmount] = useState<any>()
  const [totalDeduction, setTotalDeduction] = useState<number>()
  const [selectedAccount, setSelectedAccount] = useState<number | string>("")
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false)
  const [fiatExchangeRates, setFiatExchangeRates] = useState<number>(0)

  const alertMsg = !isWalletSelected
    ? t("wantToRedeemInBank")
    : t("wantToRedeemInBankWallet")

  const {
    data: profileData,
    isFetching: isFetchingProfileDetails,
    refetch: refetchGetUserProfileDetails,
    error: errorProfileDetails
  } = useGetUserProfileDetails()

  const {
    data: fiatExchangeRatesData,
    refetch: refetchExchangeRates,
    isFetching: isFetchingExhchangeRates,
    isSuccess: isExchangeRateSuccess
  } = useGetFiatExchangeRate(
    getFiatCurrency(userDetails) + "&transactionType=REFUND"
  )

  const {
    data: transactionConfigData,
    refetch: refetchTransactionConfigData,
    isSuccess: isTransactionConfigSuccess,
    error: transactionConfigError
  } = useTransactionValidationConfig(
    TransactionTypeControl.UNSPENT_DIGITAL_CURRENCY_REFUND
  )

  useEffect(() => {
    if (fiatExchangeRatesData) {
      setFiatExchangeRates(fiatExchangeRatesData.SAR)
    }
  }, [fiatExchangeRatesData])

  useEffect(() => {
    if (totalDeduction && Number(userBalances) <= totalDeduction) {
      setButtonDisabled(false)
    } else {
      if (
        (isBankAccountSelected || isWalletSelected) &&
        userBalances > 0 &&
        isChecked
      ) {
        setButtonDisabled(true)
      } else {
        setButtonDisabled(false)
      }
    }
  }, [
    isBankAccountSelected,
    isWalletSelected,
    userBalances,
    isChecked,
    totalDeduction,
    userBalances
  ])

  useEffect(() => {
    if (selectedAccount !== "") {
      setIsBankAccountSelected(true)
    }
  }, [selectedAccount])

  useEffect(() => {
    // check limit for transactions
    if (transactionConfigData) {
      //console.log("Transaction config API response refund----> ", sarTokens > 0 )
      const totalDeduction =
        userBalances > 0 && transactionConfigData?.feeConfig
          ? calculateDeductions()
          : 0.0 // Calculate total fees Applied

      const totalAmount =
        (Number(userBalances) - Number(totalDeduction)) / fiatExchangeRates

      setTotalDeduction(totalDeduction)
      setTotalAmount(totalAmount.toFixed(2))

      if (totalAmount > 0) {
        setIsFinalAmountNegative(false)
      } else {
        setIsFinalAmountNegative(true)
      }
    } else {
      const totalDeduction = 0.0

      const totalAmount =
        (Number(userBalances) - Number(totalDeduction)) / fiatExchangeRates

      setTotalDeduction(totalDeduction)
      setTotalAmount(totalAmount.toFixed(2))

      if (totalAmount > 0) {
        setIsFinalAmountNegative(false)
      } else {
        setIsFinalAmountNegative(true)
      }
    }
  }, [transactionConfigData, isTransactionConfigSuccess, fiatExchangeRates])

  function onRefundClick() {
    const fiatClaculated = transactionConfigData?.feeConfig
      ? userBalances - parseFloat(calculateDeductions()?.toFixed(2))
      : userBalances

    if (refundClicked == false) {
      if (
        userBalances > 0 &&
        (isBankAccountSelected || isWalletSelected) &&
        isChecked &&
        fiatExchangeRatesData
      ) {
        const transactionConfirmationDataParam = {
          requestPayload: {
            tokenAsset: "SART",
            amount: transactionConfigData?.feeConfig
              ? userBalances - parseFloat(calculateDeductions()?.toFixed(2))
              : userBalances.toString(),
            bankAccountNumber: selectedAccount,
            fiatAsset: getFiatCurrency(userDetails),
            fiatAmount: (
              Number(fiatClaculated) / fiatExchangeRatesData!.SAR
            ).toFixed(2),
            feeConfigData: getAllFeeApplied() ? getAllFeeApplied() : null,
            totalFeeApplied: calculateDeductions()
              ? Number(calculateDeductions()?.toFixed(2))
              : 0
          },
          isBankAccountSelected: isBankAccountSelected,
          transactionType: "Refund", // if Redeem Unspent  transaction type changed to refund globally in project
          screenType: "refundUnspent",
          amountRequested: `${sartTxt} ${userBalances.toFixed(2)}`,
          totalAmount: `${getFiatCurrency(userDetails)} ${Number(
            totalAmount
          ).toFixed(2)}`,
          totalFee: calculateDeductions(),
          tokenAsset: "SART",
          transactionConfigData: transactionConfigData,
          ttc: TransactionTypeControl.UNSPENT_DIGITAL_CURRENCY_REFUND,
          feeTitle: t("netRedeemTokens"),
          isFinalAmountNegative: isFinalAmountNegative,
          netAmountCalulated: `${sartTxt} ${
            transactionConfigData?.feeConfig
              ? (userBalances - calculateDeductions())?.toFixed(2)
              : userBalances
          }`,
          totalAmountText: t("amountToBeRedeem")
        }

        navigation(RouteType.TRANSACTION_CONFIRMATION, {
          state: transactionConfirmationDataParam
        })
      }
    }
  }

  const getAllFeeApplied = () => {
    if (transactionConfigData?.feeConfig) {
      const FeeApplied = transactionConfigData?.feeConfig.map((fee: any) => {
        if (fee.feeType == "Percentage") {
          const feeAmount = findPercentage(fee.feeAmount, Number(userBalances))

          if (
            (fee?.feeMinimumAmount && feeAmount < fee.feeMinimumAmount) ||
            feeAmount == fee.feeMinimumAmount
          ) {
            fee[fee.feeAmount] = fee.feeMinimumAmount
          } else if (
            (fee?.feeMaximumAmount && feeAmount > fee.feeMaximumAmount) ||
            feeAmount == fee.feeMaximumAmount
          ) {
            fee[fee.feeAmount] = fee.feeMaximumAmount
          } else {
            fee[fee.feeAmount] = feeAmount
          }
        } else {
          fee[fee.feeAmount] = fee.feeAmount
        }

        return {
          ["currencyType"]: fee.currencyType,
          ["feeId"]: fee.feeId,
          ["feeName"]: fee.feeName,
          ["feeCalculatedAmount"]: fee[fee.feeAmount].toFixed(2),
          ["feeType"]: fee.feeType,
          ["enteredAmount"]: Number(userBalances),
          ["feeAmount"]: fee.feeAmount
        }
      })
      console.log("FeeApplied ", FeeApplied)
      return FeeApplied
    }
  }

  const calculateDeductions = () => {
    if (transactionConfigData?.feeConfig) {
      const totalFee = transactionConfigData?.feeConfig.reduce(
        (total: number, curr: any) => {
          if (curr.feeType == "Percentage") {
            const feeAmount = findPercentage(
              curr.feeAmount,
              Number(userBalances)
            )
            console.log("feeAmount ", feeAmount)
            if (
              (curr.feeMinimumAmount && feeAmount < curr.feeMinimumAmount) ||
              feeAmount == curr.feeMinimumAmount
            ) {
              total = total + curr.feeMinimumAmount
            } else if (
              (curr.feeMaximumAmount && feeAmount > curr.feeMaximumAmount) ||
              feeAmount == curr.feeMaximumAmount
            ) {
              total = total + curr.feeMaximumAmount
            } else {
              total = total + feeAmount
            }
          } else {
            total = total + curr.feeAmount
          }
          return total
        },
        0
      )

      return totalFee
    } else {
      return 0.0
    }
  }

  const handleChange = () => {
    setChecked(!isChecked)
    setIsValidationErrorTextInput(false)
  }

  const handleAccount = (selectedAccount: number | string) => {
    setSelectedAccount(selectedAccount)
  }

  return (
    <>
      <div className="whiteFullWidth"></div>
      <div className="row">
        <div className="mb-40" style={{ zIndex: 1 }}>
          {contextHolder}
          <div className="header-available-balance">
            <p className="main-font-color" style={{ marginTop: "65px" }}>
              {t("availableRedeemTokens")}
            </p>
          </div>
          <div>
            <button
              style={{
                width: "100%",
                marginBottom: "0px",
                textAlign: "left",
                fontSize: "18px",
                marginTop: "5px",
                borderRadius: "5px",
                height: "55px"
              }}
              type="button"
              className="border-button-refund"
              disabled={true}
            >
              {sartTxt}{" "}
              <span
                style={{
                  fontSize: "24px",
                  marginLeft: "10px",
                  fontWeight: "600",
                  color: "grayMedium",
                  fontFamily: "Rubik-Regular"
                }}
              >
                {getAmountTwoDecimal(userBalances)}
              </span>
            </button>
          </div>

          <h6
            style={{
              textAlign: "center",
              fontSize: "12px",
              marginTop: "5px"
            }}
          >
            {t("allAvailableTokensToRedeemedInAccount")}
          </h6>
          {totalAmount && totalAmount > 0 && (
            <h6
              style={{
                textAlign: "center",
                marginTop: "4px",
                fontSize: "12px"
              }}
            >
              {t("youGet")}
              {` ${getFiatCurrency(userDetails)}`}{" "}
              {totalAmount ? (
                totalAmount
              ) : (
                <Skeleton.Button active={true} size={"small"} />
              )}
            </h6>
          )}
          {totalAmount && totalAmount <= 0 && (
            <div
              style={{
                textAlign: "center",
                marginTop: "4px",
                fontSize: "14px",
                color: "#f00"
              }}
            >
              {Number(userBalances) !== 0.0
                ? Number(userBalances) === totalDeduction
                  ? t("lessBalanceToRedeem")
                  : `Minimum ${sartTxt} ${totalDeduction} ` +
                    t(`requiredToRedeem`)
                : t("noBalanceToBeRedeemed")}
            </div>
          )}

          <div className="margin-block mt-3">
            <hr
              style={{
                backgroundColor: "rgb(169 164 164)",
                width: "110%",
                marginLeft: "-5%",
                marginTop: "-5px"
              }}
            />
            <h5 className="mt-3" style={{ color: "blackishGray" }}>
              {" "}
              {t("selectRedeemTo")}
            </h5>
          </div>
          {/* Invoke accounts component */}
          <Accounts
            handleAccount={handleAccount}
            selectedAccount={selectedAccount}
          />
          {/* PrivacyPolicyAgreement */}
          <PrivacyPolicyAgreement
            isChecked={isChecked}
            isValidationErrorTextInput={isValidationErrorTextInput}
            handleChange={handleChange}
            isDisabled={userBalances > 0 ? false : true}
          />
          <Popconfirm
            placement="top"
            title={alertMsg}
            description={""}
            onConfirm={onRefundClick}
            okText={t("yes")}
            cancelText={t("no")}
            disabled={isButtonDisabled ? false : true}
            className={
              isButtonDisabled
                ? "redeemNutton active-btn-style"
                : "redeemNutton default-btn-style"
            }
          >
            <Button
              size={"large"}
              className={
                isButtonDisabled ? "active-btn-style" : "default-btn-style"
              }
              style={{
                width: "100%",
                height: "50px",
                cursor: isButtonDisabled ? "pointer" : "not-allowed"
              }}
            >
              {t("continue")}&nbsp;&nbsp;
            </Button>
          </Popconfirm>
        </div>
      </div>
      {profileData && !profileData?.isActivationFeeCharged && (
        <AlertPopupModal isVisible={true} />
      )}
    </>
  )
}

export default RefundPage
