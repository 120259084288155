import React, { ReactNode, useContext } from "react"
import { UserContext } from "src/context/User"

const SignedInUser: React.FC = ({ children }: { children?: ReactNode }) => {
  const { user, isLoading, setUser, error, verified } = useContext(UserContext)
  // console.log(user, verified)
  if (user && !verified) {
    setUser(null)
    sessionStorage.removeItem("currentUser")
    sessionStorage.removeItem("currentToken")
  }
  return <>{!isLoading && verified && user && children}</>
}

export default SignedInUser
