import React, { useState, useEffect } from "react"
import { Col, Button, notification, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import CryptoJS from "crypto-js"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSavePasscodeHash } from "src/api/user"
import { RouteType } from "src/constants/routeTypes"

import AesUtil from "../AesUtil"
import NumericKeyboardPasscode from "./NumericKeyboardPasscode"
import "./OTPInputStyles.scss"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const Passcode: React.FC = (props) => {
  const { t } = useTranslation()
  const CODE_LENGTH = 6
  const navigation = useNavigate()
  const [passcodeArray, setPasscodeArray] = useState(["", "", "", "", "", ""])
  const [confirmPasscodeArray, setConfirmPasscodeArray] = useState([
    "",
    "",
    "",
    "",
    "",
    ""
  ])
  const [isPasscode, setIsPasscode] = useState<boolean>(false)
  const [passcode, setPasscode] = useState<string>("")
  const [confirmPasscode, setConfirmPasscode] = useState<string>("")
  const [loader, setLoader] = useState<boolean>(false)
  const [isButtonDisable, setIsButtonDisable] = useState<boolean>(false)

  const {
    mutate: savePasscodeHash,
    isLoading: savePasscodeHashcodeLoading,
    error: savaPasscodeHashcodeError,
    isSuccess: savaPasscodeHashcodeSuccess,
    data: savaPasscodeHashcodeData
  } = useSavePasscodeHash()

  const encryptData = (plainText: string) => {
    const iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex)
    const salt = CryptoJS.lib.WordArray.random(128 / 8).toString(
      CryptoJS.enc.Hex
    )

    const aesUtil = new AesUtil(128, 1000)
    const ciphertext = aesUtil.encrypt(salt, iv, "passcode", plainText)

    return iv + "::" + salt + "::" + ciphertext
  }

  useEffect(() => {
    if (savaPasscodeHashcodeSuccess) {
      setPasscode("")
      setConfirmPasscode("")
      setLoader(false)
      notification["success"]({
        message: "Nification",
        description: "Passcode has been set successfully",
        placement: "bottom"
      })

      navigation(RouteType.SETTINGS)
    }
  }, [savaPasscodeHashcodeSuccess])

  // After enter passcode
  useEffect(() => {
    setIsButtonDisable(false)
    if (passcode?.length === CODE_LENGTH) {
      setIsPasscode(true)
    } else {
      setIsPasscode(false)
    }
  }, [passcode])

  // Compare passcode with confirmation passcode
  useEffect(() => {
    if (
      passcode?.length === CODE_LENGTH &&
      confirmPasscode?.length === CODE_LENGTH
    ) {
      if (passcode === confirmPasscode) {
        setLoader(true)
        setIsButtonDisable(false)
        savePasscodeHash({
          passcodeHash: encryptData(passcode)
        })
      } else {
        notification["error"]({
          message: t("anErrorOccurred"),
          description: "Your passcode doesn't match.",
          placement: "bottom"
        })
        setPasscodeArray(["", "", "", "", "", ""])
        setConfirmPasscodeArray(["", "", "", "", "", ""])
        setPasscode("")
        setConfirmPasscode("")
        setIsButtonDisable(false)
      }
    }
  }, [passcode, confirmPasscode])

  const onNext = () => {
    setIsButtonDisable(true)
    if (passcode?.length < CODE_LENGTH) {
      if (passcode?.length === 0) {
        notification["error"]({
          message: t("anErrorOccurred"),
          description: "Please enter passcode code.",
          placement: "bottom"
        })
      } else {
        notification["error"]({
          message: t("anErrorOccurred"),
          description: "Passcode must be 6 digits.",
          placement: "bottom"
        })
      }
    }
  }

  const handleOTPChange = (value: string) => {
    setIsButtonDisable(false)

    const newOTP = isPasscode ? [...confirmPasscodeArray] : [...passcodeArray]

    const passcodeKey = isPasscode ? confirmPasscodeArray : passcodeArray

    const index =
      value === ""
        ? passcodeKey.join("").split("").length - 1
        : passcodeKey.join("").split("").length
    newOTP[index] = value

    isPasscode ? setConfirmPasscodeArray(newOTP) : setPasscodeArray(newOTP)

    isPasscode
      ? setConfirmPasscode(
          value === ""
            ? confirmPasscode.substr(0, passcode.length - 1)
            : confirmPasscode + "" + value
        )
      : setPasscode(
          value === ""
            ? passcode.substr(0, passcode.length - 1)
            : passcode + "" + value
        )
  }

  return (
    <div className="geidea mb-50" style={{ marginTop: "70px" }}>
      <Col>
        <h6
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            fontSize: "16px",
            color: "#2D2D2D",
            fontStyle: "rubik"
          }}
        >
          {isPasscode ? "Confirm Passcode" : "Enter Passcode"}
        </h6>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "12px",
            fontSize: "14px",
            color: "#2D2D2D",
            fontStyle: "rubik"
          }}
        >
          This code protect your privacy, please set your app authentication
          method.
        </div>
      </Col>
      <Col
        className="readonly-otp-container"
        style={{ marginTop: "50px", marginLeft: "5%" }}
      >
        <div className="otp-container">
          {(isPasscode ? confirmPasscodeArray : passcodeArray).map(
            (value, index) => (
              <input
                key={index}
                type="text"
                value={value}
                className={`custom-input ${value && "filled"}`}
              />
            )
          )}
        </div>
      </Col>
      <div style={{ marginTop: "100px" }}></div>
      <NumericKeyboardPasscode
        value={isPasscode ? confirmPasscode : passcode || ""}
        onChange={(value) => handleOTPChange(value)}
        maxValueLength={CODE_LENGTH}
      />
      <Col>
        {loader || isButtonDisable ? (
          <Button size="large" className="passCodeButton">
            Next{" "}
            {!isButtonDisable ? (
              <Spin
                style={{ color: "#000", marginLeft: "5px" }}
                indicator={antIcon}
              />
            ) : null}
          </Button>
        ) : (
          <Button onClick={onNext} size="large" className="passCodeButton">
            Next
          </Button>
        )}
      </Col>
    </div>
  )
}

export default Passcode
