import React from "react"
import { Modal } from "antd"
import { useNavigate } from "react-router-dom"
import { RouteType } from "src/constants/routeTypes"
import { useTranslation } from "react-i18next"

interface Props {
  title?: string
  isVisible?: boolean
}

const AlertPopupModal = ({ title, isVisible }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleConfirm = () => {
    navigate(RouteType.DASHBOARD)
  }

  return (
    <Modal
      className="alert-modal"
      title={title || t("pleaseActivateYourWallet")}
      visible={isVisible}
      onOk={handleConfirm}
      okText={t("ok")}
      cancelButtonProps={{ style: { display: "none" } }}
      closeIcon={<span></span>}
      maskClosable={false}
      keyboard={false}
    ></Modal>
  )
}

export default AlertPopupModal
