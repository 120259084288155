import QRCode from "react-qr-code"
import React, { createRef, useState, useEffect, useContext } from "react"
import { Modal, Skeleton, Button, message } from "antd"
import { useTranslation } from "react-i18next"
import { createFileName, useScreenshot } from "use-react-screenshot"
import { UserDetailsContext } from "src/context/User"

import { useAddresses, useGetEncryptedData } from "../api/user"
import "./scanQrCode/ScanQRCode.scss"

const SaveUserStaticQrModal = () => {
  const { t } = useTranslation()
  const ref = createRef()
  const [isModalVisible, setIsModalVisible] = useState<string>("show")
  const savedSuccessTitle = t("imageSaved")
  const [encryptedText, setEncryptedText] = useState("")
  const { userDetails } = useContext(UserDetailsContext)

  const {
    mutate: getEncryptedData,
    isLoading: isGetEncryptedDataLoading,
    error: getEncryptedDataError,
    isSuccess: isGetEncryptedDataSuccess,
    data: getEncryptedSuccessData
  } = useGetEncryptedData()

  const {
    data: addresses,
    isFetching: addressesIsFetching,
    error: addressesError,
    isSuccess: addressesIsSuccess
  } = useAddresses()

  useEffect(() => {
    const response = addresses
    if (response) {
      const walletAddress = addresses?.find(
        (obj) => obj?.asset === "SART"
      )?.address
      if (walletAddress) {
        getEncryptedData({
          data: `transactionID:00000|blockchainAddress:${walletAddress}|type:SART|transactionAmount:0|merchantId:000000|posId:000000|merchantDisplayName:${userDetails?.email}`
          //walletAddresses
        })
      }
    }
  }, [addressesIsSuccess])

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  })

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download)

  const download = (image: any, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a")
    a.href = image
    a.download = createFileName(extension, name)
    a.click()

    // hide modal
    setIsModalVisible("hide")
    localStorage.setItem("userStaticQr", "hide")

    // alert message
    message.success(savedSuccessTitle)
  }

  useEffect(() => {
    if (isGetEncryptedDataSuccess && getEncryptedSuccessData) {
      const response = getEncryptedSuccessData
      setEncryptedText(response?.toString())
    }
  }, [isGetEncryptedDataSuccess, getEncryptedSuccessData])

  const handleCancel = () => {
    setIsModalVisible("hide")
    localStorage.setItem("userStaticQr", "hide")
  }

  return (
    <>
      <Modal
        title={t("saveQRCode")}
        visible={isModalVisible === "show" ? true : false}
        onCancel={handleCancel}
        className="modalStyle saveUserStaticQrModal"
        footer={null}
        bodyStyle={{ borderRadius: "50px" }}
      >
        <>
          <div
            style={{ padding: 20, marginTop: 40 }}
            ref={ref as React.RefObject<HTMLDivElement>}
          >
            {encryptedText ? (
              <QRCode
                style={{
                  height: "auto",
                  maxWidth: "98%",
                  width: "98%",
                  marginLeft: "1%"
                }}
                value={encryptedText}
                viewBox={`0 0 256 256`}
              />
            ) : (
              <Skeleton.Node
                className="skeletonImageBig"
                active={true}
              ></Skeleton.Node>
            )}

            <h6
              className="main-font-color"
              style={{
                marginLeft: "-6%",
                marginTop: 20,
                marginBottom: 10,
                fontFamily: "Rubik-Medium",
                fontWeight: 500
              }}
            >
              {t("userId")}
            </h6>
            <div
              className="wdz-wallet-address-card"
              style={{
                display: "flex",
                backgroundColor: "#F6F6F6",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: "-6%",
                width: "112%",
                marginBottom: "25px",
                borderColor: "#EDEDED"
              }}
            >
              <div
                className="overflow-text"
                onClick={() =>
                  navigator.clipboard.writeText("Copy this text to clipboard")
                }
              >
                {userDetails?.email}
              </div>
            </div>
          </div>

          <Button
            size="large"
            className="cancelQRButton default-btn-style"
            onClick={handleCancel}
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: "15%",
              width: "70%",
              fontFamily: "Rubik-Medium"
            }}
          >
            {t("saveLater")}
          </Button>
          <Button
            size="large"
            onClick={() => {
              downloadScreenshot()
            }}
            className="active-btn-style"
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: "15%",
              width: "70%",
              background: "#FFA600",
              height: 50,
              marginTop: "50px",
              marginBottom: "10px",
              fontFamily: "Rubik-Medium"
            }}
          >
            <img src="../images/SaveIcon.svg" />
            &nbsp; {t("saveToGallery")}
          </Button>
        </>
      </Modal>
    </>
  )
}

export default SaveUserStaticQrModal
