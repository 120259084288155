import React, { useState, useEffect, useContext } from "react"
import { t } from "i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { UserDetailsContext } from "src/context/User"

const Profile = () => {
  const location = useLocation()
  const { userDetails } = useContext(UserDetailsContext)

  return (
    <>
      <div className="geidea mb-50" style={{ marginTop: "100px" }}>
        <div className="row">
          <div>
            <div
              style={{
                paddingLeft: "15px",
                paddingRight: "15px"
              }}
            >
              <div
                style={{
                  height: "60px",
                  backgroundColor: "#f2f2f2",
                  display: "flex",
                  paddingTop: "19px",
                  borderRadius: "10px"
                }}
              >
                <h6
                  className="m-0 flex-start"
                  style={{
                    paddingLeft: "18px"
                  }}
                >
                  {t("userId")}:
                </h6>
                <h6
                  className="m-0"
                  style={{
                    position: "absolute",
                    right: "40px"
                  }}
                >
                  {userDetails?.email}
                </h6>
              </div>
            </div>

            <div
              style={{
                height: "30px",
                backgroundColor: "#FAFAFA"
              }}
            ></div>

            {/* Phone number */}
            <div
              style={{
                paddingLeft: "15px",
                paddingRight: "15px"
              }}
            >
              <div
                style={{
                  height: "60px",
                  backgroundColor: "#f2f2f2",
                  display: "flex",
                  paddingTop: "19px",
                  borderRadius: "10px"
                }}
              >
                <h6
                  className="m-0 flex-start"
                  style={{
                    paddingLeft: "18px"
                  }}
                >
                  {t("phoneNumber")}:
                </h6>
                <h6
                  className="m-0"
                  style={{
                    position: "absolute",
                    right: "45px"
                  }}
                >
                  {userDetails?.phoneNumber == null
                    ? "-"
                    : userDetails?.phoneNumber}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile
