import { useTranslation } from "react-i18next"

import { EndpointKey } from "./constants"
import { ErrorType } from "./errorTypes"

const useGeneralErrorMessages: () => { [key in ErrorType]?: string } = () => {
  const { t } = useTranslation()
  return {
    [ErrorType.BAD_REQUEST]: t("ERROR_MESSAGE.BAD_REQUEST"),
    [ErrorType.UNAUTHORIZED]: t("ERROR_MESSAGE.UNAUTHORIZED"),
    [ErrorType.FORBIDDEN]: t("ERROR_MESSAGE.FORBIDDEN"),
    [ErrorType.NOT_FOUND]: t("ERROR_MESSAGE.NOT_FOUND"),
    [ErrorType.INTERNAL_SERVER_ERROR]: t("ERROR_MESSAGE.INTERNAL_SERVER_ERROR"),
    [ErrorType.INVALID_INPUT_FORMAT]: t("ERROR_MESSAGE.INVALID_INPUT_FORMAT"),
    [ErrorType.USER_NOT_FOUND]: t("ERROR_MESSAGE.USER_NOT_FOUND"),
    [ErrorType.TOKEN_EXPIRED]: t("TOKEN_EXPIRED"),
    [ErrorType.INVALID_WALLET_ADDRESS]: t(
      "ERROR_MESSAGE.INVALID_WALLET_ADDRESS"
    ),
    [ErrorType.UNKNOWN_WALLET_ERROR]: t("UNKNOWN_WALLET_ERROR"),
    [ErrorType.REFUND_ALREADY_EXISTS_ERROR]: t("REFUND_ALREADY_EXISTS_ERROR"),
    [ErrorType.WALLET_DISABLED_RECIPIENT]: t(
      "ERROR_MESSAGE.WALLET_DISABLED_RECIPENT"
    ),
    [ErrorType.WALLET_DISABLED]: t("ERROR_MESSAGE.WALLET_DISABLED"),
    [ErrorType.BLOCKCHAIN_INSUFFICIENT_AMOUNT]: t(
      "ERROR_MESSAGE.BLOCKCHAIN_INSUFFICIENT_AMOUNT"
    ),
    [ErrorType.OTHER_BANK_TRANSFER_NOT_ALLOWED]: t(
      "ERROR_MESSAGE.OTHER_BANK_TRANSFER_NOT_ALLOWED"
    )
  }
}

export const useErrorMessages: () => {
  [key in EndpointKey]: {
    [key in ErrorType]?: string
  }
} = () => {
  const { t } = useTranslation()
  const generalErrorMessages = useGeneralErrorMessages()
  return {
    getUser: {
      ...generalErrorMessages
    },
    sendPhoneOTP: {
      ...generalErrorMessages,
      [ErrorType.PHONE_NUMBER_ALREADY_EXISTS]: t(
        "ERROR_MESSAGE.PHONE_NUMBER_ALREADY_EXISTS"
      ),
      [ErrorType.UNKNOWN_TWILIO_ERROR]: t("ERROR_MESSAGE.UNKNOWN_TWILIO_ERROR"),
      [ErrorType.INVALID_PHONE_NUMBER]: t("ERROR_MESSAGE.INVALID_PHONE_NUMBER")
    },
    userDetailsAndEmailOTP: {
      ...generalErrorMessages,
      [ErrorType.UNVERIFIED_PHONE_NUMBER]: t(
        "ERROR_MESSAGE.UNVERIFIED_PHONE_NUMBER"
      ),
      [ErrorType.EMAIL_ALREADY_EXISTS]: t("ERROR_MESSAGE.EMAIL_ALREADY_EXISTS"),
      [ErrorType.FRAUDULENT_USER]: t("ERROR_MESSAGE.FRAUDULENT_USER"),
      [ErrorType.UNKNOWN_SEON_ERROR]: t("ERROR_MESSAGE.UNKNOWN_SEON_ERROR")
    },
    merchant: {
      ...generalErrorMessages,
      [ErrorType.MERCHANT_ALREADY_EXISTS]: t(
        "ERROR_MESSAGE.MERCHANT_ALREADY_EXISTS"
      )
    },
    sendOTP: {
      ...generalErrorMessages,
      [ErrorType.PHONE_NUMBER_ALREADY_EXISTS]: t(
        "ERROR_MESSAGE.PHONE_NUMBER_ALREADY_EXISTS"
      ),
      [ErrorType.UNKNOWN_TWILIO_ERROR]: t("ERROR_MESSAGE.UNKNOWN_TWILIO_ERROR"),
      [ErrorType.INVALID_PHONE_NUMBER]: t("ERROR_MESSAGE.INVALID_PHONE_NUMBER")
    },
    verifyOTP: {
      ...generalErrorMessages,
      [ErrorType.INCORRECT_CODE]: t("ERROR_MESSAGE.INCORRECT_CODE"),
      [ErrorType.VERIFICATION_NOT_FOUND]: t("ERROR_MESSAGE.INCORRECT_CODE"),
      [ErrorType.PHONE_NUMBER_ALREADY_EXISTS]: t(
        "ERROR_MESSAGE.PHONE_NUMBER_ALREADY_EXISTS"
      ),
      [ErrorType.PHONE_NUMBER_DOES_NOT_EXISTS]: t(
        "ERROR_MESSAGE.PHONE_NUMBER_DOES_NOT_EXISTS"
      ),
      [ErrorType.UNKNOWN_TWILIO_ERROR]: t("ERROR_MESSAGE.UNKNOWN_TWILIO_ERROR")
    },
    savePasscode: {
      ...generalErrorMessages,
      [ErrorType.SALT_KEY_NOT_FOUND]: t("ERROR_MESSAGE.SALT_KEY_NOT_FOUND"),
      [ErrorType.WALLET_DISABLED]: t("ERROR_MESSAGE.WALLET_DISABLED")
    },
    verifyPhoneOTP: {
      ...generalErrorMessages,
      [ErrorType.INCORRECT_CODE]: t("ERROR_MESSAGE.INCORRECT_CODE"),
      [ErrorType.PHONE_NUMBER_ALREADY_EXISTS]: t(
        "ERROR_MESSAGE.PHONE_NUMBER_ALREADY_EXISTS"
      ),
      [ErrorType.PHONE_NUMBER_DOES_NOT_EXISTS]: t(
        "ERROR_MESSAGE.PHONE_NUMBER_DOES_NOT_EXISTS"
      ),
      [ErrorType.UNKNOWN_TWILIO_ERROR]: t("ERROR_MESSAGE.UNKNOWN_TWILIO_ERROR")
    },
    verifyEmailOTPAndCreateUser: {
      ...generalErrorMessages,
      [ErrorType.UNVERIFIED_PHONE_NUMBER]: t(
        "ERROR_MESSAGE.UNVERIFIED_PHONE_NUMBER"
      ),
      [ErrorType.EMAIL_ALREADY_EXISTS]: t("ERROR_MESSAGE.EMAIL_ALREADY_EXISTS"),
      [ErrorType.INCORRECT_CODE]: t("ERROR_MESSAGE.INCORRECT_CODE"),
      [ErrorType.EMAIL_DOES_NOT_EXISTS]: t(
        "ERROR_MESSAGE.EMAIL_DOES_NOT_EXISTS"
      )
    },
    userBalances: {
      ...generalErrorMessages
    },
    merchantTransaction: {
      ...generalErrorMessages,
      [ErrorType.TRANSACTION_NOT_FOUND]: t(
        "ERROR_MESSAGE.TRANSACTION_NOT_FOUND"
      )
    },
    merchantTransactions: {
      ...generalErrorMessages
    },
    refundTransactions: {
      ...generalErrorMessages
    },
    refundAcceptRejectTransactions: {
      ...generalErrorMessages,
      [ErrorType.INVALID_WALLET_ADDRESS]: t(
        "ERROR_MESSAGE.INVALID_WALLET_ADDRESS"
      ),
      [ErrorType.UNKNOWN_WALLET_ERROR]: t("ERROR_MESSAGE.UNKNOWN_WALLET_ERROR"),
      [ErrorType.INSUFFICIENT_FUNDS]: t("ERROR_MESSAGE.INSUFFICIENT_FUNDS"),
      [ErrorType.EXTERNAL_SEND_INSIDE_THE_SYSTEM]: t(
        "ERROR_MESSAGE.EXTERNAL_SEND_INSIDE_THE_SYSTEM"
      ),
      [ErrorType.WALLET_NOT_AVAILABLE]: t("ERROR_MESSAGE.WALLET_NOT_AVAILABLE")
    },
    refundInitiateWebLink: {
      ...generalErrorMessages,
      [ErrorType.BAD_REQUEST]: t("ERROR_MESSAGE.BAD_REQUEST"),
      [ErrorType.INVALID_EMAIL]: t("ERROR_MESSAGE.INVALID_EMAIL"),
      [ErrorType.INVALID_AMOUNT_NEGATIVE_OR_ZERO]: t(
        "ERROR_MESSAGE.INVALID_AMOUNT_NEGATIVE_OR_ZERO"
      )
    },
    refundSubmitFormWithAuth: {
      ...generalErrorMessages,
      [ErrorType.BAD_REQUEST]: t("ERROR_MESSAGE.BAD_REQUEST")
    },
    userTransaction: {
      ...generalErrorMessages,
      [ErrorType.TRANSACTION_NOT_FOUND]: t(
        "ERROR_MESSAGE.TRANSACTION_NOT_FOUND"
      )
    },
    userTransactions: {
      ...generalErrorMessages
    },
    addUserTransaction: {
      ...generalErrorMessages,
      [ErrorType.USER_NOT_FOUND]: t("ERROR_MESSAGE.USER_NOT_FOUND"),
      [ErrorType.SUBACCOUNT_NOT_FOUND]: t("ERROR_MESSAGE.SUBACCOUNT_NOT_FOUND"),
      [ErrorType.INSUFFICIENT_FUNDS]: t("ERROR_MESSAGE.INSUFFICIENT_FUNDS"),
      [ErrorType.ISSUANCE_WALLET_USER_DISABLE]: t(
        "ERROR_MESSAGE.ISSUANCE_WALLET_USER_DISABLE"
      ),
      [ErrorType.WALLET_DISABLED]: t("ERROR_MESSAGE.WALLET_DISABLED"),
      [ErrorType.RECIPIENT_WALLET_NOT_ACTIVATED]: t(
        "ERROR_MESSAGE.RECIPIENT_WALLET_NOT_ACTIVATED"
      ),
      [ErrorType.P2P_TRANSFER_DISABLED]: t(
        "ERROR_MESSAGE.P2P_TRANSFER_DISABLED"
      )
    },
    exchangeRates: {
      ...generalErrorMessages
    },
    inviteUser: {
      ...generalErrorMessages,
      [ErrorType.INVALID_EMAIL]: t("ERROR_MESSAGE.INVALID_EMAIL"),
      [ErrorType.INVALID_ROLE]: t("ERROR_MESSAGE.INVALID_ROLE"),
      [ErrorType.MERCHANT_NOT_FOUND]: t("ERROR_MESSAGE.MERCHANT_NOT_FOUND")
    },
    p2pTransaction: {
      ...generalErrorMessages,
      [ErrorType.USER_NOT_FOUND]: t("ERROR_MESSAGE.USER_NOT_FOUND"),
      [ErrorType.INSUFFICIENT_FUNDS]: t("ERROR_MESSAGE.INSUFFICIENT_FUNDS"),
      [ErrorType.INVALID_AMOUNT_NEGATIVE_OR_ZERO]: t(
        "ERROR_MESSAGE.INVALID_AMOUNT_NEGATIVE_OR_ZERO"
      )
    },
    generateAPIKey: {
      ...generalErrorMessages,
      [ErrorType.MERCHANT_NOT_FOUND]: t("ERROR_MESSAGE.MERCHANT_NOT_FOUND")
    },
    merchantDetails: {
      ...generalErrorMessages,
      // Note, don't translate this, it is used to check if a merchant exists
      [ErrorType.MERCHANT_NOT_FOUND]: "ERROR_MESSAGE.MERCHANT_NOT_FOUND"
    },
    userList: {
      ...generalErrorMessages,
      [ErrorType.INVALID_EMAIL]: t("ERROR_MESSAGE.INVALID_EMAIL"),
      [ErrorType.MERCHANT_NOT_FOUND]: t("ERROR_MESSAGE.MERCHANT_NOT_FOUND"),
      [ErrorType.INVITATION_NOT_FOUND]: t("ERROR_MESSAGE.INVITATION_NOT_FOUND")
    },
    enableUser: {
      ...generalErrorMessages,
      [ErrorType.INVALID_EMAIL]: t("ERROR_MESSAGE.INVALID_EMAIL"),
      [ErrorType.USER_NOT_FOUND]: t("ERROR_MESSAGE.USER_NOT_FOUND")
    },
    disableUser: {
      ...generalErrorMessages,
      [ErrorType.INVALID_EMAIL]: t("ERROR_MESSAGE.INVALID_EMAIL"),
      [ErrorType.USER_NOT_FOUND]: t("ERROR_MESSAGE.USER_NOT_FOUND")
    },
    getRefundForm: {
      ...generalErrorMessages
    },
    submitRefundForm: {
      ...generalErrorMessages,
      [ErrorType.TRANSACTION_NOT_MATCHING_REFUND_USER_NAME]: t(
        "TRANSACTION_NOT_MATCHING_REFUND_USER_NAME"
      ),
      [ErrorType.REFUND_ALREADY_EXISTS_ERROR]: t("REFUND_ALREADY_EXISTS_ERROR")
    },
    refundExchangeRates: {
      ...generalErrorMessages,
      [ErrorType.TOKEN_EXPIRED]: t("TOKEN_EXPIRED")
    },
    recentPayment: {
      ...generalErrorMessages,
      [ErrorType.INVALID_EMAIL]: t("ERROR_MESSAGE.INVALID_EMAIL")
    },
    atmWithDraw: {
      ...generalErrorMessages,
      [ErrorType.INSUFFICIENT_FUNDS]: t("Insufficient Funds."),
      [ErrorType.INVALID_AMOUNT_NEGATIVE_OR_ZERO]: t("Amount should be > 0.")
    },
    userVerify: {
      ...generalErrorMessages,
      [ErrorType.USER_NOT_FOUND]: t("Inavlid User.")
    },
    userVerifyReset: {
      ...generalErrorMessages,
      [ErrorType.USER_NOT_FOUND]: t("Inavlid User.")
    },
    refundFormFields: {
      ...generalErrorMessages
    },
    userBalancesGeidea: {
      ...generalErrorMessages
    },
    UserTransactionsDetails: {
      ...generalErrorMessages
    },
    loadToken: {
      ...generalErrorMessages,
      [ErrorType.USER_BANK_ACCOUNT_NOT_FOUND]: t(
        "ERROR_MESSAGE.USER_BANK_ACCOUNT_NOT_FOUND"
      ),
      [ErrorType.INSUFFICIENT_FUNDS]: t("ERROR_MESSAGE.INSUFFICIENT_FUNDS"),
      [ErrorType.UNSUPPORTED_TOKEN]: t("ERROR_MESSAGE.UNSUPPORTED_TOKEN"),
      [ErrorType.USER_NOT_FOUND]: t("ERROR_MESSAGE.USER_NOT_FOUND"),
      [ErrorType.ISSUANCE_BANK_NOT_FOUND]: t(
        "ERROR_MESSAGE.ISSUANCE_BANK_NOT_FOUND"
      ),
      [ErrorType.ISSUANCE_WALLET_USER_DISABLE]: t(
        "ERROR_MESSAGE.ISSUANCE_WALLET_USER_DISABLE"
      ),
      [ErrorType.WALLET_DISABLED]: t("ERROR_MESSAGE.WALLET_DISABLED")
    },
    refundToken: {
      ...generalErrorMessages,
      [ErrorType.USER_BANK_ACCOUNT_NOT_FOUND]: t(
        "ERROR_MESSAGE.USER_BANK_ACCOUNT_NOT_FOUND"
      ),
      [ErrorType.INSUFFICIENT_FUNDS]: t("ERROR_MESSAGE.INSUFFICIENT_FUNDS"),
      [ErrorType.UNSUPPORTED_TOKEN]: t("ERROR_MESSAGE.UNSUPPORTED_TOKEN"),
      [ErrorType.USER_NOT_FOUND]: t("ERROR_MESSAGE.USER_NOT_FOUND"),
      [ErrorType.ISSUANCE_BANK_NOT_FOUND]: t(
        "ERROR_MESSAGE.ISSUANCE_BANK_NOT_FOUND"
      ),
      [ErrorType.ISSUANCE_WALLET_USER_DISABLE]: t(
        "ERROR_MESSAGE.ISSUANCE_WALLET_USER_DISABLE"
      ),
      [ErrorType.WALLET_DISABLED]: t("ERROR_MESSAGE.WALLET_DISABLED")
    },
    userTransactionGeidea: {
      ...generalErrorMessages
    },
    userContactsGeidea: {
      ...generalErrorMessages
    },
    getJwtToken: {
      ...generalErrorMessages
    },
    useTopupSart: {
      ...generalErrorMessages
    },
    fiatExchangeRates: {
      ...generalErrorMessages
    },
    sendDigitalCurrencyToExternalWallet: {
      ...generalErrorMessages,
      [ErrorType.TRANSACTION_IS_ALREADY_PAID]: t(
        "ERROR_MESSAGE.TRANSACTION_IS_ALREADY_PAID"
      ),
      [ErrorType.SENDER_RECEIVER_WALLET_ADDRESS_CANT_BE_SAME]: t(
        "ERROR_MESSAGE.SENDER_RECEIVER_WALLET_ADDRESS_CANT_BE_SAME"
      ),
      [ErrorType.UNKNOWN_WALLET_ERROR]: t("ERROR_MESSAGE.UNKNOWN_WALLET_ERROR"),
      [ErrorType.WALLET_NOT_AVAILABLE]: t("ERROR_MESSAGE.WALLET_NOT_AVAILABLE"),
      [ErrorType.EXTERNAL_SEND_INSIDE_THE_SYSTEM]: t(
        "ERROR_MESSAGE.EXTERNAL_SEND_INSIDE_THE_SYSTEM"
      ),
      [ErrorType.INVALID_AMOUNT_NEGATIVE_OR_ZERO]: t(
        "ERROR_MESSAGE.INVALID_AMOUNT_NEGATIVE_OR_ZERO"
      ),
      [ErrorType.INVALID_AMOUNT_TOO_MANY_DECIMAL_PLACES]: t(
        "ERROR_MESSAGE.INVALID_AMOUNT_TOO_MANY_DECIMAL_PLACES"
      ),
      [ErrorType.SUBACCOUNT_NOT_FOUND]: t("ERROR_MESSAGE.SUBACCOUNT_NOT_FOUND"),
      [ErrorType.WALLET_DISABLED]: t("ERROR_MESSAGE.WALLET_DISABLED"),
      [ErrorType.INSUFFICIENT_FUNDS]: t("ERROR_MESSAGE.INSUFFICIENT_FUNDS"),
      [ErrorType.MERCHANT_NOT_FOUND]: t("ERROR_MESSAGE.MERCHANT_NOT_FOUND"),
      [ErrorType.P2P_TRANSFER_DISABLED]: t(
        "ERROR_MESSAGE.P2P_TRANSFER_DISABLED"
      )
    },
    getUserByEmailOrMobile: {
      ...generalErrorMessages
    },
    addresses: {
      ...generalErrorMessages
    },
    addUserContact: {
      ...generalErrorMessages,
      [ErrorType.CONTACT_ALREADY_EXISTS]: t(
        "ERROR_MESSAGE.CONTACT_ALREADY_EXISTS"
      ),
      [ErrorType.NICKNAME_ALREADY_USED]: t(
        "ERROR_MESSAGE.NICKNAME_ALREADY_USED"
      ),
      [ErrorType.OWN_ACCOUNT_CONTACT]: t("ERROR_MESSAGE.OWN_ACCOUNT_CONTACT")
    },
    updateUserContact: {
      ...generalErrorMessages,
      [ErrorType.CONTACT_ALREADY_EXISTS]: t(
        "ERROR_MESSAGE.CONTACT_ALREADY_EXISTS"
      ),
      [ErrorType.NICKNAME_ALREADY_USED]: t(
        "ERROR_MESSAGE.NICKNAME_ALREADY_USED"
      )
    },
    getMappedLanguages: {
      ...generalErrorMessages,
      [ErrorType.BAD_REQUEST]: t("ERROR_MESSAGE.BAD_REQUEST")
    },
    deleteContactOfUser: {
      ...generalErrorMessages
    },
    getDecryptedQR: {
      ...generalErrorMessages
    },
    getEncryptedQR: {
      ...generalErrorMessages
    },
    getTransactionValidation: {
      ...generalErrorMessages
    },
    getProfileDetails: {
      ...generalErrorMessages
    },
    getDecryptedEmail: {
      ...generalErrorMessages
    }
  }
}
