import React, { useState, useEffect, memo, useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import { RouteType } from "src/constants/routeTypes"
import { useTranslation } from "react-i18next"
import { useGetUserProfileDetails } from "src/api/user"
import { useSendPhoneOtpCommon } from "src/api/otp"
import { UserDetailsContext } from "src/context/User"

import { formatPhoneNumber } from "../utils/Utils"

const Settings = () => {
  const navigation = useNavigate()
  const { t } = useTranslation()
  const { userDetails } = useContext(UserDetailsContext)
  const [passcodeText, setPasscodeText] = useState("")
  const userMobilenumber = userDetails?.phoneNumber

  const {
    data: profileData,
    isFetching: isFetchingProfileDetails,
    error: errorProfileDetails
  } = useGetUserProfileDetails()

  useEffect(() => {
    // Check if passcode is set for the user or not
    // console.log("profileData", profileData)
    if (profileData?.isPasscodeSet) {
      setPasscodeText("Change/Forgot Passcode")
    } else {
      setPasscodeText("Set Passcode")
    }
  }, [profileData])

  const {
    mutate: sendPhoneOTP,
    isLoading,
    error,
    isSuccess
  } = useSendPhoneOtpCommon()

  useEffect(() => {
    if (isSuccess) {
      navigation(RouteType.VERIFY_PHONE_OTP, {
        state: {
          screenType: "phone",
          target: userMobilenumber?.toString() || ""
        }
      })
    }
  }, [isSuccess])

  const onNext = () => {
    // navigation.navigate("PasscodeScreen")
    sendPhoneOTP({
      phoneNumber: formatPhoneNumber(userMobilenumber?.toString() || "")
    })
  }

  useEffect(() => {
    // get token from local storage
    const token = localStorage.getItem("token")
    if (token === undefined || token == null || token === "") {
      navigation(RouteType.AUTH_ERROR)
      window.location.reload()
    }
  }, [])

  return (
    <>
      <div className="whiteFullWidth"></div>
      <div className="geidea mb-50" style={{ marginTop: "48px" }}>
        <div className="row">
          <div className="col-lg-12" style={{ zIndex: 1 }}>
            <div style={{ marginTop: "17px" }}>
              <Link
                to={RouteType.PROFILE}
                state={{ from: RouteType.SETTINGS }}
                className="text-decoration-none"
              >
                <img
                  src="/images/User.svg"
                  style={{ maxHeight: "55px", marginRight: "6px" }}
                  className="pe-1"
                />
                <span className="main-font-color-black">
                  {t("userDetails")}
                </span>
              </Link>
            </div>
            <hr
              style={{ width: "108%", marginLeft: "-4%", color: "#bbb8b8" }}
            />
            <div>
              <Link
                to={RouteType.MANAGE_CONTACTS}
                state={{ from: RouteType.SETTINGS }}
                className="text-decoration-none"
              >
                <img
                  src="/images/Contact.svg"
                  style={{ maxHeight: "55px", marginRight: "6px" }}
                  className="pe-1"
                />{" "}
                <span className="main-font-color-black">
                  {t("manageContacts")}
                </span>
              </Link>
            </div>
            <hr
              style={{ width: "108%", marginLeft: "-4%", color: "#bbb8b8" }}
            />
            {/* <div>
              <a style={{ cursor: "pointer" }} onClick={() => onNext()}>
                <img
                  src="/images/passcode-icon.svg"
                  style={{ maxHeight: "55px", marginRight: "6px" }}
                  className="pe-1"
                />{" "}
                <span className="main-font-color-black">{passcodeText}</span>
              </a>
            </div>
            <hr
              style={{ width: "108%", marginLeft: "-4%", color: "#bbb8b8" }}
            /> */}
            <div>
              <Link
                to={RouteType.SHOW_MY_QR}
                state={{ from: RouteType.SETTINGS }}
                className="text-decoration-none"
              >
                <img
                  src="/images/ShowMyQR.svg"
                  style={{ maxHeight: "55px", marginRight: "6px" }}
                  className="pe-1"
                />{" "}
                <span className="main-font-color-black">{t("showMyQR")}</span>
              </Link>
            </div>
            <hr
              style={{ width: "108%", marginLeft: "-4%", color: "#bbb8b8" }}
            />
            <div>
              <Link
                to={RouteType.WADZPAY_AUTO_LOGOUT}
                className="text-decoration-none"
              >
                <img
                  src="/images/Exit.svg"
                  style={{ maxHeight: "55px", marginRight: "6px" }}
                  className="pe-1"
                />{" "}
                <span className="main-font-color-black">
                  {t("backToMainWallet")}
                </span>
              </Link>
            </div>
            <hr
              style={{ width: "108%", marginLeft: "-4%", color: "#bbb8b8" }}
            />

            <div
              className="fixed-bottom build-version text-center main-font-color"
              style={{
                fontSize: "13px",
                marginBottom: "80px"
              }}
            >
              <span>{t("buildVersion")}: 2.0.2</span>
              {/* <br />
              <span className="text-center">
                Build Server URL: https://api.geidea-test.wadzpay.com
              </span> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(Settings)
