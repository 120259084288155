import { createSlice } from "@reduxjs/toolkit"

// get accounts from localStorage
const accounts = localStorage.getItem("accounts")

const initialState =
  accounts !== undefined && accounts !== null ? JSON.parse(accounts) : []

const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setAccounts(state, action) {
      return action.payload
    }
  }
})

export const { setAccounts } = accountsSlice.actions
export default accountsSlice.reducer
