import React, { useState, useEffect, useRef } from "react"
import OtpInput from "react-otp-input"
import { Col, Button, notification, Modal, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { RouteType } from "src/constants/routeTypes"
import { useSendPhoneOtpCommon, useVerifyPhoneOtpCommon } from "src/api/otp"
// import { useValidationSchemas, VerifyCodeForm } from "src/api/constants"

import NumericKeyboard from "./NumericKeyboard"

export type UseCustomMutation<T> = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  mutate: (body: T) => void | Promise<any>
  isSuccess: boolean
  isLoading: boolean
  error: Error | null
}

type Props<T> = {
  // useRequestCode: () => UseMutationResult<ResponseType, Error, T>
  useRequestCode: UseCustomMutation<T>
  useVerifyCode: UseCustomMutation<T & { phoneNumberCode: string }>
  onVerifySuccess: () => void
  onBack: () => void
  extraParams: T
  title: string
  target: string
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const VerifyPhoneOtp: React.FC = (props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigation = useNavigate()

  const CODE_LENGTH = 6
  const useRequestCode = useSendPhoneOtpCommon()
  const useVerifyCode = useVerifyPhoneOtpCommon()
  const [loader, setLoader] = useState<boolean>(false)
  const [phoneNumberCode, setValue] = useState<string>("")
  const [minutes, setMinutes] = useState<number>(1)
  const [seconds, setSeconds] = useState<number>(30)

  // verify Code
  const {
    mutate: verifyCode,
    isLoading: isVerifyCodeLoading,
    error: verifyCodeError,
    isSuccess: isVerifyCodeSuccess
  } = useVerifyCode

  // Request code
  const {
    mutate: requestCode,
    isLoading: isRequestCodeLoading,
    error: requestCodeError,
    isSuccess: isRequestCodeSuccess
  } = useRequestCode

  // Timer
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
        } else {
          setSeconds(59)
          setMinutes(minutes - 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])

  useEffect(() => {
    if (isVerifyCodeSuccess) {
      navigation(RouteType.PASSCODE)
    }
    setLoader(false)
  }, [isVerifyCodeSuccess])

  useEffect(() => {
    if (isRequestCodeSuccess) {
      // Reset timer
      // setTargetDate(addMinutes(new Date(), TIMER_MINUTES))
      // setTimeLeft(TIMER_MINUTES * 60)
      setMinutes(1)
      setSeconds(30)
    }
  }, [isRequestCodeSuccess])

  useEffect(() => {
    if (verifyCodeError) {
      notification["error"]({
        message: t("anErrorOccurred"),
        description: verifyCodeError.message,
        placement: "bottom"
      })
      setValue("")
      setLoader(false)
    }
  }, [verifyCodeError])

  useEffect(() => {
    if (phoneNumberCode?.length === CODE_LENGTH) {
      onNext()
    }
  }, [phoneNumberCode])

  const onNext = () => {
    if (phoneNumberCode?.length < CODE_LENGTH) {
      if (phoneNumberCode?.length === 0) {
        notification["error"]({
          message: t("anErrorOccurred"),
          description: "Please enter verification code.",
          placement: "bottom"
        })
      } else {
        notification["error"]({
          message: t("anErrorOccurred"),
          description: "The code must be 6 digits",
          placement: "bottom"
        })
      }
    } else {
      setLoader(true)
      verifyCode({
        phoneNumber: location?.state?.target,
        phoneNumberCode
      })
    }
  }

  return (
    <div className="geidea mb-50" style={{ marginTop: "70px" }}>
      <Col>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "15px",
            fontSize: "14px",
            color: "#2D2D2D",
            fontStyle: "rubik"
          }}
        >
          A code hasbeen sent to your phone
        </div>
        <h6
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            fontSize: "16px",
            color: "#2D2D2D",
            fontStyle: "rubik"
          }}
        >
          {location?.state?.target}
        </h6>
      </Col>
      <Col className="readonly-otp-container" style={{ marginTop: "50px" }}>
        <OtpInput
          value={phoneNumberCode}
          onChange={setValue}
          numInputs={6}
          renderSeparator={<span style={{ width: "8px" }}></span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            border: "1px solid #C4C4C4",
            borderRadius: "10px",
            width: "15%",
            height: "40px",
            fontSize: "12px",
            color: "#000",
            fontWeight: "400",
            caretColor: "#C4C4C4"
          }}
        />
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "15px",
            fontSize: "14px",
            color: "#1E1E1E",
            fontStyle: "rubik"
          }}
        >
          {seconds > 0 || minutes > 0 ? (
            <span>
              {"No code? Wait "} {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds} {"seconds"}
            </span>
          ) : (
            <>
              {"No Code? "}{" "}
              <span
                onClick={() =>
                  requestCode({ phoneNumber: location?.state?.target })
                }
                style={{ color: "#006CD7" }}
              >
                Resend Code
              </span>
            </>
          )}
        </div>
      </Col>
      <div style={{ marginTop: "100px" }}></div>
      <NumericKeyboard
        value={phoneNumberCode || ""}
        onChange={(value: string) => setValue(value)}
        maxValueLength={CODE_LENGTH}
      />
      <Col>
        {loader ? (
          <Button size="large" className="passCodeButton">
            Next{" "}
            <Spin
              style={{ color: "#000", marginLeft: "5px" }}
              indicator={antIcon}
            />
          </Button>
        ) : (
          <Button onClick={onNext} size="large" className="passCodeButton">
            Next
          </Button>
        )}
      </Col>
    </div>
  )
}

export default VerifyPhoneOtp
