import React, { useEffect, useState, useContext } from "react"
import { CloseOutlined } from "@ant-design/icons"
import { Skeleton, Button } from "antd"
import moment from "moment"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { SearchContext, FilterContext } from "src/context/Search"
import { UserTransactionsDetails } from "src/api/models"
import { useUserTransactionsDetails } from "src/api/user"
import { Link, useNavigate } from "react-router-dom"
import { RouteType } from "src/constants/routeTypes"
import Search from "src/components/searchFilter/Search"
import Filter from "src/components/searchFilter/Filter"

import {
  getTransactionType,
  errorModal,
  getAmountTwoDecimal,
  getDirection,
  getOtherPartyName,
  sartTxt
} from "../../utils/Utils"

const checkNumberOrString = (value: any) => {
  if (!isNaN(value)) {
    return Number(value) // Converts string number to actual number
  } else {
    return value // Returns the string itself if it's not a valid number
  }
}

export default function Transactions() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentData = new Date()
  const { isSearch, setIsSearch } = useContext(SearchContext)
  const { isFilter, setIsFilter } = useContext(FilterContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [search, setSearch] = useState<string | undefined>("")
  const [filter, setFilter] = useState<any>({})
  const [query, setQuery] = useState("page=0&asset=SART")
  const [dateFrom, setDateFrom] = useState<Date>(currentData)
  const [dateTo, setDateTo] = useState<Date>(currentData)
  const [transactionType, setTransactionType] = useState<object>([])
  const [transactionStatus, setTransactionStatus] = useState<object>([])
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)

  // Get user transactions API
  const [userTransactions, setUserTransactions] = useState<
    UserTransactionsDetails[]
  >([])

  // Get user transactions API
  const {
    data: userTransactionsData,
    isFetching: isFetchingUserTransactions,
    error: errorUserTransactions
  } = useUserTransactionsDetails(query)

  useEffect(() => {
    // Handle the case when there's an error
    if (errorUserTransactions) {
      setLoading(false)
      errorModal(errorUserTransactions.message)
      return // Exit early after handling the error
    }

    // Handle the case when userTransactionsData is available
    if (userTransactionsData) {
      if (userTransactionsData.length > 0) {
        // Merge existing userTransactions with new data
        const userTransactionsList: any = userTransactions
          ? [...userTransactionsData, ...userTransactions]
          : userTransactionsData

        console.log(">>>>>> mohbit page", page)

        setUserTransactions(
          page == 0 ? userTransactionsData : userTransactionsList
        )

        setHasMore(true) // more transactions to load
      } else {
        setHasMore(false) // No more transactions to load
      }

      setLoading(false) // Set loading to false after processing
    }
  }, [userTransactionsData, errorUserTransactions])

  useEffect(() => {
    setIsSearch(false)
    setIsFilter(false)

    // get filter
    const filterData = localStorage.getItem("filter")
    if (filterData) {
      const filter = JSON.parse(filterData)
      setDateFrom(filter?.dateFrom)
      setDateTo(filter?.dateTo)
      setTransactionType(filter?.type)
      setTransactionStatus(filter?.status)
    }

    // get token from local storage
    const token = localStorage.getItem("token")
    if (token === undefined || token == null || token === "") {
      navigate(RouteType.AUTH_ERROR)
      window.location.reload()
    }
  }, [])

  useEffect(() => {
    setUserTransactions([])

    const key =
      typeof checkNumberOrString(search) === "number" ? "amount" : "search"

    key === "amount" ? delete filter.search : delete filter.amount

    setFilter({
      ...filter,
      ...{
        appSearch: true,
        [key]: search?.toLowerCase()
      }
    })
  }, [search])

  useEffect(() => {
    if (filter) {
      let filters: any = {}
      const filterData = localStorage.getItem("filter")

      if (filterData) {
        filters = {
          ...filter,
          ...JSON.parse(filterData)
        }
      } else {
        filters = { ...filter }
      }

      const filterStr = Object.keys(filters)
        .map((key) => {
          return filters[key] != ""
            ? `${key}=${encodeURIComponent(filters[key])}`
            : null
        })
        .join("&")

      setQuery(`page=${page}&asset=SART` + "&" + filterStr)

      setLoading(true)
    }
  }, [filter])

  const handleFilter = (filterObj: any, isReset?: boolean) => {
    setPage(0)

    if (isReset != null && isReset == true) {
      setLoading(true)

      setTransactionType([])
      setTransactionStatus([])
      setDateFrom(currentData)
      setDateTo(currentData)
      setFilter({
        page: 0,
        appSearch: true
      })
      return
    }

    setUserTransactions([])

    setDateFrom(filterObj.dateFrom)
    setDateTo(filterObj.dateTo)
    setTransactionType(filterObj.type)
    setTransactionStatus(filterObj.status)

    // set filter object properties
    setFilter(filterObj)

    setIsFilter(false)
  }

  let timeoutId: NodeJS.Timeout

  const skeletons = Array.from({ length: 5 }, (_, index) => (
    <Skeleton
      style={{ marginTop: index == 0 ? "20px" : 0 }}
      key={index}
      loading={loading}
      active
      avatar
    ></Skeleton>
  ))

  // Function to compare two datetime strings
  const compareDatetime = (a: any, b: any) => {
    const dateA = new Date(a)
    const dateB = new Date(b)

    // Compare the dates using getTime()
    return dateB.getTime() - dateA.getTime()
  }

  const getTypeNameByValue = (value: string) => {
    let updateValue = ""
    if (value == "") {
      return null
    }

    if (value === "DEPOSIT") {
      updateValue = t(`transactionsTypes.${"Topup"}`)
    } else if (value === "WITHDRAW") {
      updateValue = t(`transactionsTypes.${"Redeem Unspent"}`)
    } else if (value === "PEER_TO_PEER") {
      updateValue = t(`transactionsTypes.${"Transfer"}`)
    } else if (value === "POS,OTHER") {
      updateValue = t(`transactionsTypes.${"Payment"}`)
    } else if (value === "REFUND") {
      updateValue = t(`transactionsTypes.${"Merchant Refund"}`)
    } else if (value === "SUCCESSFUL") {
      updateValue = t("successful")
    } else if (value === "IN_PROGRESS") {
      updateValue = t("inProgress")
    } else if (value === "FAILED") {
      updateValue = t("failed")
    }

    return updateValue
  }

  const updateFilterObj = (value: string, key: string) => {
    const filterData = localStorage.getItem("filter")

    if (filterData) {
      const filters = JSON.parse(filterData)

      key === "type" || key === "status"
        ? (filters[key] = filters[key].filter(function (item: string) {
            return item !== value
          }))
        : null

      if (key == "date") {
        filters["dateFrom"] = ""
        filters["dateTo"] = ""

        setDateFrom(currentData)
        setDateTo(currentData)
      }

      setTransactionType(filters.type)
      setTransactionStatus(filters.status)

      // Store latest filter
      localStorage.setItem("filter", JSON.stringify(filters))

      // Set latest filter
      setFilter(filters)
    }
  }

  let filterss: any = {}
  const filterData = localStorage.getItem("filter")
  if (filterData) {
    filterss = { ...filter, ...JSON.parse(filterData) }
  }

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null
    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop // Store last scroll position

    const handleScroll = () => {
      // Clear the previous timeout, if any
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      timeoutId = setTimeout(() => {
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop

        // Check if the user is scrolling down
        if (scrollTop > lastScrollTop) {
          // Check if the user has scrolled to the bottom
          if (
            window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.offsetHeight - 10
          ) {
            if (!loading && hasMore) {
              console.log(">>>>>> mohit scrolling down")

              setPage((prevPage) => prevPage + 1) // Increment page number once

              setFilter((prevState: any) => ({
                ...prevState,
                hasMore: true
              }))
            }
          }
        }

        // Update the last scroll position
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
      }, 200) // Debounce time
    }

    // Add event listener for scrolling
    window.addEventListener("scroll", handleScroll)

    // Clean up the event listener and timeout when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll)
      if (timeoutId) {
        clearTimeout(timeoutId) // Clear timeout on cleanup
      }
    }
  }, [loading, hasMore])

  const handleCancel = () => {
    console.log(">>>>> mohit data", userTransactions)

    setTimeout(() => {
      setLoading(false)
    }, 100)
  }

  return (
    <>
      <div className="geidea mb-40">
        <div className="row mt-5">
          <div style={{ gap: "10px" }}>
            {Object.keys(filterss).map((key: any) => {
              const value = filterss[key]
              let isCount = 0

              if (key === "dateTo") {
                isCount++
              }

              return value ? (
                typeof value !== "object" ? (
                  isCount === 1 ? (
                    <Button
                      className="filterButton"
                      onClick={() => updateFilterObj(value, "date")}
                      key={key}
                    >
                      {moment(filterss["dateFrom"]).format("DD-MM-YYYY")} -{" "}
                      {moment(filterss["dateTo"]).format("DD-MM-YYYY")}
                      <span>
                        &nbsp;
                        <CloseOutlined
                          style={{
                            fontSize: "10px",
                            position: "absolute",
                            top: "10px"
                          }}
                        />
                      </span>
                    </Button>
                  ) : null
                ) : (
                  value.length > 0 &&
                  value.map((value: string) => {
                    return (
                      <Button
                        className="filterButton"
                        onClick={() => updateFilterObj(value, key)}
                        key={key}
                      >
                        {getTypeNameByValue(value)}
                        <span>
                          &nbsp;
                          <CloseOutlined
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              top: "10px"
                            }}
                          />
                        </span>
                      </Button>
                    )
                  })
                )
              ) : null
            })}
          </div>

          {isSearch ? (
            <>
              <span style={{ marginTop: "50p" }}>
                <Search
                  placeholderTxt={t("searchByUserIdAmountTransactionID")}
                  setSearch={(value?: string) => {
                    if (timeoutId) clearTimeout(timeoutId)
                    timeoutId = setTimeout(() => {
                      setSearch(value)
                    }, 1000)
                  }}
                />
              </span>
            </>
          ) : null}

          {isFilter ? (
            <>
              <Filter
                isOpen={isFilter}
                handleFilter={(filterObj?: any, isReset?: boolean) =>
                  handleFilter(filterObj, isReset)
                }
                fromDate={dateFrom}
                toDate={dateTo}
                transactionTypes={transactionType}
                transactionStatus={transactionStatus}
                handleCancel={handleCancel}
              />
            </>
          ) : null}
          {loading ? (
            <>{skeletons}</>
          ) : userTransactions?.length !== undefined &&
            userTransactions?.length > 0 ? (
            <div
              className="table-responsive shadow mb-5"
              style={{ background: "#ffffff" }}
            >
              <table className="customTable table">
                <tbody>
                  {userTransactions.length > 0 &&
                    userTransactions
                      ?.sort((a: any, b: any) =>
                        compareDatetime(
                          a?.paymentReceivedDate
                            ? a?.paymentReceivedDate
                            : a?.createdAt,
                          b?.paymentReceivedDate
                            ? b?.paymentReceivedDate
                            : b?.createdAt
                        )
                      )
                      ?.map((element: any, key) => {
                        const directionPath =
                          element.status === "FAILED"
                            ? "/images/transaction_failed.png"
                            : getDirection(element, "name") === "DebitArrow"
                            ? "/images/debit_arrow.svg"
                            : "/images/credit_arrow.svg"

                        const transactionsTypes = t(
                          `transactionsTypes.${getTransactionType(
                            element,
                            element.transactionType
                          )}`
                        )

                        return (
                          <tr
                            className="tItemAlign"
                            key={key}
                            style={{
                              height: "70px"
                            }}
                          >
                            <Link
                              key={key}
                              to={RouteType.INTERNALTRANSPAYMENTDETAILS}
                              state={{
                                from: RouteType.TRANSACTIONS,
                                transactionId: element.transactionId
                              }}
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <td key={key}>
                                <span className="tItem1">
                                  {/* {CapitalizeFirstLetter(
                                shownames(element)[0]
                              )} */}
                                  <img
                                    style={{
                                      width:
                                        element.status === "FAILED"
                                          ? "45px"
                                          : "30px",
                                      height:
                                        element.status === "FAILED"
                                          ? ""
                                          : "30px",
                                      marginLeft:
                                        element.status === "FAILED"
                                          ? "-6px"
                                          : ""
                                    }}
                                    src={directionPath}
                                  ></img>{" "}
                                </span>
                                <span className="tItem2">
                                  {transactionsTypes}
                                </span>
                                <span className="tItem3">
                                  {getOtherPartyName(
                                    element,
                                    localStorage.getItem("email") || ""
                                  )}
                                </span>
                                <span className="tItem5">
                                  {dayjs(
                                    element?.paymentReceivedDate
                                      ? element?.paymentReceivedDate
                                      : element?.createdAt
                                  ).format("D MMM YYYY, hh:mm a")}
                                </span>
                                <span className="tItem4">
                                  {sartTxt}{" "}
                                  {getAmountTwoDecimal(element.amount)}
                                </span>
                                <span className="tItem6"></span>
                              </td>
                            </Link>
                          </tr>
                        )
                      })}
                </tbody>
              </table>
            </div>
          ) : (
            <p
              style={{
                textAlign: "center",
                marginTop: "10px"
              }}
            >
              {t("noTransactionsAvailable")}
            </p>
          )}
        </div>
      </div>
    </>
  )
}
