import React from "react"
import { Button, Card } from "antd"
import { useTranslation } from "react-i18next"

const { Meta } = Card

type Props = {
  transactionType: any
  amountRequested: number
  feeConfigList: any
  netFeeKeyName: string
  totalAmount: number | any
  netAmountCalulated: number | any
  totalAmountText: string
}

const TransactionSummaryComponent: React.FC<Props> = ({
  transactionType,
  amountRequested,
  feeConfigList,
  netFeeKeyName,
  netAmountCalulated,
  totalAmountText,
  totalAmount
}: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <h6 className="main-font-color" style={{ fontWeight: 500 }}>
        {t("transactionSummary")}
      </h6>
      <Card
        style={{ width: "100%", marginTop: "15px" }}
        title={t("transactionType")}
        extra={
          <p className="main-font-color">
            <b>
              {transactionType === "Refund"
                ? t(`transactionsTypes.${"Redeem Unspent"}`)
                : t(`transactionsTypes.${transactionType}`)}
            </b>
          </p>
        }
        actions={[
          <Meta key="1" title={totalAmountText} description={totalAmount} />
        ]}
      >
        {transactionType !== "Service Fee" &&
          transactionType !== "Wallet Balance Fee" && (
            <>
              <Meta
                title={
                  transactionType == "Topup"
                    ? t("amountRequested")
                    : transactionType == "Redeem Unspent" ||
                      transactionType == "Refund"
                    ? t("tokensRequested")
                    : t("tokens")
                }
                description={amountRequested}
              />
              {feeConfigList?.length > 0 &&
                feeConfigList?.map((x: any) => {
                  return x.feeName != null && x.feeName !== "" ? (
                    <Meta
                      key={x.feeName}
                      title={t(`feeTypes.${x.feeName}`)}
                      description={`${x.currencyType || x.currencyUnit} ${
                        x.feeCalculatedAmount || x?.feeAmount
                      }`}
                    />
                  ) : null
                })}
              {feeConfigList?.length > 0 ? (
                <>
                  <Meta
                    title={netFeeKeyName}
                    description={netAmountCalulated}
                  />
                </>
              ) : (
                <Meta title={t(`feeTypes.${"Fee"}`)} description="0.00" />
              )}
            </>
          )}
      </Card>
    </>
  )
}

export default TransactionSummaryComponent
