import React, { useState, useEffect } from "react"
import { Menu, Dropdown } from "antd"
import { GlobalOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"

const LanguageMenu: React.FC<{
  languages: any[]
  onChangeLang: (lang_code: string, resourceFileURL: string) => void
}> = ({ languages, onChangeLang }) => {
  const { i18n } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState<string>("")

  const handleMenuClick = ({ key }: { key: string }) => {
    const language = languages.find((lang) => lang.languageName === key)
    if (language) {
      onChangeLang(language.languageName, language.resourceFileURL)
      setSelectedLanguage(language.languageName)
    }
  }

  const menu = (
    <Menu
      selectedKeys={[selectedLanguage]}
      onClick={handleMenuClick}
      defaultSelectedKeys={["English"]}
    >
      {languages &&
        languages.map(
          ({ languageName, languageDisplayName, countryImageUrl }, index) => (
            <Menu.Item
              key={languageName}
              aria-checked={index === 0}
              defaultChecked={true}
            >
              <img
                style={{
                  width: "20px",
                  marginTop: "-3px",
                  border: "1px solid #ADADAD"
                }}
                src={countryImageUrl}
              />
              &nbsp;&nbsp;
              <span>{languageDisplayName}</span>
            </Menu.Item>
          )
        )}
    </Menu>
  )

  useEffect(() => {
    const selectedLanguageObj = languages.find(
      (lang) => lang.languageName === selectedLanguage
    )
    if (selectedLanguageObj) {
      i18n.addResources(
        "Bhasha",
        "translation",
        selectedLanguageObj.resourceFileURL
      )
      i18n.changeLanguage("Bhasha")
    }
  }, [selectedLanguage, languages, i18n])

  useEffect(() => {
    setSelectedLanguage(
      languages && languages?.length > 0 ? languages[0]?.languageName : ""
    )
  }, [languages])

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <GlobalOutlined style={{ width: "25px", marginRight: "5px" }} />
    </Dropdown>
  )
}

export default LanguageMenu
