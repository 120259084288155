import { createSlice } from "@reduxjs/toolkit"

import { DISABLE_BACK_ICON } from "../modules"

const initialState: DISABLE_BACK_ICON = {
  isDisabled: false
}

const appHeaderSlice = createSlice({
  name: "appHeader",
  initialState,
  reducers: {
    setHeaderBackIcon: (state, action) => {
      // Mutating the state or Mofiying the state here
      state.isDisabled = action.payload
    }
  }
})

export const { setHeaderBackIcon } = appHeaderSlice.actions

export default appHeaderSlice.reducer
