export enum RouteType {
  HOME = "/home",
  CREATE_ACCOUNT = "/onboarding/create-account",
  VERIFY_PHONE_CODE = "/onboarding/verify-phone-code",
  VERIFY_EMAIL_CODE = "/onboarding/verify-email-code",
  ACCOUNT_DETAILS = "/onboarding/account-details",
  MERCHANT_DETAILS = "/onboarding/merchant-details",
  ONBOARDING_SUCCESS = "/onboarding/success",
  SIGN_IN = "/sign-in",
  RESET_PASSWORD = "/reset-password",
  RESET_PASSWORD_SUCCESS = "/reset-password/success",
  SUBMIT_RESET_PASSWORD = "/forgot-password/verify",
  TRANSACTION_DETAIL = "/transaction-detail",
  P2P_TRANSACTION = "/m2c-transaction",
  ADMIN = "/settings",
  ADMIN_USERS = "/settings/users",
  ADMIN_USERS_INVITE = "/settings/users/invite",
  ADMIN_API_KEYS = "/settings/api-keys",
  ADMIN_API_KEYS_CREATE = "/settings/api-keys/generate",
  ACCEPT_INVITE = "/accept-invite",
  CLOSING_SALES = "/closing-sales",
  //TRANSACTIONS = "/transactions",
  INVOICE = "/invoice",
  RequestTransaction = "/request-transaction",
  REFUND_DISPUTE = "/refund-dispute",
  REFUND_REQUEST_ACCEPTANCE = "/refund-request-acceptance",
  REFUND_REQUEST_APPROVAL = "/refund-request-approval",
  ANALYTICS = "/analytics",
  // SETTINGS = "/settings",
  CHANGE_PASSWORD = "/changepassword",
  CHANGE_PASSWORD_SUCCESS = "/changepassword/success",
  CUSTOMER_MANUAL_VERIFICATION_FORM = "/customermanualverificationform",
  REFUND_FORM_TOKEN = "/:id",
  ATM = "/atm",
  ATM_ENTRY = "/atm/entry",
  ATM_ENTRY_QR_CODE = "/atm/entry/qrcode",
  ATM_DISBURSEMENT = "/atm/entry/disbursement",
  WADZPAY_AUTO_LOGIN = "/",
  AUTH_CHECK = "/auth/check",
  AUTH_ERROR = "/auth/error",
  WADZPAY_AUTO_LOGOUT = "/logout",
  DASHBOARD = "/dashboard",
  SETTINGS = "/settings",
  TOPUP = "/topup",
  TRANSACTION_CONFIRMATION = "/transaction-confirmation",
  REFUNDPAGE = "/refund-page",
  PROFILE = "/profile",
  INTERNAL_TRANSFER = "/internaltransfer",
  MANAGE_CONTACTS = "/manage-contacts",
  VERIFY_PHONE_OTP = "/verify-phone-otp",
  PASSCODE = "/passcode",
  INTERNALTRANSPAYMENTDETAILS = "/payment-details",
  SCAN_QR_CODE = "/scan-qr-code",
  SCANED_QR_CODE_PAYING = "/scan-qr-code-paying",
  PAYMENT_CONFIRMATION = "/payment-confirmation",
  TRANSACTIONS = "/transactions",
  PAYMENTSUCCESS = "/PaymentSuccess",
  SHOW_MY_QR = "/show-my-qr"
}
