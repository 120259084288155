export enum ErrorType {
  BAD_REQUEST = "BAD_REQUEST",
  FORBIDDEN = "FORBIDDEN",
  INCORRECT_CODE = "INCORRECT_CODE",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  INVALID_INPUT_FORMAT = "INVALID_INPUT_FORMAT",
  INVALID_PHONE_NUMBER = "INVALID_PHONE_NUMBER",
  PHONE_NUMBER_ALREADY_EXISTS = "PHONE_NUMBER_ALREADY_EXISTS",
  PHONE_NUMBER_DOES_NOT_EXISTS = "PHONE_NUMBER_ALREADY_VERIFIED",
  UNAUTHORIZED = "UNAUTHORIZED",
  UNKNOWN_TWILIO_ERROR = "UNKNOWN_TWILIO_ERROR",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  NOT_FOUND = "NOT_FOUND",
  UNVERIFIED_PHONE_NUMBER = "UNVERIFIED_PHONE_NUMBER",
  EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS",
  FRAUDULENT_USER = "FRAUDULENT_USER",
  UNKNOWN_SEON_ERROR = "UNKNOWN_SEON_ERROR",
  EMAIL_DOES_NOT_EXISTS = "EMAIL_DOES_NOT_EXISTS",
  TRANSACTION_NOT_FOUND = "TRANSACTION_NOT_FOUND",
  SUBACCOUNT_NOT_FOUND = "SUBACCOUNT_NOT_FOUND",
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  USERS_ALREADY_IN_DATABASE = "USERS_ALREADY_IN_DATABASE",
  MERCHANT_ALREADY_EXISTS = "MERCHANT_ALREADY_EXISTS",
  INVALID_AMOUNT_NEGATIVE_OR_ZERO = "INVALID_AMOUNT_NEGATIVE_OR_ZERO",
  INVALID_AMOUNT_TOO_MANY_DECIMAL_PLACES = "INVALID_AMOUNT_TOO_MANY_DECIMAL_PLACES",
  MERCHANT_NOT_FOUND = "MERCHANT_NOT_FOUND",
  INVALID_EMAIL = "INVALID_EMAIL",
  INVALID_ROLE = "INVALID_ROLE",
  INVITATION_NOT_FOUND = "INVITATION_NOT_FOUND",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  INVALID_WALLET_ADDRESS = "INVALID_WALLET_ADDRESS",
  UNKNOWN_WALLET_ERROR = "UNKNOWN_WALLET_ERROR",
  EXTERNAL_SEND_INSIDE_THE_SYSTEM = "EXTERNAL_SEND_INSIDE_THE_SYSTEM",
  WALLET_NOT_AVAILABLE = "WALLET_NOT_AVAILABLE",
  TRANSACTION_NOT_MATCHING_REFUND_USER_NAME = "TRANSACTION_NOT_MATCHING_REFUND_USER_NAME",
  REFUND_ALREADY_EXISTS_ERROR = "REFUND_ALREADY_EXISTS_ERROR",
  USER_BANK_ACCOUNT_NOT_FOUND = "USER_BANK_ACCOUNT_NOT_FOUND",
  UNSUPPORTED_TOKEN = "UNSUPPORTED_TOKEN",
  ISSUANCE_BANK_NOT_FOUND = "ISSUANCE_BANK_NOT_FOUND",
  ISSUANCE_WALLET_USER_DISABLE = "ISSUANCE_WALLET_USER_DISABLE",
  WALLET_DISABLED = "WALLET_DISABLED",
  VERIFICATION_NOT_FOUND = "VERIFICATION_NOT_FOUND",
  OWN_ACCOUNT_CONTACT = "OWN_ACCOUNT_CONTACT",
  NICKNAME_ALREADY_USED = "NICKNAME_ALREADY_USED",
  CONTACT_ALREADY_EXISTS = "CONTACT_ALREADY_EXISTS",
  CONTACT_NOT_FOUND = "CONTACT_NOT_FOUND",
  INVALID_EXPO_TOKEN = "INVALID_EXPO_TOKEN",
  INVALID_BANK_ACCOUNT = "INVALID_BANK_ACCOUNT",
  BANK_ACCOUNT_ALREDY_ADDED = "BANK_ACCOUNT_ALREDY_ADDED",
  DUPLICATE_BANK_ACCOUNT = "DUPLICATE_BANK_ACCOUNT",
  CANNOT_ALLOW_LESS_THEN_MIN_FIAT = "CANNOT_ALLOW_LESS_THEN_MIN_FIAT",
  CANNOT_ALLOW_MORE_THEN_MAX_FIAT = "CANNOT_ALLOW_MORE_THEN_MAX_FIAT",
  TRANSACTION_IS_ALREADY_PAID = "TRANSACTION_IS_ALREADY_PAID",
  SENDER_RECEIVER_WALLET_ADDRESS_CANT_BE_SAME = "SENDER_RECEIVER_WALLET_ADDRESS_CANT_BE_SAME",
  WALLET_DISABLED_RECIPIENT = "WALLET_DISABLED_RECIPIENT",
  SALT_KEY_NOT_FOUND = "SALT_KEY_NOT_FOUND",
  BLOCKCHAIN_INSUFFICIENT_AMOUNT = "BLOCKCHAIN_INSUFFICIENT_AMOUNT",
  RECIPIENT_WALLET_NOT_ACTIVATED = "RECIPIENT_WALLET_NOT_ACTIVATED",
  OTHER_BANK_TRANSFER_NOT_ALLOWED = "OTHER_BANK_TRANSFER_NOT_ALLOWED",
  P2P_TRANSFER_DISABLED = "P2P_TRANSFER_DISABLED",
  LANGUAGE_MAPPED_WITH_INSTITUTIONS = "LANGUAGE_MAPPED_WITH_INSTITUTIONS"
}
