import env, { ENV } from "./env"

export const isDev = env.TYPE === ENV.DEV

export const formatPhoneNumber: (phoneNumber: string) => string = (
  phoneNumber
) => {
  return phoneNumber.replace(/[^+\d]/g, "")
}

export const isValidHost = (url: string) => {
  const domain = "wadzpay.com"
  const dev = "localhost"

  if (
    url.toLocaleLowerCase().includes(domain) ||
    url.toLocaleLowerCase().includes(dev)
  ) {
    return true
  }
  return false
}
