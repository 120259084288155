import React, { useEffect, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { RouteType } from "src/constants/routeTypes"
import { UserDetailsContext } from "src/context/User"
import { useUser } from "src/api/user"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const AuthCheck = () => {
  const navigate = useNavigate()
  const { setUserDetails } = useContext(UserDetailsContext)
  const [userBankDetails, setUserBankDetailsData] = useState<any>(null)

  const email = localStorage.getItem("email")
  const redirectUrl = localStorage.getItem("redirectUrl")

  const getToken = () => localStorage.getItem("token")

  // Function to wait for the token to be available in local storage
  const waitForToken = () => {
    return new Promise((resolve) => {
      const checkToken = () => {
        const token = getToken()
        if (token) {
          resolve(token)
        } else {
          setTimeout(checkToken, 100) // Check every 100ms
        }
      }
      checkToken()
    })
  }

  const {
    data: userDetailsData,
    refetch: refetchUserDetailsData,
    isSuccess: userIsSuccess
  } = useUser(`email=${email}`)

  useEffect(() => {
    if (userDetailsData) {
      setUserBankDetailsData(userDetailsData)
    }
  }, [userDetailsData])

  useEffect(() => {
    if (userBankDetails != null) {
      setUserDetails({
        cognitoUsername: userBankDetails?.cognitoUsername,
        email: userBankDetails?.email,
        phoneNumber: userBankDetails?.phoneNumber,
        firstName: userBankDetails?.firstName,
        lastName: userBankDetails?.lastName,
        bankAccountNumber:
          userBankDetails?.userBankAccount?.[0]?.bankAccountNumber,
        ifscCode: userBankDetails?.userBankAccount?.[0]?.ifscCode,
        notificationStatus: userBankDetails?.notificationStatus,
        kycVerified: userBankDetails?.kycVerified,
        userBankAccount: userBankDetails?.userBankAccount
      })

      localStorage.removeItem("previusLocation")
      localStorage.removeItem("redirectUrl")

      navigate(redirectUrl || RouteType.DASHBOARD)
    } else {
      // navigate(RouteType.AUTH_ERROR)
    }
  }, [userBankDetails, setUserDetails, navigate, redirectUrl])

  useEffect(() => {
    const fetchData = async () => {
      await waitForToken()
      refetchUserDetailsData()
    }

    fetchData()
  }, [refetchUserDetailsData])

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <Spin style={{ color: "#000", marginLeft: "5px" }} indicator={antIcon} />
    </div>
  )
}

export default AuthCheck
