import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const useScrollToRefresh = () => {
  const location = useLocation()

  useEffect(() => {
    const isHomePage = location.pathname === "/dashboard"

    if (isMobileDevice() && isHomePage) {
      const handleScroll = () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          window.location.reload()
        }
      }

      window.addEventListener("scroll", handleScroll)

      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [location])
}

export default useScrollToRefresh

const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent)
}
