import { configureStore, combineReducers } from "@reduxjs/toolkit"

import { APP_CONFIGURATION, DISABLE_BACK_ICON } from "./modules"
import appHeaderReducer from "./slices/appHeaderSlice"
import accountsReducer from "./slices/accountsSlice" // Import the accountsSlice reducer

// Define the Initial state
const initialAppConfigurationState: APP_CONFIGURATION = {
  issuanceType: "bullion",
  themeColor: "#ffffff",
  buttonColor: "#FFC235"
}

const initialDisableBackIconState: DISABLE_BACK_ICON = {
  isDisabled: false
}

// Combine reducers
const rootReducer = combineReducers({
  appHeader: appHeaderReducer,
  accounts: accountsReducer // Add the accountsSlice reducer
})

// Configure the Redux store with the initial state
const appStore = configureStore({
  reducer: rootReducer,
  preloadedState: {
    appHeader: initialDisableBackIconState // Match the key to the reducer name
  }
})

export default appStore
