import React from "react"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

import ErrorImg from "./../screens/RefundForm/error.gif"
import "./../screens/RefundForm/errorPage.scss"

export default function ErrorPage(props: any) {
  const { t } = useTranslation()
  const location = useLocation()
  const { jwtTokenDetails } = location.state || {}

  const errorMsg =
    jwtTokenDetails && !jwtTokenDetails?.state
      ? t(jwtTokenDetails?.message)
      : "Invalid User ID."

  return (
    <div className="error-handle-main mt-5">
      <div className="error-img-section">
        <img src={ErrorImg} alt="error-img" className="error-img" />
      </div>
      <p
        style={{
          marginTop: "50px",
          display: "grid",
          textAlign: "center",
          color: "red"
        }}
      >
        {props?.errorMssg ? props?.errorMssg : errorMsg}
      </p>
    </div>
  )
}
