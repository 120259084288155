import React, { useState, createContext, useContext, useEffect } from "react"
import {
  Input,
  Drawer,
  DrawerProps,
  Space,
  Button,
  Col,
  Row,
  Form,
  DatePicker,
  DatePickerProps,
  notification
} from "antd"
import moment from "moment"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import tz from "dayjs/plugin/timezone"
import { useTranslation } from "react-i18next"
import { FilterContext } from "src/context/Search"
import type { NotificationPlacement } from "antd/es/notification/interface"
const style: React.CSSProperties = { background: "#0092ff", padding: "8px 0" }

dayjs.extend(utc)
dayjs.extend(tz)

type Props = {
  isOpen: boolean
  handleFilter: (value?: string, isReset?: boolean) => void
  fromDate: Date
  toDate: Date
  transactionTypes: object
  transactionStatus: object
  handleCancel: () => void
}

const Filter: React.FC<Props> = ({
  handleFilter,
  isOpen,
  fromDate,
  toDate,
  transactionTypes,
  transactionStatus,
  handleCancel
}: Props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [rendom, setRendom] = useState<number>(0)
  const { setIsFilter } = useContext(FilterContext)
  const [open, setOpen] = useState<boolean>(isOpen)
  const [placement] = useState<DrawerProps["placement"]>("bottom")
  const [dateFrom, setDateFrom] = useState<Date | null>(new Date(fromDate))
  const [dateTo, setDateTo] = useState<Date | null>(new Date(toDate))
  const [transactionType, setTransactionType] = useState<any>(transactionTypes)
  const [status, setStatus] = useState<any>(transactionStatus)
  const [api, contextHolder] = notification.useNotification()
  const [size, setSize] = useState<DrawerProps["size"]>("large")

  const openNotification = (
    discreptionTxt: string,
    placement: NotificationPlacement = "bottom"
  ) => {
    api.info({
      message: "",
      description: discreptionTxt,
      placement
    })
  }

  const onClose = () => {
    setOpen(false)
    setIsFilter(false)
    handleFilter("", true)

    handleCancel()
  }

  const disabledFromDate = (current: any) => {
    return dateTo === null
      ? current && current.isAfter(moment())
      : current && current.isAfter(moment())
    // current && current.isAfter(moment(dateTo).add(1, "days"))
  }

  const disabledToDate = (current: any) => {
    console.log("current", current, " ", moment(dateFrom) + " ", moment())
    return dateFrom === null
      ? current && current.isAfter(moment())
      : (current && current.isBefore(dateFrom)) ||
          (current && current.isAfter(moment()))
  }

  const handleFromDate: DatePickerProps["onChange"] = (
    date: any,
    dateString: any
  ) => {
    setDateFrom(dateString != "" ? date.$d : null)
  }

  const handleToDate: DatePickerProps["onChange"] = (
    date: any,
    dateString: any
  ) => {
    setDateTo(dateString != "" ? date.$d : null)
  }

  const transactionFilter = () => {
    if (dateTo != null && dateFrom != null && dateTo < dateFrom) {
      openNotification(t("fromDateShouldBeLesserThanToDate"))
      return
    }
    if (
      dateTo != null &&
      dateFrom != null &&
      dateTo?.toISOString == dateFrom?.toISOString
    ) {
      dateTo?.setHours(23)
      dateTo?.setMinutes(59)
      dateFrom?.setHours(0)
      dateFrom?.setMinutes(0)
    }

    const filterObj: any = {
      dateFrom: dateFrom === null ? "" : dateFrom.toISOString(),
      dateTo: dateTo === null ? "" : dateTo.toISOString(),
      type: transactionType,
      status: status
    }

    localStorage.setItem("filter", JSON.stringify(filterObj))

    handleFilter(filterObj, false)
  }

  const resetTransactionFilter = () => {
    setDateFrom(new Date())
    setDateTo(new Date())
    setTransactionType([])
    setStatus([])
    // setOpen(false)
    // setIsFilter(false)

    localStorage.removeItem("filter")
  }

  const handleTransactionType = async (value: string) => {
    let transactionTypeArray = transactionType
    if (transactionTypeArray?.includes(value)) {
      transactionTypeArray = await transactionTypeArray.filter(
        (item: string) => item !== value
      )
    } else {
      await transactionTypeArray?.push(value)
    }

    setTransactionType(transactionTypeArray)
    // Genetestge rendom number to rerender jsx
    setRendom(Math.random)
  }

  const handleStatus = async (value: string) => {
    let statusArray = status
    if (statusArray?.includes(value)) {
      statusArray = await statusArray.filter((item: string) => item !== value)
    } else {
      await statusArray.push(value)
    }
    setStatus(statusArray)
    // Genetestge rendom number to rerender jsx
    setRendom(Math.random)
  }

  return (
    <>
      {contextHolder}
      <Drawer
        title={t("filterTransactions")}
        placement={placement}
        closable={false}
        onClose={onClose}
        size={size}
        open={open}
        key={placement}
        extra={
          <Space>
            <h5 className="main-font-color" onClick={onClose}>
              {t("cancel")}
            </h5>
          </Space>
        }
      >
        <div className="row">
          <div className="search mb-2 filter">
            <Form form={form}>
              <Space>
                <Row gutter={24}>
                  <Col className="gutter-row" span={12}>
                    <Form.Item label={t("fromDate")} name="fromFromDate">
                      <Input.Group compact>
                        <DatePicker
                          className="datepicker-custom"
                          id="fromFromDate"
                          style={{ width: "100%" }}
                          placeholder="DD/MM/YYYY"
                          value={dayjs(dateFrom)}
                          format="DD-MM-YYYY"
                          allowClear={false}
                          disabledDate={disabledFromDate}
                          onChange={handleFromDate}
                          inputReadOnly={true}
                        />
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item label={t("toDate")}>
                      <Input.Group compact>
                        <DatePicker
                          className="datepicker-custom"
                          id="fromFromDate"
                          style={{ width: "100%" }}
                          placeholder="DD/MM/YYYY"
                          value={dayjs(dateTo)}
                          format="DD-MM-YYYY"
                          allowClear={false}
                          disabledDate={disabledToDate}
                          onChange={handleToDate}
                          inputReadOnly={true}
                        />
                      </Input.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Space>
              <div className="checkbox-button-long">
                <label>{t("type")}</label>
                <div>
                  <Button
                    size="middle"
                    className={
                      transactionType?.includes("DEPOSIT")
                        ? "main-border-color main-font-color"
                        : ""
                    }
                    style={{
                      width: "100%",
                      border: "1px solid #ababab",
                      color: "#ababab"
                    }}
                    onClick={() => handleTransactionType("DEPOSIT")}
                  >
                    {t(`transactionsTypes.${"Topup"}`)}
                  </Button>
                  <Button
                    size="middle"
                    className={
                      transactionType?.includes("PEER_TO_PEER")
                        ? "main-border-color main-font-color"
                        : ""
                    }
                    style={{
                      width: "100%",
                      border: "1px solid #ababab",
                      color: "#ababab"
                    }}
                    onClick={() => handleTransactionType("PEER_TO_PEER")}
                  >
                    {t(`transactionsTypes.${"Transfer"}`)}
                  </Button>
                  <Button
                    size="middle"
                    className={
                      transactionType?.includes("WITHDRAW")
                        ? "main-border-color main-font-color"
                        : ""
                    }
                    style={{
                      width: "100%",
                      border: "1px solid #ababab",
                      color: "#ababab"
                    }}
                    onClick={() => handleTransactionType("WITHDRAW")}
                  >
                    {t(`transactionsTypes.${"Redeem Unspent"}`)}
                  </Button>
                  <Button
                    size="middle"
                    className={
                      transactionType?.includes("POS,OTHER")
                        ? "main-border-color main-font-color"
                        : ""
                    }
                    style={{
                      width: "100%",
                      border: "1px solid #ababab",
                      color: "#ababab"
                    }}
                    onClick={() => handleTransactionType("POS,OTHER")}
                  >
                    {t(`transactionsTypes.${"Payment"}`)}
                  </Button>
                  <Button
                    size="middle"
                    className={
                      transactionType?.includes("REFUND")
                        ? "main-border-color main-font-color"
                        : ""
                    }
                    style={{
                      width: "100%",
                      border: "1px solid #ababab",
                      color: "#ababab"
                    }}
                    onClick={() => handleTransactionType("REFUND")}
                  >
                    {t(`transactionsTypes.${"Merchant Refund"}`)}
                  </Button>
                </div>
              </div>
              <div className="success-button">
                <label>{t("status")}</label>
                <div>
                  <Button
                    size="middle"
                    className={
                      status?.includes("SUCCESSFUL")
                        ? "main-border-color main-font-color"
                        : ""
                    }
                    style={{
                      width: "100%",
                      border: "1px solid #ababab",
                      color: "#ababab"
                    }}
                    value={"SUCCESSFUL"}
                    onClick={() => handleStatus("SUCCESSFUL")}
                  >
                    {t("successful")}
                  </Button>
                  <Button
                    size="middle"
                    className={
                      status?.includes("IN_PROGRESS")
                        ? "main-border-color main-font-color"
                        : ""
                    }
                    style={{
                      width: "100%",
                      border: "1px solid #ababab",
                      color: "#ababab"
                    }}
                    onClick={() => handleStatus("IN_PROGRESS")}
                  >
                    {t("pending")}
                  </Button>
                  <Button
                    size="middle"
                    className={
                      status?.includes("FAILED")
                        ? "main-border-color main-font-color"
                        : ""
                    }
                    style={{
                      width: "100%",
                      border: "1px solid #ababab",
                      color: "#ababab"
                    }}
                    onClick={() => handleStatus("FAILED")}
                  >
                    {t("failed")}
                  </Button>
                </div>
              </div>
              <Space>
                <h5
                  onClick={() => resetTransactionFilter()}
                  style={{ padding: "10px" }}
                >
                  {t("resetFilters")}
                </h5>
              </Space>
              <Space>
                <Button
                  onClick={() => transactionFilter()}
                  className="alignCenter applyButton active-btn-style"
                  size={"middle"}
                >
                  {t("apply")}
                </Button>
              </Space>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  )
}
export default Filter
