import React, { useRef, useState, useEffect } from "react"
import { Button, notification } from "antd"
import { useTranslation } from "react-i18next"
import QrScanner from "qr-scanner"
import "./ScanQRCode.scss"
import { RouteType } from "src/constants/routeTypes"
import { Link, useNavigate } from "react-router-dom"

import { useGetDecryptedData, useGetUserProfileDetails } from "../../api/user"
import { TransactionTypeControl } from "../../api/constants"
import AlertPopupModal from "../AlertPopupModal"

const ScanQRCode: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const video = useRef<HTMLVideoElement>(null)
  const [qrScanner, setQrScanner] = useState<QrScanner>()
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false)
  const [qrTransactionId, setQrTransactionId] = useState("")
  const [walletAddress, setWalletAddress] = useState("")
  const [qrAsset, setQrAsset] = useState("")
  const [qrAmount, setQrAmount] = useState("")
  const [qrMerchantId, setQrMerchantId] = useState("")
  const [qrPosID, setQrPosID] = useState("")
  const [qrMerchantName, setQrMerchantName] = useState("")
  const [api, contextHolder] = notification.useNotification()
  const [isAPIScheduled, setIsAPIScheduled] = useState(false)
  const [qrData, setQrData] = useState<string>("")
  const [transactionTypecontrolQuery, setTransactionTypecontrolQuery] =
    useState(TransactionTypeControl.PURCHASE)

  // Notifications
  const openNotification = (
    type: string,
    description: string,
    message: any = t("anErrorOccurred")
  ) => {
    if (type === "success") {
      notification["success"]({
        message: "Success",
        description,
        placement: "bottom"
      })
    } else {
      notification["error"]({
        message: message,
        description,
        placement: "bottom"
      })
    }
  }

  const {
    data: profileData,
    isFetching: isFetchingProfileDetails,
    refetch: refetchGetUserProfileDetails,
    error: errorProfileDetails
  } = useGetUserProfileDetails()

  // get decrypted data
  const {
    mutate: getDecryptedData,
    isLoading: isGetDecryptedDataLoading,
    error: getDecryptedDataError,
    isSuccess: isGetDecryptedDataSuccess,
    data: getDecryptedSuccessData
  } = useGetDecryptedData()

  useEffect(() => {
    if (video.current) {
      const qrScanner = new QrScanner(
        video.current,
        (result) => handleScan(result),
        {
          highlightScanRegion: true
        }
      )
      qrScanner.start()
      setQrScanner(qrScanner)
    }
    // Dependency array missing handleScan, since it should not set Scanner on handleScan change
    // eslint-disable-next-line
  }, [video.current])

  // Function to handle scan
  function handleScan(result: QrScanner.ScanResult) {
    //Logic with scanned qr code
    if (result.data) {
      setQrData(result.data)
    }
  }

  useEffect(() => {
    if (qrData != "" && isAPIScheduled === false) {
      getDecryptedData({
        data: String(qrData)
      })
      setIsAPIScheduled(true)
      setQrData("")
    }
  }, [qrData, isAPIScheduled])

  // after get decrypted data
  useEffect(() => {
    if (isGetDecryptedDataSuccess && getDecryptedSuccessData) {
      const response = getDecryptedSuccessData
      setIsPaymentConfirmed(true)
      setIsAPIScheduled(false)
      utilizeDecryptedData(String(response))
    }
  }, [isGetDecryptedDataSuccess, getDecryptedSuccessData])

  // habdle the response error message for Decrypted Data
  useEffect(() => {
    if (getDecryptedDataError) {
      openNotification("error", t(`ERROR_MESSAGE.${"INVALID_QR"}`))
    }
  }, [getDecryptedDataError])

  useEffect(() => {
    if (isPaymentConfirmed && walletAddress.length > 0) {
      navigate(RouteType.SCANED_QR_CODE_PAYING, {
        state: {
          qrAddress: walletAddress,
          qrAmount: qrAmount,
          qrMerchantName: qrMerchantName,
          transactionTypeControlQuery: transactionTypecontrolQuery,
          qrAsset: qrAsset,
          transactionId: qrTransactionId,
          merchantId: qrMerchantId,
          posId: qrPosID
        }
      })
    }
  }, [isPaymentConfirmed, walletAddress])

  // function to utilize the decrypted data
  function utilizeDecryptedData(decryptedString: string) {
    let transactionId = ""
    let address = ""
    let asset = ""
    let amount = ""
    let merchantId = ""
    let posID = ""
    let merchantName = ""

    if (decryptedString.includes("|")) {
      // split and generate the array
      const qrData = decryptedString.split("|")
      // console.log("here in : ", qrData)

      if (qrData[0].includes(":")) {
        transactionId = qrData[0].split(":")[1]
      }

      if (qrData[1].includes(":")) {
        address = qrData[1].split(":")[1]
      }

      if (qrData[2].includes(":")) {
        const tempAsset = qrData[2].split(":")[1]

        if (
          tempAsset === "SAR Token" ||
          tempAsset === "SART" ||
          tempAsset === "SARt" ||
          tempAsset === "sart"
        ) {
          asset = "SART"
        } else {
          asset = ""
        }
      }

      if (qrData[3].includes(":")) {
        amount = qrData[3].split(":")[1]
      } else {
        amount = ""
      }

      if (qrData[4].includes(":")) {
        merchantId = qrData[4].split(":")[1]
      } else {
        merchantId = "0"
      }

      if (qrData[5].includes(":")) {
        posID = qrData[5].split(":")[1]
      } else {
        posID = "0"
      }

      if (qrData[6].includes(":")) {
        merchantName = qrData[6].split(":")[1]
      } else {
        merchantName = "Name not found"
      }
    } else {
      address = decryptedString
    }

    const temp_transaction_id = transactionId.replace(/^0+/, "")
    const temp_merchantId = merchantId.replace(/^0+/, "")

    if (temp_transaction_id.length > 0) {
      setTransactionTypecontrolQuery(TransactionTypeControl.PURCHASE)
    } else if (temp_merchantId.length > 0 && temp_transaction_id.length == 0) {
      setTransactionTypecontrolQuery(TransactionTypeControl.MERCHANT_OFFLINE)
    } else {
      setTransactionTypecontrolQuery(TransactionTypeControl.P2P_TRANSFER)
    }

    if (address.length > 0) {
      // set values
      setQrAmount(amount)
      setQrAsset(asset)
      setQrMerchantName(merchantName)
      setWalletAddress(address)
      setQrTransactionId(transactionId)
      setQrMerchantId(merchantId)
      setQrPosID(posID)
    } else {
      openNotification("error", t(`ERROR_MESSAGE.${"INVALID_QR"}`))
    }
  }

  useEffect(() => {
    // get token from local storage
    const token = localStorage.getItem("token")
    if (token === undefined || token == null || token === "") {
      navigate(RouteType.AUTH_ERROR)
      window.location.reload()
    }
  }, [])

  return (
    <>
      {contextHolder}
      <div className="geidea mb-50">
        {/* show Scanner */}
        <div className="backgroundBlack"></div>
        <div className="row">
          <div className="col-lg-12 mb-3">
            <span className="videoAdd">
              <video autoPlay className="video" ref={video}></video>
              <Link
                to={RouteType.SHOW_MY_QR}
                state={{ from: RouteType.SCAN_QR_CODE }}
                style={{ textDecoration: "none" }}
              >
                <Button
                  className="alignCenter"
                  size={"large"}
                  style={{ color: "white" }}
                >
                  <img className="qr-icon" src="/images/QR.png"></img> &nbsp;
                  {t("showMyQR")}
                </Button>
              </Link>
            </span>
          </div>
        </div>
      </div>
      {profileData && !profileData?.isActivationFeeCharged && (
        <AlertPopupModal isVisible={true} />
      )}
    </>
  )
}

export default ScanQRCode
