import React, { createContext, useContext } from "react"
import { Input } from "antd"
import { SearchOutlined, CloseOutlined } from "@ant-design/icons"

type Props = {
  setSearch: (value?: string) => void
  placeholderTxt: string
}

const Search: React.FC<Props> = ({ setSearch, placeholderTxt }: Props) => {
  return (
    <div className="search mb-2 mt-2">
      <Input
        size="large"
        placeholder={placeholderTxt}
        prefix={<SearchOutlined />}
        allowClear={{ clearIcon: <CloseOutlined style={{ color: "#000" }} /> }}
        onChange={(e) => setSearch(e.target.value)}
        autoFocus={true}
      />
    </div>
  )
}

export default Search
