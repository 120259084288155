import React, { useState, useEffect, useContext } from "react"
import { Skeleton, notification } from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AddContactContext, SearchContext } from "src/context/Search"
import "./ManageContacts.scss"
import Search from "src/components/searchFilter/Search"
import { RouteType } from "src/constants/routeTypes"

import AddContact from "./AddContact"
import { useUserContactsGeidea } from "../../api/user"
import { UserContactGeidea } from "../../api/models"

const Settings: React.FC = () => {
  const { t } = useTranslation()
  const { isSearch, setIsSearch } = useContext(SearchContext)
  const { isAddContact, setAddContact } = useContext(AddContactContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { from } = location.state
  const [loading, setLoading] = useState<boolean>(true)
  const [userContacts, setUserContacts] = useState<UserContactGeidea>()
  const [query, setQuery] = useState("search=")
  const [search, setSearch] = useState<string | undefined>("")
  const [addContact, setAddContactPopUp] = useState<string | undefined>("")
  const [selectedContact, setSelectedContact] = useState<any>()
  const [error, setError] = useState<string | null>(null)

  // Get user contacts API
  const {
    data: userConactsData,
    isFetching: isFetchingUserConacts,
    error: errorUserConacts,
    refetch
  } = useUserContactsGeidea(query)

  useEffect(() => {
    if (userConactsData) {
      // set error null
      setError(null)

      userConactsData.sort((a, b) => {
        const nameA = a.nickname
          ? a.nickname.toLowerCase()
          : a.email.toLowerCase()
        const nameB = b.nickname
          ? b.nickname.toLowerCase()
          : b.email.toLowerCase()
        return nameA.localeCompare(nameB)
      })

      setUserContacts(userConactsData)

      // update the loading state
      setLoading(false)
    }
  }, [userConactsData])

  useEffect(() => {
    setIsSearch(false)
  }, [])

  useEffect(() => {
    setLoading(true)

    const delayDebounceFn = setTimeout(() => {
      setQuery("search=" + search)
      setLoading(false)
    }, 300) // Adjust the delay as needed

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    if (errorUserConacts) {
      setError(errorUserConacts.message)

      // update the loading state
      setLoading(false)
    }
  }, [errorUserConacts])

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target }: any = event

    // Prevent input if it exceeds 50 characters
    if (target.value.length >= 50) {
      event.preventDefault()
    }
  }

  return (
    <>
      <div className="geidea mb-50" style={{ marginTop: "55px" }}>
        <div className="row mt-4">
          {isSearch ? (
            <Search
              placeholderTxt={t("searchUserByUserIdOrPhoneNumber")}
              setSearch={(value?: string) => setSearch(value)}
            />
          ) : null}
          {isAddContact ? (
            <>
              <AddContact
                isOpen={isAddContact}
                setContactPopup={(value?: string) => setAddContactPopUp(value)}
                contact={
                  from == "/internaltransfer" ? undefined : selectedContact
                }
                updateContact={(value?: boolean) =>
                  setSelectedContact(undefined)
                }
              />
            </>
          ) : null}
          {error ? (
            <>
              <div className="mt-0">&nbsp;</div>
              <div style={{ color: "red", marginTop: "-25px" }}>
                Error: {error}
              </div>
            </>
          ) : loading ? (
            <>
              {[...Array(7)].map((_, index) => (
                <Skeleton
                  key={index}
                  className={index === 0 ? "mt-4" : ""}
                  active
                />
              ))}
            </>
          ) : userContacts?.length !== undefined && userContacts?.length > 0 ? (
            <>
              {/* <p className="m-0 font-weight-bold float-start">A</p> */}
              <div
                className="table-responsive shadow mb-5"
                style={{ background: "#ffffff" }}
              >
                <table className="customTable customTableContacts table">
                  <tbody>
                    {userContacts?.map((element, key) => {
                      return (
                        <tr
                          key={key}
                          style={{
                            height: "80px"
                          }}
                          onClick={() => {
                            if (from == "/internaltransfer") {
                              navigate(RouteType.INTERNAL_TRANSFER, {
                                state: {
                                  from: RouteType.MANAGE_CONTACTS,
                                  userContact: element
                                }
                              })
                            } else {
                              setAddContact(true)
                              setSelectedContact(element)
                            }
                          }}
                        >
                          <td key={key}>
                            <span
                              className="tItem1 dot"
                              style={{ marginTop: "15px" }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  left: "13px",
                                  top: "6px",
                                  fontSize: "15px"
                                }}
                              >
                                {element.nickname === null &&
                                element.nickname !== ""
                                  ? element?.email[0]?.toUpperCase()
                                  : element?.nickname[0]?.toUpperCase()}
                              </span>
                            </span>
                            <span
                              className="tItem2"
                              style={{
                                marginTop: "5px",
                                fontSize: "14px",
                                marginLeft: "35px",
                                color: "#a39898"
                              }}
                            >
                              {element?.nickname}
                            </span>
                            <span
                              className="tItem3"
                              style={{
                                marginTop: "25px",
                                marginLeft: "35px",
                                color: "#000"
                              }}
                            >
                              {element?.email}
                            </span>
                            <span
                              className="tItem4"
                              style={{
                                marginLeft: "-5px",
                                marginTop: "45px"
                              }}
                            >
                              {element.phoneNumber}
                            </span>
                            <span className="tItem6"></span>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <p
              style={{
                textAlign: "center",
                marginTop: "10px"
              }}
            >
              {t("noContactsVailable")}
            </p>
          )}
        </div>
      </div>
    </>
  )
}

export default Settings
