import React from "react"
import { Row, Col, Space } from "antd"

export enum KEY {
  ONE = "1",
  TWO = "2",
  THREE = "3",
  FOUR = "4",
  FIVE = "5",
  SIX = "6",
  SEVEN = "7",
  EIGHT = "8",
  NINE = "9",
  DECIMAL_POINT = "",
  ZERO = "0",
  BACKSPACE = "BACKSPACE"
}

const keys = [
  [KEY.ONE, KEY.TWO, KEY.THREE],
  [KEY.FOUR, KEY.FIVE, KEY.SIX],
  [KEY.SEVEN, KEY.EIGHT, KEY.NINE],
  [KEY.DECIMAL_POINT, KEY.ZERO, KEY.BACKSPACE]
]

type NumericKeyboardProps = {
  value: string
  onChange: (value: string) => void | undefined
  isValueAmount?: boolean | undefined
  maxValueLength?: number | undefined
  hideDecimalPoint?: boolean | undefined
  disabledKeys?: KEY[] | undefined
}

const NumericKeyboard: React.FC<NumericKeyboardProps> = ({
  value,
  onChange,
  isValueAmount,
  maxValueLength,
  hideDecimalPoint = false,
  disabledKeys = []
}: NumericKeyboardProps) => {
  const _onChange = (key: KEY) => {
    if (disabledKeys && !disabledKeys.includes(key)) {
      if (key === KEY.BACKSPACE) {
        if (isValueAmount && value.length === 1) {
          onChange("0")
        } else {
          onChange(value.substr(0, value.length - 1))
        }
      } else if (!maxValueLength || value.length < maxValueLength) {
        if (
          key === KEY.DECIMAL_POINT &&
          value.indexOf(KEY.DECIMAL_POINT) > -1
        ) {
          onChange(value)
        } else {
          if (isValueAmount && value === "0" && key !== KEY.DECIMAL_POINT) {
            onChange(key)
          } else {
            onChange(value + key)
          }
        }
      }
    }
  }

  return (
    <div>
      {keys.map((row, rowIndex) => (
        <Row
          key={rowIndex}
          gutter={[16, 16]}
          style={{ padding: "10px 0px 10px 0px" }}
        >
          {row.map((key) => (
            <Col
              key={key}
              style={{ justifyContent: "center", textAlign: "center" }}
              span={8}
              onClick={
                hideDecimalPoint && key === KEY.DECIMAL_POINT
                  ? // eslint-disable-next-line @typescript-eslint/no-empty-function
                    () => {}
                  : () => _onChange(key)
              }
            >
              {key === KEY.BACKSPACE ? (
                value.length > 0 ? (
                  <img src="/images/clear-icon.svg"></img>
                ) : null
              ) : (
                <Space style={{ fontSize: "24px", fontStyle: "inter" }}>
                  {key}
                </Space>
              )}
            </Col>
          ))}
        </Row>
      ))}
    </div>
  )
}

export default NumericKeyboard
