import React, { useState } from "react"
import { Modal } from "antd"
import { useTranslation } from "react-i18next"

interface Props {
  isValidationErrorTextInput: boolean
  isChecked: boolean
  handleChange?: () => void
  isDisabled?: boolean
}

const PrivacyPolicyAgreement: React.FC<Props> = ({
  isValidationErrorTextInput,
  isChecked,
  handleChange,
  isDisabled
}: Props) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  //   const handleChange = () => {}

  return (
    <>
      <div className="row align-items-start">
        <div className="col-sm-12">
          <div
            className="walletChek form-check"
            style={{
              marginLeft: "12px",
              marginTop: "20px",
              marginBottom: "20px"
            }}
          >
            <input
              className="form-check-input main-border-color"
              type="checkbox"
              id="flexCheckChecked"
              onChange={handleChange}
              style={{
                borderRadius: "2px",
                border: "2px solid #6d6a6a",
                fontSize: "17px"
              }}
              checked={isChecked}
              disabled={isDisabled}
            />
            <label
              style={{
                color: isValidationErrorTextInput ? "red" : "black",
                fontSize: "13px"
              }}
              className="form-check-label align-items-start"
            >
              {t("iAccept")}{" "}
              <a
                href="javascript:void(0)"
                onClick={() => setModalOpen(true)}
                style={{
                  textDecoration: "none",
                  color: "rgb(89, 35, 127)"
                }}
              >
                {t("termsServices")}
              </a>{" "}
              {`${t("and")}`}{" "}
              <a
                href="javascript:void(0)"
                onClick={() => setModalOpen(true)}
                style={{
                  textDecoration: "none",
                  color: "rgb(89, 35, 127)"
                }}
              >
                {t("privacyPolicy")}
              </a>
            </label>
          </div>
        </div>
      </div>
      <Modal
        title=""
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={null}
      >
        <div>
          <p>Welcome to Pilgrim Wallet</p>
          <p>
            (“We”, “Us”, or “Our”) is committed to your privacy. This notice
            describes our Privacy Policy and how we treat data collected on our
            website and through the services we offer (“Site and Services”)
            accessible through mobile devices, computers, and tablets. The
            Privacy Policy is intended to inform and advise you about how we
            collect, use, and protect the personal information you provide. By
            visiting this site, you are accepting the practices described in
            this Privacy Policy. When we refer to “you” or “your”, we are
            referring to visitors to the Sites and Services, users of the Sites
            and Services, and subscribers to the Sites and Services.{" "}
          </p>
        </div>
      </Modal>
    </>
  )
}

export default PrivacyPolicyAgreement
