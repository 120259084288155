import React, { useState, useEffect, useContext, useRef } from "react"
import { useLocation } from "react-router-dom"
import "./InternalTransferPaymentDetails.scss"
import dayjs from "dayjs"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import { useTranslation } from "react-i18next"
import { useUserTransactionGeidea } from "src/api/user"
import { UserTransactionGeidea } from "src/api/models"
import { CopyOutlined } from "@ant-design/icons"
import copy from "copy-to-clipboard"
import { notification, Skeleton, Button, Card } from "antd"
import { UserDetailsContext } from "src/context/User"

import {
  getAmountTwoDecimal,
  showFromFeildValue,
  showToFeildValue,
  shownames,
  getTransactionType,
  getTotalName,
  sartTxt,
  getTranslatedString
} from "../../utils/Utils"

const { Meta } = Card

const InternalTransferPaymentDetails = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const ref = useRef<HTMLDivElement | null>(null)

  const trnsactionId = location?.state?.transactionId

  const [loading, setLoading] = useState<boolean>(true)
  const [copytext, setCopyText] = useState(false)
  const [showError, setShowError] = useState(false)
  const [isPDFDownload, setIsPDFDownload] = useState(false)
  const { userDetails } = useContext(UserDetailsContext)

  const downloadPdf = () => {
    setIsPDFDownload(true)

    // dealy download
    setTimeout(() => {
      download()
    }, 100)
  }

  const download = async () => {
    try {
      if (ref.current) {
        const canvas = await html2canvas(ref.current, {
          scrollX: 0,
          scrollY: 0,
          windowWidth: ref.current.offsetWidth,
          windowHeight: ref.current.offsetHeight
        })
        const imgData = canvas.toDataURL("image/png")
        const pdf = new jsPDF({
          unit: "px", // Set unit to pixels
          format: [canvas.width, canvas.height] // Set PDF format to match canvas size
        })
        pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height)
        pdf.save("transaction-receipt.pdf")
        setIsPDFDownload(false) // Reset the state
      }
    } catch (error) {
      console.error("Error generating PDF:", error)
      notification.error({
        message: "Error",
        description: "Failed to generate PDF",
        placement: "bottom"
      })
      setIsPDFDownload(false) // Reset the state in case of error
    }
  }

  const [userTransactions, setUserTransactions] =
    useState<UserTransactionGeidea>()

  let copymsgText = t("transactionIDCopied")
  const [copyHash, setCopyHash] = useState(false)

  const {
    isLoading: isFetching,
    error: userTransactionsDataError,
    isSuccess: userTransactionsDataSuccess,
    data: userTransactionsData
  } = useUserTransactionGeidea(trnsactionId)

  const copyToClipboard = () => {
    copy(values)
    setCopyText(true)
  }

  let values = ""
  const getTransactionId = (transaction: any) => {
    values =
      transaction.transactionType === "REFUND"
        ? transaction?.refundTransactionId || transaction.uuid
        : transaction.uuid
    copymsgText = t("transactionIDCopied")
    return values
  }

  useEffect(() => {
    if (userTransactionsData) {
      setUserTransactions(userTransactionsData)
      setLoading(false)
      setShowError(false)
    } else if (userTransactionsDataError) {
      console.log("loader")
      setLoading(false)
      setShowError(true)
    }
  }, [isFetching])

  useEffect(() => {
    if (copytext || copyHash) {
      notification["success"]({
        message: "",
        description: copymsgText,
        placement: "bottom",
        closeIcon: false
      })

      setTimeout(() => {
        setCopyText(false)
        setCopyHash(false)
      }, 5000)
    }
  }, [copytext, copyHash])

  const headerData = (title: string, description: string) => {
    return (
      <div className="customHeader" key={1}>
        <div className="fullWidth"></div>
        <div>
          <p
            style={{
              marginBottom: 0,
              color: "#717171",
              fontSize: "16px"
            }}
          >
            {t("transactionType")}
          </p>
          <p style={{ marginBottom: 0 }}>
            <p>
              <b>
                {userTransactions &&
                  t(
                    `transactionsTypes.${getTransactionType(
                      userTransactions,
                      userTransactions?.transactionType
                    )}`
                  )}
              </b>
            </p>
          </p>
        </div>
        <div>
          <p
            style={{
              marginBottom: 0,
              color: "#717171",
              fontSize: "16px"
            }}
          >
            {t(`transactionsTypes.${title}`)}
          </p>
          <p style={{ marginBottom: 0 }}>
            <p>
              <b>
                {title === "Period"
                  ? getTranslatedString(t, description, "Daily")
                  : t(`transactionsTypes.${description}`)}
              </b>
            </p>
          </p>
        </div>
      </div>
    )
  }

  return (
    <div
      className="geidea mb-50 internal-transfer-payment-details"
      style={{ alignItems: "center" }}
    >
      {loading ? (
        <>
          <Skeleton.Avatar
            style={{ marginTop: "30px" }}
            size={"large"}
            active
          ></Skeleton.Avatar>

          <Skeleton loading={loading} title={{ width: 200 }}></Skeleton>

          <Skeleton
            loading={loading}
            title={false}
            paragraph={{
              rows: 10,
              width: [300, 300, 200, 300, 300, 200, 300, 300, 200, 300]
            }}
          ></Skeleton>
          <Skeleton.Button size={"large"} active></Skeleton.Button>
        </>
      ) : showError ? (
        <div className="row" style={{ marginTop: "80px" }}>
          <div className="asset-as-header">
            <img
              style={{ width: "70px", marginBottom: "10px" }}
              src="/images/transaction_failed.png"
            ></img>
            <h3 className="status-failure-text">{t("paymentError")}</h3>
          </div>

          {/* <div className="shadow payment-field-row">
           */}
          <div
            className="col-lg-12 mb-3 send-external-component"
            style={{
              background: "#fff",
              padding: "40px",
              textAlign: "center"
            }}
          >
            {t("transactionNotAvailable")}
          </div>
        </div>
      ) : (
        <div>
          <div ref={ref} style={{ padding: "10px" }}>
            <div className="asset-as-header" style={{ marginTop: "80px" }}>
              {userTransactions &&
                userTransactions?.status === "SUCCESSFUL" &&
                userTransactions?.transactionType !== "SERVICE_FEE" &&
                userTransactions?.transactionType !== "WALLET_FEE" && (
                  <>
                    <img
                      style={{ width: "70px", marginBottom: "10px" }}
                      src="/images/Success.svg"
                    ></img>

                    <h3 className="status-text" style={{ color: "#27ae60" }}>
                      {t(
                        `transactionsTypes.${getTransactionType(
                          userTransactions,
                          userTransactions?.transactionType
                        )}`
                      ) +
                        " " +
                        t("successful")}
                    </h3>
                  </>
                )}
              {userTransactions && (
                <>
                  {(userTransactions.transactionType === "SERVICE_FEE" ||
                    userTransactions.transactionType === "WALLET_FEE") && (
                    <>
                      <img
                        style={{ width: "70px", marginBottom: "10px" }}
                        src={
                          userTransactions.transactionType === "WALLET_FEE"
                            ? "/images/ic_wallet_balance.svg"
                            : "/images/ServiceFee.svg"
                        }
                        alt={t("transactionType")}
                      />
                      <h3 className="status-text" style={{ color: "#000" }}>
                        {t(
                          `transactionsTypes.${getTransactionType(
                            userTransactions,
                            userTransactions.transactionType
                          )}`
                        )}
                      </h3>
                    </>
                  )}
                </>
              )}

              {userTransactions && userTransactions?.status === "FAILED" && (
                <>
                  <img
                    style={{ width: "70px", marginBottom: "10px" }}
                    src={"/images/transaction_failed.png"}
                  ></img>

                  <h3 className="status-text" style={{ color: "#000" }}>
                    {t("transactionFailed")}
                  </h3>
                </>
              )}
            </div>

            <>
              <h6 style={{ fontWeight: 500 }}>{t("transactionDetails")}</h6>
              <Card
                style={{ width: "100%", marginTop: "15px" }}
                title={
                  (userTransactions &&
                    shownames(userTransactions) === "Service Fee") ||
                  shownames(userTransactions) === "Wallet Low Balance Fee"
                    ? null
                    : t("transactionType")
                }
                extra={
                  (userTransactions &&
                    shownames(userTransactions) === "Service Fee") ||
                  shownames(userTransactions) ===
                    "Wallet Low Balance Fee" ? null : (
                    <p>
                      <b>
                        {userTransactions &&
                          t(
                            `transactionsTypes.${getTransactionType(
                              userTransactions,
                              userTransactions.transactionType
                            )}`
                          )}
                      </b>
                    </p>
                  )
                }
                actions={[
                  <Meta
                    key="1"
                    title={
                      (userTransactions &&
                        t(
                          `transactionsTitle.${getTotalName(
                            userTransactions,
                            userTransactions?.transactionType
                          )}`
                        )) ||
                      t(`transactionsTitle.${"Amount Received"}`)
                    }
                    description={
                      userTransactions?.transactionType == "Redeem Unspent" ||
                      userTransactions?.transactionType == "Refund" ||
                      userTransactions?.transactionType == "WITHDRAW"
                        ? `${
                            userTransactions?.fiatAsset === "SART"
                              ? sartTxt
                              : userTransactions?.fiatAsset
                          } ${
                            userTransactions?.totalAmount !== undefined
                              ? getAmountTwoDecimal(
                                  userTransactions?.fiatAmount
                                )
                              : 0.0
                          }`
                        : `${
                            userTransactions?.asset === "SART"
                              ? sartTxt
                              : userTransactions?.asset
                          } ${
                            userTransactions?.totalAmount !== undefined
                              ? getAmountTwoDecimal(
                                  userTransactions?.totalAmount
                                )
                              : 0.0
                          }`
                    }
                  />
                ]}
              >
                {userTransactions &&
                  shownames(userTransactions) === "Service Fee" &&
                  userTransactions?.feeConfigData?.map((x: any, index: any) => {
                    return headerData("Period", x.description)
                  })}

                {userTransactions &&
                  shownames(userTransactions) === "Wallet Low Balance Fee" &&
                  userTransactions?.feeConfigData?.map((x: any, index: any) => {
                    return headerData("Description", x.description)
                  })}

                {userTransactions &&
                  shownames(userTransactions) !== "Service Fee" &&
                  userTransactions &&
                  shownames(userTransactions) !== "Wallet Low Balance Fee" && (
                    <>
                      <Meta
                        title={
                          (userTransactions &&
                            userTransactions?.transactionType === "Topup") ||
                          userTransactions?.transactionType === "DEPOSIT"
                            ? t("amountRequested")
                            : userTransactions?.transactionType ==
                                "Redeem Unspent" ||
                              userTransactions?.transactionType == "Refund" ||
                              userTransactions?.transactionType == "REFUND" ||
                              userTransactions?.transactionType == "WITHDRAW"
                            ? t("tokensRequested")
                            : t("tokens")
                        }
                        description={`${
                          userTransactions &&
                          ["Topup", "DEPOSIT", "Refund", "WITHDRAW"].includes(
                            shownames(userTransactions)
                          )
                            ? userTransactions?.fiatAsset === "SART"
                              ? sartTxt
                              : userTransactions?.fiatAsset
                            : userTransactions?.totalRequestedAmountAsset ===
                              "SART"
                            ? sartTxt
                            : userTransactions?.totalRequestedAmountAsset
                        } ${
                          userTransactions &&
                          ["Topup", "DEPOSIT", "Refund", "WITHDRAW"].includes(
                            shownames(userTransactions)
                          )
                            ? getAmountTwoDecimal(userTransactions.fiatAmount)
                            : getAmountTwoDecimal(
                                userTransactions.totalRequestedAmount
                              )
                        }`}
                      />
                      {userTransactions?.totalFeeApplied > 0 ? (
                        userTransactions?.feeConfigData?.length > 1 ? (
                          userTransactions?.feeConfigData?.map(
                            (x: any, index: number) => {
                              return x.feeName != null && x.feeName !== "" ? (
                                <Meta
                                  key={x.feeName}
                                  title={t(`feeTypes.${x.feeName}`)}
                                  description={`${
                                    x.currencyType || x.currencyUnit
                                  } ${getAmountTwoDecimal(
                                    x.feeCalculatedAmount || x?.feeAmount
                                  )}`}
                                />
                              ) : null
                            }
                          )
                        ) : (
                          <Meta
                            title={
                              userTransactions?.transactionType === "Topup" ||
                              userTransactions?.transactionType === "DEPOSIT"
                                ? t(`feeTypes.${"Topup Fee"}`)
                                : userTransactions?.transactionType ==
                                    "Redeem Unspent" ||
                                  userTransactions?.transactionType ==
                                    "Refund" ||
                                  userTransactions?.transactionType ==
                                    "WITHDRAW"
                                ? t(`feeTypes.${"Redeem Unspent Fee"}`)
                                : t(`feeTypes.${"Fee"}`)
                            }
                            description={`${
                              userTransactions?.totalFeeAppliedAsset === "SART"
                                ? sartTxt
                                : userTransactions?.totalFeeAppliedAsset
                            } ${getAmountTwoDecimal(
                              userTransactions.totalFeeApplied
                            )}`}
                          />
                        )
                      ) : (
                        <Meta
                          title={t(`feeTypes.${"Fee"}`)}
                          description={`0.00`}
                        />
                      )}
                      {userTransactions &&
                      ["Topup", "DEPOSIT", "Refund", "WITHDRAW"].includes(
                        shownames(userTransactions)
                      ) ? (
                        <Meta
                          title={t("amountDebited")}
                          description={`${
                            userTransactions.totalRequestedAmountAsset ===
                            "SART"
                              ? sartTxt
                              : userTransactions.totalRequestedAmountAsset
                          } ${getAmountTwoDecimal(
                            userTransactions.totalRequestedAmount
                          )}`}
                        />
                      ) : null}

                      {(userTransactions &&
                        userTransactions?.transactionType ==
                          "Redeem Unspent") ||
                      userTransactions?.transactionType == "Refund" ||
                      userTransactions?.transactionType == "WITHDRAW" ? (
                        <Meta
                          title={t("netRedeemTokens")}
                          description={`${
                            userTransactions.asset === "SART"
                              ? sartTxt
                              : userTransactions.asset
                          } ${getAmountTwoDecimal(userTransactions.amount)}`}
                        />
                      ) : null}
                    </>
                  )}
              </Card>
            </>

            {/* // TransactionId */}
            {isPDFDownload ? (
              <div className="shadow payment-field-row">
                <div>
                  <div>{t("transactionId")}</div>
                  <div>{getTransactionId(userTransactions)}</div>
                </div>
              </div>
            ) : (
              <div className="shadow payment-field-row">
                <div className="text-field-name">{t("transactionId")} </div>
                <div className="text-field-value">
                  {getTransactionId(userTransactions)?.slice(0, 16)}
                  {"... "}
                  <CopyOutlined onClick={copyToClipboard} />{" "}
                </div>
              </div>
            )}

            {/* // From  */}
            <div className="shadow payment-field-row">
              <div className="text-field-name">{t("from")}</div>
              <div className="text-field-value">
                {userTransactions &&
                  showFromFeildValue(userTransactions, userDetails?.email)}
              </div>
            </div>

            {/* // To  */}
            <div className="shadow payment-field-row">
              <div className="text-field-name">{t("to")}</div>
              <div className="text-field-value">
                {userTransactions && showToFeildValue(userTransactions)}
              </div>
            </div>

            {/* // Time  */}
            <div className="shadow payment-field-row">
              <div className="text-field-name">{t("time")}</div>
              <div className="text-field-value">
                {userTransactions &&
                  dayjs(
                    userTransactions?.paymentReceivedDate
                      ? userTransactions?.paymentReceivedDate
                      : userTransactions?.createdAt
                  ).format("D MMM YYYY, hh:mm a")}
              </div>
            </div>
          </div>

          <Button
            size="large"
            style={{
              width: "100%",
              background: "rgb(255 194 53)",
              borderColor: "rgb(255 194 53)",
              marginBottom: "100px"
            }}
            className="border-button-refund active-btn-style"
            onClick={() => {
              downloadPdf()
            }}
          >
            {t("download")}
          </Button>
        </div>
      )}
    </div>
  )
}

export default InternalTransferPaymentDetails
