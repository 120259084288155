import { FiatTokenToAmount, TokenToAmount } from "src/constants/types"

export enum EndpointKey {
  SEND_PHONE_OTP = "sendPhoneOTP",
  VERIFY_EMAIL_OTP_CREATE_USER = "verifyEmailOTPAndCreateUser",
  GET_USER = "getUser",
  VERIFY_PHONE_OTP = "verifyPhoneOTP",
  USER_DETAILS_SEND_EMAIL_OTP = "userDetailsAndEmailOTP",
  SEND_PHONE_OTP_COMMON = "sendOTP",
  VERIFY_PHONE_OTP_COMMON = "verifyOTP",
  USER_BALANCES = "userBalances",
  MERCHANT_TRANSACTION = "merchantTransaction",
  MERCHANT_TRANSACTIONS = "merchantTransactions",
  REFUND_TRANSACTIONS = "refundTransactions",
  REFUND_ACCEPT_REJECT_TRANSACTIONS = "refundAcceptRejectTransactions",
  REFUND_INITIATE_WEBLINK = "refundInitiateWebLink",
  REFUND_SUBMIT_WITHAUTH = "refundSubmitFormWithAuth",
  EXCHANGE_RATES = "exchangeRates",
  USER_TRANSACTION = "userTransaction",
  USER_TRANSACTIONS = "userTransactions",
  MERCHANT_DETAILS = "merchantDetails",
  P2P_TRANSACTIONS = "p2pTransaction",
  GENERATE_API_KEY = "generateAPIKey",
  INVITE_USER = "inviteUser",
  MERCHANT = "merchant",
  USER_LIST = "userList",
  ENABLE_USER = "enableUser",
  DISABLE_USER = "disableUser",
  SUBMIT_REFUND_FORM_DATA = "submitRefundForm",
  GET_REFUND_FORM = "getRefundForm",
  REFUND_EXCHANGE_RATES = "refundExchangeRates",
  RECENT_PAYMENT = "recentPayment",
  ATM_WITHDRAW = "atmWithDraw",
  USER_VERIFY = "userVerify",
  USER_VERIFY_RESET = "userVerifyReset",
  REFUND_FORM_FIELDS = "refundFormFields",
  USER_BALANCES_GEIDEA = "userBalancesGeidea",
  SEND_DIGITAL_CRTPTO_TO_EXTERNAL_DATA = "sendDigitalCurrencyToExternalWallet",
  FIAT_EXCHANGE_RATE = "fiatExchangeRates",
  TOPUP = "loadToken",
  GET_JWT_TOKEN = "getJwtToken",
  USER_TRANSACTIONS_GEIDEA = "UserTransactionsDetails",
  USER_TRANSACTION_GEIDEA = "userTransactionGeidea",
  ADD_TRANSACTION = "addUserTransaction",
  USER_CONTACTS_GEIDEA = "userContactsGeidea",
  REFUND = "refundToken",
  GET_USER_BY_EMAIL_OR_MOBILE = "getUserByEmailOrMobile",
  ADDRESSES = "addresses",
  ADD_USER_CONTACT = "addUserContact",
  UPDATE_USER_CONTACT = "updateUserContact",
  DELETE_CONTACT_OF_USER = "deleteContactOfUser",
  ENCRYPT_STRING = "getEncryptedQR",
  DECRYPT_STRING = "getDecryptedQR",
  GET_TRANSACTION_VALIDATION = "getTransactionValidation",
  GET_PROFILE_DETAILS = "getProfileDetails",
  SAVE_PASSCODE = "savePasscode",
  GET_DECRYPTED_EMAIL = "getDecryptedEmail",
  GET_MAPPED_LANGUAGES = "getMappedLanguages"
}

export const useApi: () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in EndpointKey]: (...args: any[]) => string
} = () => {
  return {
    getUser: (query: string) => `user?${query}`,
    sendPhoneOTP: () => "user/registration/phone/",
    verifyPhoneOTP: () => "user/registration/phone/verify/",
    userDetailsAndEmailOTP: () => "user/registration/details/",
    verifyEmailOTPAndCreateUser: () => "user/registration/verify-and-create/",
    userBalances: () => "merchantDashboard/balances",
    merchantTransaction: (id: string) => `merchant/transactions/${id}`,
    merchantTransactions: (query: string) =>
      `merchant/transaction/search?${query}`,
    refundTransactions: (query: string) =>
      `merchant/refund/transactions?${query}`,
    refundAcceptRejectTransactions: () => "merchant/refund/approve",
    refundInitiateWebLink: () => "merchantDashboard/initiateWebLinkRefund",
    refundSubmitFormWithAuth: () => "merchantDashboard/submitRefundForm",
    exchangeRates: (fiat: string) => `v1/config/exchangeRates?from=${fiat}`,
    userTransaction: (id: string) => `user/transactions/${id}`,
    userTransactions: (query: string) =>
      `merchantDashboard/transactions?${query}`,
    merchant: () => "merchantDashboard/admin/merchant/",
    p2pTransaction: () => "merchantDashboard/admin/p2p_transaction",
    generateAPIKey: () => "merchantDashboard/admin/api-key",
    inviteUser: () => "merchantDashboard/admin/invite/",
    merchantDetails: () => "merchantDashboard/merchantDetails",
    userList: () => "merchantDashboard/getAllUsersOfMerchant",
    enableUser: () => "merchantDashboard/admin/enable",
    disableUser: () => "merchantDashboard/admin/disable",
    submitRefundForm: () => "api/v1/pos/refundForm",
    getRefundForm: () => "api/v1/merchantDashboard/getRefundDetailsFromToken",
    refundExchangeRates: (query: string) =>
      `api/v1/merchantDashboard/exchangeRates?from=${query}`,
    recentPayment: () => "merchantDashboard/requestPayment",
    atmWithDraw: () => "bdo/withdraw/wallet",
    userVerify: (query: string) => `api/v1/userVerify?${query}`,
    userVerifyReset: (query: string) => `api/v1/userVerifyReset?${query}`,
    refundFormFields: (query: string) =>
      `api/v1/merchantDashboard/refundFormFields?formName=${query}`,
    userBalancesGeidea: () => "user/balances",
    UserTransactionsDetails: (query: string) => `user/transactions?${query}`,
    sendDigitalCurrencyToExternalWallet: () =>
      "user/account/sendDigitalCurrencyToExternalWallet",
    fiatExchangeRates: (fiat: string) =>
      `issuanceWallet/fiatExchangeRates?from=${fiat}`,
    loadToken: () => "issuance/user/loadToken",
    getJwtToken: (query: string) => `api/v1/getJwtTokenForWebViewApp?${query}`,
    userTransactionGeidea: (id: string) => `user/transactions/${id}`,
    userContactsGeidea: (query: string) => `user/contacts?${query}`,
    addUserTransaction: () => "user/account/p2p_transaction",
    refundToken: () => "issuance/user/refundToken",
    getUserByEmailOrMobile: (query: string) =>
      `user/getUserByEmailOrMobile?${query}`,
    addresses: () => "user/account/addresses",
    addUserContact: () => "user/contact",
    updateUserContact: () => "user/contact",
    deleteContactOfUser: () => "user/contact",
    getEncryptedQR: () => "user/getEncryptedQR",
    getDecryptedQR: () => "user/getDecryptedQR",
    getTransactionValidation: (query: string) =>
      `issuanceWallet/getTransactionValidation?type=${query}`,
    getProfileDetails: () => "user/getProfileDetails",
    sendOTP: () => "user/common/sendOTP/",
    verifyOTP: () => "user/common/verifyOTP/",
    savePasscode: () => "user/savePasscode/",
    getDecryptedEmail: (query: string) =>
      `api/v1/user/getDecryptedEmail?${query}`,
    getMappedLanguages: (query: string) => `api/v1/getLanguages?`
  } as const
}

export const calculateTimeLeft: (targetDate: Date) => number = (targetDate) => {
  const now = new Date()
  const difference = +targetDate - +now
  return difference > 0 ? Math.floor((difference / 1000) % 60) : 0
}

export const AssetFractionDigits: TokenToAmount = {
  BTC: 8,
  ETH: 8,
  USDT: 8,
  WTK: 8,
  USDC: 8,
  SART: 2
}

export const FiatAssetFractionDigits: FiatTokenToAmount = {
  USD: 2,
  EUR: 2,
  INR: 1,
  IDR: 1,
  SGD: 2,
  GBP: 2,
  PKR: 1,
  PHP: 1,
  AED: 2,
  WTK: 1,
  USDC: 1
}

export enum TransactionTypeControl {
  INITIAL_LOADING = "TTC_001",
  SUBSEQUENT_LOADING = "TTC_002",
  PURCHASE = "TTC_003",
  MERCHANT_OFFLINE = "TTC_004",
  CUSTOMER_OFFLINE = "TTC_005",
  UNSPENT_DIGITAL_CURRENCY_REFUND = "TTC_006",
  P2P_TRANSFER = "TTC_007"
}
