import QRCode from "react-qr-code"
import React, { createRef, useState, useEffect, useContext } from "react"
import { Col, notification, Skeleton, Button } from "antd"
import { useNavigate, useLocation } from "react-router-dom"
import { createFileName, useScreenshot } from "use-react-screenshot"
import { CopyOutlined } from "@ant-design/icons"
import copy from "copy-to-clipboard"
import { useTranslation } from "react-i18next"
import { UserDetailsContext } from "src/context/User"

import { useAddresses, useGetEncryptedData } from "../api/user"
import "./scanQrCode/ScanQRCode.scss"

const ShowMyQR = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const ref = createRef()
  const [copytext, setCopyText] = useState(false)
  const copymsgText = t("walletAddressCopied")
  const [encryptedText, setEncryptedText] = useState("")
  const { userDetails } = useContext(UserDetailsContext)

  const {
    mutate: getEncryptedData,
    isLoading: isGetEncryptedDataLoading,
    error: getEncryptedDataError,
    isSuccess: isGetEncryptedDataSuccess,
    data: getEncryptedSuccessData
  } = useGetEncryptedData()

  const {
    data: addresses,
    isFetching: addressesIsFetching,
    error: addressesError,
    isSuccess: addressesIsSuccess
  } = useAddresses()

  useEffect(() => {
    const response = addresses
    if (response) {
      const walletAddress = addresses?.find(
        (obj) => obj?.asset === "SART"
      )?.address
      if (walletAddress) {
        getEncryptedData({
          data: `transactionID:00000|blockchainAddress:${walletAddress}|type:SART|transactionAmount:0|merchantId:000000|posId:000000|merchantDisplayName:${userDetails?.email}`
          //walletAddresses
        })
      }
    }
  }, [addressesIsSuccess])

  const walletAddresses = addresses?.find(
    (obj) => obj?.asset === "SART"
  )?.address

  useEffect(() => {
    if (copytext) {
      notification["success"]({
        message: t("success"),
        description: copymsgText,
        placement: "bottom"
      })

      setTimeout(() => {
        setCopyText(false)
      }, 5000)
    }
  }, [copytext])

  const copyToClipboard = () => {
    if (walletAddresses) copy(walletAddresses)
    setCopyText(true)
  }

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  })

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download)

  const download = (image: any, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a")
    a.href = image
    a.download = createFileName(extension, name)
    a.click()

    // message.success(savedSuccessTitle)
  }

  useEffect(() => {
    if (isGetEncryptedDataSuccess && getEncryptedSuccessData) {
      const response = getEncryptedSuccessData
      setEncryptedText(response?.toString())
    }
  }, [isGetEncryptedDataSuccess, getEncryptedSuccessData])

  return (
    <>
      <div
        ref={ref as React.RefObject<HTMLDivElement>}
        className="wdz-card, row"
      >
        <div
          className="mt-5"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "60%",
            marginLeft: "20%"
          }}
        >
          {encryptedText ? (
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={encryptedText}
              viewBox={`0 0 256 256`}
            />
          ) : (
            <Skeleton.Node
              className="skeletonImage"
              active={true}
            ></Skeleton.Node>
          )}
        </div>
        <h6
          className="main-font-color"
          style={{
            marginLeft: 2,
            marginTop: 20,
            marginBottom: 10,
            fontFamily: "Rubik-Medium",
            fontWeight: 500
          }}
        >
          {t("userId")}
        </h6>
        <div
          className="wdz-wallet-address-card"
          style={{
            display: "flex",
            backgroundColor: "lightgrey",
            flexDirection: "row",
            justifyContent: "space-between",
            marginLeft: "15px",
            width: "90%",
            marginBottom: "25px",
            marginTop: 0
          }}
        >
          <div
            className="overflow-text"
            onClick={() =>
              navigator.clipboard.writeText("Copy this text to clipboard")
            }
          >
            {userDetails?.email}
          </div>
        </div>
      </div>

      <h6
        className="main-font-color"
        style={{
          marginLeft: 2,
          marginTop: 20,
          marginBottom: 10,
          fontFamily: "Rubik-Medium",
          fontWeight: 500
        }}
      >
        {t("walletAddress")}
      </h6>
      <div
        className="wdz-wallet-address-card"
        style={{
          display: "flex",
          backgroundColor: "lightgrey",
          flexDirection: "row",
          justifyContent: "space-between",
          marginLeft: 3,
          width: "96%"
        }}
      >
        <div
          className="overflow-text"
          onClick={() =>
            navigator.clipboard.writeText("Copy this text to clipboard")
          }
        >
          {walletAddresses}
        </div>
        {<CopyOutlined onClick={copyToClipboard} />}
      </div>

      {/* Bottom Buttons */}
      <div
        style={{
          bottom: "100px",
          width: "100%",
          textAlign: "center"
        }}
      >
        <Col>
          <Button
            size="large"
            className="cancelQRButton"
            onClick={() => {
              navigate(location?.state?.from)
            }}
            style={{ color: "#000000", borderColor: "#000000" }}
          >
            {t("cancel")}
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            className="active-btn-style"
            size="large"
            onClick={() => {
              downloadScreenshot()
            }}
            style={{
              width: "40%",
              height: 50
            }}
          >
            {t("download")}
          </Button>
        </Col>
      </div>
    </>
  )
}

export default ShowMyQR
