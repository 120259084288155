import React, { useEffect, useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { UserDetailsContext } from "src/context/User"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { RouteType } from "src/constants/routeTypes"
import "./Navigation.scss"

const Navigation: React.FC = () => {
  const { t } = useTranslation()
  const { userDetails } = useContext(UserDetailsContext)
  const location = useLocation()
  const navigate = useNavigate()

  const [menuSelected, setMenuSelected] = useState("dashboard")
  const [isButtomNavigation, setIsButtomNavigation] = useState<boolean>(true)

  useEffect(() => {
    if (location) {
      const previusLocation = localStorage.getItem("previusLocation")
      if (location.state?.from == "Dashboard") {
        localStorage.setItem("previusLocation", "Dashboard")
      } else if (location.state?.from == "Transactions") {
        localStorage.setItem("previusLocation", "Transactions")
      }

      if (location.pathname == "/dashboard") {
        setMenuSelected("dashboard")
      }
      if (
        location.pathname.includes("transactions") ||
        location.pathname.includes("transaction-detail")
      ) {
        setMenuSelected("transactions")
      }
      if (location.pathname.includes("invoice")) {
        setMenuSelected("invoice")
      }
      if (location.pathname.includes("settings")) {
        setMenuSelected("settings")
      }
      if (location.pathname.includes("internaltransfer")) {
        setMenuSelected("internaltransfer")
      }
      if (location.pathname.includes("refund")) {
        setMenuSelected("refund")
      }
      if (location.pathname.includes("request-transaction")) {
        setMenuSelected("request-transaction")
      }
      if (
        [
          "/scan-qr-code-paying",
          "/PaymentSuccess",
          "/transaction-confirmation",
          "/topup",
          "/refund-page",
          "/payment-details",
          "/profile",
          "/manage-contacts",
          "/show-my-qr",
          "/verify-phone-otp",
          "/passcode"
        ].includes(location.pathname)
      ) {
        setIsButtomNavigation(false)
      } else {
        setIsButtomNavigation(true)
      }

      if (
        previusLocation === "Dashboard" &&
        !["/PaymentSuccess", "/payment-details"].includes(location.pathname)
      ) {
        localStorage.removeItem("previusLocation")
        navigate(RouteType.DASHBOARD)
      }

      if (previusLocation === "Transactions") {
        localStorage.removeItem("previusLocation")
        navigate(RouteType.TRANSACTIONS)
      }

      if (
        !["/internaltransfer", "/transaction-confirmation"].includes(
          location.pathname
        )
      ) {
        // Remore formdata
        localStorage.removeItem("formData")
      }

      if (!["/transactions", "/payment-details"].includes(location.pathname)) {
        // Remore formdata
        localStorage.removeItem("filter")
      }
    }
  }, [location])

  return userDetails && isButtomNavigation ? (
    <nav className="navbar fixed-bottom navbar-light shadow" role="navigation">
      <div className="d-flex flex-row justify-content-around w-100">
        <Link to={RouteType.DASHBOARD}>
          <img
            style={{ width: "45px" }}
            className={menuSelected === "dashboard" ? "active-tab" : ""}
            src="/images/navigation/tab1.svg"
          ></img>
        </Link>
        <Link
          to={RouteType.INTERNAL_TRANSFER}
          state={{ heading: "Internal Transfer" }}
        >
          <img
            style={{ marginTop: "15px", width: "25px" }}
            className={menuSelected === "internaltransfer" ? "active-tab" : ""}
            src="/images/navigation/tab2.svg"
          ></img>
        </Link>
        <Link
          to={RouteType.SCAN_QR_CODE}
          state={{ heading: t("scanAndPay") }}
          className="scan-pay-tab"
        >
          <img
            className={menuSelected === "scan-qr-code" ? "active-tab" : ""}
            src="/images/navigation/tab3.svg"
            style={{ width: "30px", marginLeft: "10px", marginTop: "13px" }}
          ></img>
        </Link>

        <Link to={RouteType.TRANSACTIONS} state={{ heading: "Transactions" }}>
          <img
            style={{ marginTop: "10px", width: "31px" }}
            className={menuSelected === "transactions" ? "active-tab" : ""}
            src="/images/navigation/tab4.svg"
          ></img>
        </Link>

        <Link to={RouteType.SETTINGS} state={{ heading: "Settings" }}>
          <img
            style={{ marginTop: "6px", width: "35px" }}
            className={menuSelected === "settings" ? "active-tab" : ""}
            src="/images/navigation/tab5.svg"
          ></img>
        </Link>
      </div>
    </nav>
  ) : null
}

export default Navigation
