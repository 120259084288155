import React, { useState, useEffect } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import { Provider } from "react-redux"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

import "./App.scss"
import "antd/dist/reset.css"
import "./i18n"
import appStore from "./utils/appStore"
import { OnboardingContextProvider } from "./context"
import { UserContextProvider } from "./context/User"
import { SearchContextProvider } from "./context/Search"
import PrivateRoute from "./auth/PrivateRoute"
import PublicRoute from "./auth/PublicRoute"
import { RouteType } from "./constants/routeTypes"
import Page404 from "./screens/Error/Page404"
import { MerchantContextProvider } from "./context/Merchant"
import AutoLogin from "./screens/AutoLogin"
import Navigation from "./components/navigation/Navigation"
import Header from "./components/header/Header"
import Dashboard from "./screens/dashboard/Dashboard"
import Settings from "./screens/Settings"
import Profile from "./screens/Profile"
import Topup from "./screens/topup/Topup"
import TransactionConfirmationScreen from "./screens/TransactionConfirmationScreen"
import ManageContacts from "./screens/manageUserContacts/ManageContacts"
import RefundPage from "./screens/refund/RefundPage"
import ScanQRCode from "./screens/scanQrCode/ScanQRCode"
import SendExternalComponent from "./screens/scanQrCode/SendExternalComponent"
import InternalTransfer from "./screens/InternalTransfer/InternalTransfer"
import PaymentSuccess from "./screens/InternalTransfer/PaymentSuccess"
import InternalTransferPaymentDetails from "./screens/transactionDetails/InternalTransferPaymentDetails"
import Transactions from "./screens/Transactions/Transactions"
import ShowMyQR from "./screens/ShowMyQR"
import VerifyPhoneOtp from "./screens/passcode/VerifyPhoneOtp"
import Passcode from "./screens/passcode/Passcode"
import AuthCheck from "./screens/AuthCheck"
import ErrorPage from "./screens/ErrorPage"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

function App(): JSX.Element {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Simulate a delay to show the loader for demonstration purposes
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 2000)

    return () => clearTimeout(timeout)
  }, [])

  if (window !== window.top) {
    return <div>This Page Refused To Connect.</div>
  }

  return (
    <Provider store={appStore}>
      <div className="App">
        <UserContextProvider>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <MerchantContextProvider>
                <SearchContextProvider>
                  <div className="container-fluid mb-4">
                    <div>
                      <div>
                        <Header />
                      </div>
                      <div id="main-container">
                        {loading ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100vh"
                            }}
                          >
                            <Spin
                              style={{ color: "#000", marginLeft: "5px" }}
                              indicator={antIcon}
                            />
                          </div>
                        ) : (
                          <OnboardingContextProvider>
                            <Routes>
                              {/* Public route */}
                              <Route element={<PublicRoute />}>
                                <Route
                                  path={RouteType.WADZPAY_AUTO_LOGIN}
                                  element={<AutoLogin />}
                                ></Route>
                                <Route
                                  path={RouteType.AUTH_CHECK}
                                  element={<AuthCheck />}
                                ></Route>
                                <Route
                                  path={RouteType.AUTH_ERROR}
                                  element={<ErrorPage />}
                                ></Route>
                              </Route>
                              {/* Private route */}

                              {/* Private Route or public route */}
                              {/* <Route element={<PrivateRoute />}> */}
                              {!RouteType.REFUND_FORM_TOKEN && (
                                <Route path="*" element={<Page404 />} />
                              )}
                              <Route
                                path={RouteType.DASHBOARD}
                                element={<Dashboard />}
                              ></Route>
                              <Route
                                path={RouteType.SETTINGS}
                                element={<Settings />}
                              ></Route>
                              <Route
                                path={RouteType.PROFILE}
                                element={<Profile />}
                              ></Route>
                              <Route
                                path={RouteType.TOPUP}
                                element={<Topup />}
                              ></Route>
                              <Route
                                path={RouteType.TRANSACTION_CONFIRMATION}
                                element={<TransactionConfirmationScreen />}
                              ></Route>
                              <Route
                                path={RouteType.VERIFY_PHONE_OTP}
                                element={<VerifyPhoneOtp />}
                              ></Route>
                              <Route
                                path={RouteType.PASSCODE}
                                element={<Passcode />}
                              ></Route>
                              <Route
                                path={RouteType.MANAGE_CONTACTS}
                                element={<ManageContacts />}
                              ></Route>
                              <Route
                                path={RouteType.REFUNDPAGE}
                                element={<RefundPage />}
                              ></Route>
                              <Route
                                path={RouteType.SCAN_QR_CODE}
                                element={<ScanQRCode />}
                              ></Route>
                              <Route
                                path={RouteType.SCANED_QR_CODE_PAYING}
                                element={<SendExternalComponent />}
                              ></Route>
                              <Route
                                path={RouteType.SHOW_MY_QR}
                                element={<ShowMyQR />}
                              ></Route>
                              <Route
                                path={RouteType.INTERNAL_TRANSFER}
                                element={<InternalTransfer />}
                              ></Route>
                              <Route
                                path={RouteType.PAYMENTSUCCESS}
                                element={<PaymentSuccess />}
                              ></Route>
                              <Route
                                path={RouteType.INTERNALTRANSPAYMENTDETAILS}
                                element={<InternalTransferPaymentDetails />}
                              ></Route>
                              <Route
                                path={RouteType.TRANSACTIONS}
                                element={<Transactions />}
                              ></Route>
                              {/* </Route> */}
                            </Routes>
                          </OnboardingContextProvider>
                        )}
                        <div>
                          <Navigation />
                        </div>
                      </div>
                    </div>
                  </div>
                </SearchContextProvider>
              </MerchantContextProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </UserContextProvider>
      </div>
    </Provider>
  )
}

export default App
