import React, { createRef, useEffect, useState, useContext } from "react"
import { useLocation } from "react-router-dom"
import moment from "moment"
import { CopyOutlined } from "@ant-design/icons"
import copy from "copy-to-clipboard"
import { notification } from "antd"
import { useTranslation } from "react-i18next"
import { createFileName, useScreenshot } from "use-react-screenshot"
import { UserDetailsContext } from "src/context/User"
import { Modal } from "react-bootstrap"

import {
  getAmountTwoDecimal,
  showFromFeildValue,
  showToFeildValue,
  shownames,
  sartTxt
} from "../../utils/Utils"

export default function PaymentSuccess() {
  const { t } = useTranslation()
  const location = useLocation()
  const ref = createRef()
  const { userDetails } = useContext(UserDetailsContext)

  localStorage.setItem("previusLocation", "Dashboard")

  const [copytext, setCopyText] = useState(false)
  const userTransactions = location?.state?.response
  let copymsgText = "Transaction Id copied."
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const [copyHash, setCopyHash] = useState(false)

  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  })

  const download = (image: any, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a")
    a.href = image
    a.download = createFileName(extension, name)
    a.click()
  }

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download)

  const copyToClipboard = () => {
    copy(userTransactions?.transactionId)
    setCopyText(true)
  }

  const copyHashToClipboard = () => {
    copy(userTransactions?.blockchainTxId)
    setCopyHash(true)
  }

  useEffect(() => {
    if (copytext || copyHash) {
      notification["success"]({
        message: "",
        description: copymsgText,
        placement: "bottom",
        closeIcon: false
      })

      setTimeout(() => {
        setCopyText(false)
        setCopyHash(false)
      }, 5000)
    }
  }, [copytext, copyHash])

  const getTransactionUrl = (assetName: string, blockchainTxId: string) => {
    switch (assetName) {
      case "BTC":
        return "www.btcscan.org/tx/" + blockchainTxId
      case "ETH" || "USDT":
        return "www.etherscan.io/txn/" + blockchainTxId
      case "WTK":
        return "assetName" + blockchainTxId
      case "THB":
        return "assetName" + blockchainTxId
      case "PHP":
        return "assetName" + blockchainTxId
      case "AED":
        return "assetName" + blockchainTxId
      case "SART":
        return "https://www.google.com/search?q=" + blockchainTxId
      default:
        return "www.google.com"
    }
  }

  let values = ""
  const getTransactionId = (transaction: any) => {
    values =
      transaction.transactionType === "REFUND"
        ? transaction?.refundTransactionId || transaction.uuid
        : transaction.uuid
    copymsgText = "Transaction Id copied."
    return values
  }

  return (
    <div className="geidea mb-50">
      <div className="row" ref={ref as React.RefObject<HTMLDivElement>}>
        <div className="asset-as-header">
          {userTransactions && userTransactions?.status === "SUCCESSFUL" ? (
            <>
              <img
                style={{ width: "70px", marginBottom: "10px" }}
                src="/images/Success.svg"
              ></img>

              <h3 className="status-text" style={{ color: "#27ae60" }}>
                {shownames(userTransactions) + " Successful"}
              </h3>
            </>
          ) : null}
          {userTransactions &&
          userTransactions?.transactionType === "SERVICE_FEE" ? (
            <>
              <img
                style={{ width: "70px", marginBottom: "10px" }}
                src="/images/ServiceFee.svg"
              ></img>

              <h3 className="status-text" style={{ color: "#000" }}>
                Service Fee
              </h3>
            </>
          ) : null}
        </div>

        {/* // TransactionId */}
        <div className="shadow payment-field-row">
          <div className="text-field-name">{t("transactionId")}</div>
          <div className="text-field-value">
            {/* {userTransactions?.transactionId.slice(0, 20)} */}
            {getTransactionId(userTransactions)?.slice(0, 20)}
            {"... "}
            <CopyOutlined onClick={copyToClipboard} />{" "}
          </div>
        </div>

        {/* // Transaction Type */}
        <div className="shadow payment-field-row">
          <div className="text-field-name">{t("transactionType")}</div>
          <div className="text-field-value">
            {userTransactions && shownames(userTransactions)}
          </div>
        </div>

        {/* // From  */}
        <div className="shadow payment-field-row">
          <div className="text-field-name">From</div>
          <div className="text-field-value">
            {userTransactions &&
              showFromFeildValue(userTransactions, userDetails?.email)}
          </div>
        </div>

        {/* // To  */}
        <div className="shadow payment-field-row">
          <div className="text-field-name">To</div>
          <div className="text-field-value">
            {userTransactions && showToFeildValue(userTransactions)}
          </div>
        </div>

        {/* // Amount  */}

        <div className="shadow payment-field-row">
          <div className="text-field-name">Amount</div>
          <div className="text-field-value">
            {/* <img
                  style={{ width: "22px" }}
                  src="/images/sartLogo.svg"
                ></img> */}
            {sartTxt}{" "}
            {userTransactions &&
              `${getAmountTwoDecimal(userTransactions?.amount)}`}
            <p style={{ marginBottom: 0 }}>
              {(userTransactions?.fiatAmount &&
                userTransactions?.asset != "SART") ||
              (userTransactions?.fiatAmount &&
                userTransactions?.asset == "SART" &&
                (userTransactions.transactionType == "DEPOSIT" ||
                  userTransactions.transactionType == "WITHDRAW"))
                ? `${userTransactions.fiatAsset} ${getAmountTwoDecimal(
                    userTransactions?.fiatAmount
                  )}`
                : ""}
            </p>
          </div>
        </div>

        {/* // Time  */}

        <div className="shadow payment-field-row">
          <div className="text-field-name">Time</div>
          <div className="text-field-value">
            {userTransactions &&
              moment(
                userTransactions?.paymentReceivedDate
                  ? userTransactions?.paymentReceivedDate
                  : userTransactions?.createdAt
              ).format("ddd MMM DD YYYY hh:mm a")}
          </div>
        </div>

        {/* // Status  */}
        <div className="shadow payment-field-row">
          <div className="text-field-name">Status</div>
          <div className="text-field-value status-text">
            {userTransactions && userTransactions?.status}
          </div>
        </div>
      </div>

      <button
        style={{
          width: "80%",
          marginLeft: "40px",
          background: "#FFA600",
          borderColor: "#FFA600",
          marginBottom: "100px"
        }}
        type="button"
        className="border-button-refund"
        onClick={() => {
          downloadScreenshot()
        }}
      >
        <h5 className="text-button-style">Download</h5>
      </button>

      {
        <Modal
          title=""
          open={modalOpen}
          onCancel={() => setModalOpen(false)}
          footer={null}
        ></Modal>
      }
    </div>
  )
}
