import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Radio } from "antd"
import { useTranslation } from "react-i18next"

type Props = {
  handleAccount: (value?: any) => void
  selectedAccount: number | string
}

const Accounts: React.FC<Props> = ({
  handleAccount,
  selectedAccount
}: Props) => {
  const { t } = useTranslation()
  // Inside your component
  const accounts = useSelector((state: any) => state?.accounts)

  useEffect(() => {
    // set selectedAccount
    if (accounts.length === 1) {
      handleAccount(accounts[0])
    }
  }, [accounts])

  function maskAccountNumber(
    accountNumber: number | string,
    visibleDigits = 4,
    maskCharacter = "x"
  ) {
    if (
      typeof accountNumber !== "string" ||
      typeof visibleDigits !== "number" ||
      typeof maskCharacter !== "string"
    ) {
      throw new Error("Invalid input types")
    }

    const visiblePart = accountNumber.slice(-visibleDigits)
    const maskedPart = maskCharacter.repeat(
      accountNumber.length - visibleDigits
    )

    return `${maskedPart} ${visiblePart}`
  }

  return (
    <div>
      {accounts.length > 0 ? (
        accounts.map((account: number | string, key: number) => {
          const isChecked =
            selectedAccount === account || accounts.length === 1 ? true : false
          return (
            <div className="col margin-block mt-3" key={account}>
              {key === 0 && (
                <span>{t("Bank Muamalat Indonesia accounts")}</span>
              )}
              <div className="shadow-box-2 shadow block-border">
                <div className="margin-block">
                  <Radio
                    checked={isChecked}
                    onChange={() => handleAccount(account)}
                    name="account"
                  >
                    {maskAccountNumber(account)}
                  </Radio>
                </div>
              </div>
            </div>
          )
        })
      ) : (
        <div className="col margin-block mt-3 text-danger">
          No accounts available
        </div>
      )}
    </div>
  )
}

export default Accounts
