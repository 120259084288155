import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Radio, Form, Input, notification, Spin, Button } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { RouteType } from "src/constants/routeTypes"
import { UserDetailsContext } from "src/context/User"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import {
  useGetFiatExchangeRate,
  useTransactionValidationConfig
} from "../../api/user"
import { TransactionTypeControl } from "../../api/constants"
import {
  ConTwoDecDigit,
  findPercentage,
  isTransactionAllowed,
  validateInitialLoading,
  getErrorMessage,
  getFiatCurrency,
  sartTxt
} from "../../utils/Utils"
import PrivacyPolicyAgreement from "../PrivacyPolicyAgreement"
import Accounts from "../Accounts"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const Topup = () => {
  const { t } = useTranslation()
  const navigation = useNavigate()
  // Inside your component
  const accounts = useSelector((state: any) => state?.accounts)

  // Get previous data
  let previousFormData = {
    amount: "",
    isChecked: false,
    selectedAccount: ""
  }

  const savedFormData = localStorage.getItem("formData")
  if (savedFormData) {
    previousFormData = JSON.parse(savedFormData)
  }

  const { userDetails } = useContext(UserDetailsContext)

  const [selectedAccount, setSelectedAccount] = useState<number | string>(
    previousFormData?.selectedAccount
  )
  const [amount, setAmount] = useState<any>(previousFormData?.amount)
  const [inputFieldError, setInputFieldError] = useState<boolean>(false)
  const [isValidationErrorTextInput, setIsValidationErrorTextInput] =
    useState(false)
  const [isChecked, setChecked] = useState(previousFormData?.isChecked)

  const [api, contextHolder] = notification.useNotification()
  const [loading, setLoading] = useState(false)
  const [isAmountError, setAmountError] = useState(false)
  const [hitAPI, sethitAPI] = useState(true)
  const [ttc, setTtc] = useState(TransactionTypeControl.SUBSEQUENT_LOADING)
  const [hasCalledApiToday, setHasCalledApiToday] = useState(false)

  // ************************ Creating Webservice API Interactions ************************
  // Fetch fiat exchange rates
  const { data: fiatExchangeRatesData, refetch: refetchExchangeRates } =
    useGetFiatExchangeRate(
      getFiatCurrency(userDetails) + "&transactionType=LOAD"
    )

  useEffect(() => {
    if (userDetails && !hasCalledApiToday) {
      refetchExchangeRates()
      setHasCalledApiToday(true)
    }
  }, [userDetails, hasCalledApiToday, refetchExchangeRates])

  const {
    data: transactionConfigData,
    refetch: refetchTransactionConfigData,
    isSuccess: isTransactionConfigSuccess,
    error: transactionConfigError
  } = useTransactionValidationConfig(ttc, hitAPI)

  // ************************ Handling Server Errors ************************
  useEffect(() => {
    refetchTransactionConfigData()
  }, [ttc, hitAPI])
  // ************************ Handling Server Responses ************************

  useEffect(() => {
    // check limit for transactions
    if (transactionConfigData?.initialLoading) {
      setTtc(
        transactionConfigData?.initialLoading
          ? TransactionTypeControl.INITIAL_LOADING
          : TransactionTypeControl.SUBSEQUENT_LOADING
      )
      sethitAPI(true)
    }
  }, [transactionConfigData, isTransactionConfigSuccess])

  useEffect(() => {
    // calculate all fee & charges
    const totalDeduction = amount.length > 0 ? calculateDeductions() : 0 // Calculate total fees Applied

    const totalAmount =
      (Number(amount) - Number(totalDeduction)) *
      Number(fiatExchangeRatesData?.SAR)
  }, [amount])

  const getAllFeeApplied = () => {
    if (transactionConfigData?.feeConfig) {
      const FeeApplied = transactionConfigData?.feeConfig.map((fee: any) => {
        if (fee.feeType == "Percentage") {
          const feeAmount = findPercentage(fee.feeAmount, Number(amount))

          if (
            (fee?.feeMinimumAmount && feeAmount < fee.feeMinimumAmount) ||
            feeAmount == fee.feeMinimumAmount
          ) {
            fee[fee.feeAmount] = fee.feeMinimumAmount
          } else if (
            (fee?.feeMaximumAmount && feeAmount > fee.feeMaximumAmount) ||
            feeAmount == fee.feeMaximumAmount
          ) {
            fee[fee.feeAmount] = fee.feeMaximumAmount
          } else {
            fee[fee.feeAmount] = feeAmount
          }
        } else {
          fee[fee.feeAmount] = fee.feeAmount
        }

        return {
          ["currencyType"]: fee.currencyType,
          ["feeId"]: fee.feeId,
          ["feeName"]: fee.feeName,
          ["feeCalculatedAmount"]: fee[fee.feeAmount].toFixed(2),
          ["feeType"]: fee.feeType,
          ["enteredAmount"]: amount,
          ["feeAmount"]: fee.feeAmount
        }
      })
      return FeeApplied
    }
  }

  const calculateDeductions = () => {
    if (transactionConfigData?.feeConfig) {
      const totalFee = transactionConfigData?.feeConfig.reduce(
        (total: number, curr: any) => {
          if (curr.feeType == "Percentage") {
            const feeAmount = findPercentage(curr.feeAmount, Number(amount))

            if (
              (curr.feeMinimumAmount && feeAmount < curr.feeMinimumAmount) ||
              feeAmount == curr.feeMinimumAmount
            ) {
              total = total + curr.feeMinimumAmount
            } else if (
              (curr.feeMaximumAmount && feeAmount > curr.feeMaximumAmount) ||
              feeAmount == curr.feeMaximumAmount
            ) {
              total = total + curr.feeMaximumAmount
            } else {
              total = total + feeAmount
            }
          } else {
            total = total + curr.feeAmount
          }
          return total
        },
        0
      )

      return totalFee
    }
    return 0
  }

  const onConfirmClick = () => {
    let isTransactionPermitted
    // Check transaction limit and control for load Token
    if (ttc == TransactionTypeControl.SUBSEQUENT_LOADING) {
      isTransactionPermitted = isTransactionAllowed(
        transactionConfigData,
        Number(amount),
        t
      ) // transaction control and loading
    } else {
      // initail loading
      isTransactionPermitted = validateInitialLoading(
        transactionConfigData,
        Number(amount),
        t
      )
    }

    // if (userDetails && !userDetails?.bankAccountNumber) {
    //   notification["error"]({
    //     message: t("anErrorOccurred"),
    //     description: t("pleaseAddBankAccount"),
    //     placement: "bottom"
    //   })
    //   return false
    // } else
    if (isTransactionPermitted !== "allowed") {
      notification["error"]({
        message: t("anErrorOccurred"),
        description: isTransactionPermitted,
        placement: "bottom"
      })
    } else {
      let totalFee
      const amountInSar =
        fiatExchangeRatesData &&
        (parseFloat(amount) * fiatExchangeRatesData?.SAR)?.toFixed(2)
      if (!isValidationErrorTextInput) {
        if (transactionConfigData?.feeConfig) {
          totalFee = amount.length > 0 ? calculateDeductions() : 0.0 // Calculate total fees Applied
        }

        const amountToDebit = totalFee
          ? (Number(amount) + Number(totalFee)).toFixed(2)
          : amount

        const transactionConfirmationDataParam = {
          requestPayload: {
            tokenAsset: "SART",
            fiatAsset: getFiatCurrency(userDetails),
            amount: amountInSar,
            bankAccountNumber: selectedAccount,
            fiatAmount: Number(amount)?.toFixed(2),
            feeConfigData: getAllFeeApplied() ? getAllFeeApplied() : null,
            totalFeeApplied:
              amount.length > 0
                ? totalFee
                  ? Number(totalFee?.toFixed(2))
                  : 0
                : 0,
            totalRequestedAmount: amountToDebit
          },
          transactionType: "Topup",
          amountRequested: `${getFiatCurrency(userDetails)} ${Number(
            amount
          ).toFixed(2)}`,
          screenType: "loadToken",
          uniqueList: getUniqueFeeList(transactionConfigData?.feeConfig) || {},
          feeTitle: t("amountToBeDebited"),
          totalAmount: `${sartTxt} ${amountInSar}`,
          totalAmountText: t("amountToBeReceived"),
          netAmountCalulated: `${getFiatCurrency(userDetails)} ${(
            Number(amount) + Number(totalFee)
          )?.toFixed(2)}`
        }

        // Set formData
        localStorage.setItem(
          "formData",
          JSON.stringify({
            amount,
            isChecked,
            selectedAccount
          })
        )

        navigation(RouteType.TRANSACTION_CONFIRMATION, {
          state: transactionConfirmationDataParam
        })
      }
    }
  }

  const getUniqueFeeList = (feeConFigList: any) => {
    if (feeConFigList) {
      let totaloadingfee = 0
      let otherFee = 0
      let LoadingFeeObj = {}
      let otherFeeObj = {}

      feeConFigList.map((fee: any) => {
        if (fee.feeNameId == "WF_006") {
          if (fee.feeType == "Percentage") {
            const feeAmount = findPercentage(fee.feeAmount, Number(amount))

            if (
              feeAmount < fee.feeMinimumAmount ||
              feeAmount == fee.feeMinimumAmount
            ) {
              fee[fee.feeAmount] = fee.feeMinimumAmount
            } else if (
              feeAmount > fee.feeMaximumAmount ||
              feeAmount == fee.feeMaximumAmount
            ) {
              fee[fee.feeAmount] = fee.feeMaximumAmount
            } else {
              fee[fee.feeAmount] = feeAmount
            }
          } else {
            fee[fee.feeAmount] = fee.feeAmount
          }

          totaloadingfee = totaloadingfee + fee[fee.feeAmount]
          LoadingFeeObj = {
            ["feeName"]: fee.feeName,
            ["feeCalculatedAmount"]: totaloadingfee.toFixed(2),
            ["currencyType"]: fee.currencyType
          }
        } else {
          if (fee.feeType == "Percentage") {
            const feeAmount = findPercentage(fee.feeAmount, Number(amount))

            if (
              feeAmount < fee.feeMinimumAmount ||
              feeAmount == fee.feeMinimumAmount
            ) {
              fee[fee.feeAmount] = fee.feeMinimumAmount
            } else if (
              feeAmount > fee.feeMaximumAmount ||
              feeAmount == fee.feeMaximumAmount
            ) {
              fee[fee.feeAmount] = fee.feeMaximumAmount
            } else {
              fee[fee.feeAmount] = feeAmount
            }
          } else {
            fee[fee.feeAmount] = fee.feeAmount
          }

          otherFee = otherFee + fee[fee.feeAmount]

          otherFeeObj = {
            ["feeName"]: fee.feeName,
            ["feeCalculatedAmount"]: otherFee.toFixed(2),
            ["currencyType"]: fee.currencyType
          }
        }
      })

      if (LoadingFeeObj && otherFee > 0) {
        return [otherFeeObj, LoadingFeeObj]
      } else if (otherFee > 0) {
        return [otherFeeObj]
      } else {
        return [LoadingFeeObj]
      }
    }
  }

  const handleChange = () => {
    setChecked(!isChecked)
    setInputFieldError(false)
  }

  const handleInputChange = (value?: any) => {
    // Reset error states
    setAmountError(false)

    // Validate input using regex for numbers with up to 2 decimal places
    const reg = /^\d*(\.\d{0,2})?$/

    // Handle amount limit based on presence of decimal point
    const amountLimit = value.includes(".") ? 18 : 15

    // Handle various cases based on input value
    if (
      value.length > amountLimit ||
      value <= 0 ||
      value === "-" ||
      value === "--"
    ) {
      // Handle cases where input exceeds length limit, is zero or negative, or contains invalid characters
      setAmountError(true)
      setAmount(
        value === "-" || value === "--" ? "" : value === "." ? "0." : value
      )
    } else if (reg.test(value) || value === "") {
      // Valid input: update amount state and perform transaction limit validation
      setAmount(value)

      const transactionType =
        ttc === TransactionTypeControl.SUBSEQUENT_LOADING
          ? "per_TRANSACTION"
          : "one_TIME"
      const maxBalance =
        transactionConfigData?.[transactionType]?.maximumBalance
      const minBalance =
        transactionConfigData?.[transactionType]?.minimumBalance

      if (
        (maxBalance && maxBalance !== null && Number(value) > maxBalance) ||
        (minBalance && minBalance !== null && Number(value) < minBalance)
      ) {
        setAmountError(true)
      } else {
        setAmountError(false)
      }
    } else {
      // Default case: handle any unexpected scenarios
      setAmountError(true)
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target }: any = event

    // Check if the key is a digit or a decimal point
    if (!/[0-9.]/.test(key)) {
      event.preventDefault() // Prevent input if not a digit or decimal point
    }

    // Ensure only one decimal point is allowed
    if (key === "." && target.value.includes(".")) {
      event.preventDefault() // Prevent adding more than one decimal point
    }

    // Split the input into the whole and decimal parts
    const [wholePart, decimalPart] = target.value.split(".")

    // Limit to 15 digits before the decimal point
    if (!target.value.includes(".") && wholePart.length >= 15 && key !== ".") {
      event.preventDefault() // Prevent input if more than 15 digits before the decimal
    }

    // Limit to two decimal places
    if (target.value.includes(".")) {
      const decimalPlaces = decimalPart?.length || 0
      if (decimalPlaces >= 2) {
        event.preventDefault() // Prevent input if more than two decimal places
      }
    }
  }

  // callback function for account number change
  const handleAccount = (selectedAccount: number | string) => {
    setSelectedAccount(selectedAccount)
  }

  return (
    <>
      <div className="whiteFullWidth"></div>
      {contextHolder}
      <div className="row">
        <div className="col-lg-12" style={{ zIndex: 1 }}>
          <div
            style={{ marginTop: "50px" }}
            className="header-available-balance"
          >
            <p className="main-font-color" style={{ fontSize: "17px" }}>
              {t("enterAmountToAdd")}
            </p>
          </div>

          <Form.Item noStyle rules={[{ required: true }]}>
            <Input
              onChange={(x) => handleInputChange(x.target.value)}
              style={{
                right: "45px",
                left: "25px",
                paddingTop: "8px"
              }}
              placeholder="0.00"
              defaultValue={amount}
              value={amount}
              size="large"
              addonBefore={getFiatCurrency(userDetails)}
              status={inputFieldError ? "error" : undefined}
              className="topup-input"
              onKeyPress={handleKeyPress}
            />
          </Form.Item>
          {transactionConfigData?.per_TRANSACTION == null &&
          amount !== "" &&
          Number(amount) === 0 ? (
            <div
              style={{
                textAlign: "center",
                color: "#f00",
                fontSize: "13px",
                marginTop: "10px"
              }}
            >
              {t("validAmountError")}{" "}
            </div>
          ) : amount &&
            Number(amount) > 0.01 &&
            !isAmountError &&
            fiatExchangeRatesData ? (
            <div
              style={{
                textAlign: "center",
                fontSize: "13px",
                marginTop: "10px"
              }}
              className="main-font-color"
            >
              {t("youGet")} {sartTxt}{" "}
              {fiatExchangeRatesData &&
                (parseFloat(amount) * fiatExchangeRatesData?.SAR)?.toFixed(2)}
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
                color: "#f00",
                fontSize: "13px",
                marginTop: "10px"
              }}
            >
              {isAmountError && amount.length != 0
                ? getErrorMessage(
                    ttc == TransactionTypeControl.SUBSEQUENT_LOADING
                      ? transactionConfigData?.per_TRANSACTION
                      : transactionConfigData?.one_TIME,
                    Number(amount),
                    t
                  )
                : ``}
            </div>
          )}

          <div
            className={
              amount && Number(amount) >= 0.01
                ? `margin-block mt-3`
                : `margin-block mt-5`
            }
          >
            <hr
              style={{
                backgroundColor: "rgb(169 164 164)",
                width: "110%",
                marginLeft: "-5%",
                marginTop: "-5px"
              }}
            />
            <h5 className="mt-3"> {t("selectTopupFrom")}</h5>
          </div>
          {/* Invoke accounts components */}
          <Accounts
            handleAccount={handleAccount}
            selectedAccount={selectedAccount}
          />
          {/* PrivacyPolicyAgreement */}
          <PrivacyPolicyAgreement
            isChecked={isChecked}
            isValidationErrorTextInput={isValidationErrorTextInput}
            handleChange={handleChange}
          />
          <Button
            size={"large"}
            onClick={onConfirmClick}
            disabled={
              !loading &&
              amount &&
              Number(amount) >= 0.01 &&
              isChecked &&
              !isAmountError &&
              selectedAccount !== ""
                ? false
                : true
            }
            className={
              amount &&
              Number(amount) >= 0.01 &&
              isChecked &&
              !isAmountError &&
              selectedAccount !== ""
                ? "active-btn-style"
                : "default-btn-style"
            }
            style={{
              width: "100%",
              height: "50px",
              marginTop: "20px"
            }}
          >
            {t("continue")}&nbsp;&nbsp;
            {loading ? (
              <Spin
                style={{ color: "#000", marginLeft: "5px" }}
                indicator={antIcon}
              />
            ) : null}
          </Button>
        </div>
      </div>
    </>
  )
}

export default Topup
