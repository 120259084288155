import React, { useState, useEffect, useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Carousel, Skeleton, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import { RouteType } from "src/constants/routeTypes"
import { UserBalanceContext, UserDetailsContext } from "src/context/User"
import useScrollToRefresh from "src/utils/useScrollToRefresh"

import {
  useUserBalancesInfo,
  useUserTransactionsDetails,
  useGetUserProfileDetails
} from "../../api/user"
import { UserTransactionsDetails } from "../../api/models"
import {
  getDirection,
  getAmountTwoDecimal,
  getTransactionType,
  getOtherPartyName,
  sartTxt
} from "../../utils/Utils"
import SaveUserStaticQrModal from "../SaveUserStaticQrModal"

const contentStyle: React.CSSProperties = {
  padding: "0 0 0 0",
  height: "120px",
  color: "#000",
  lineHeight: "20px",
  textAlign: "center",
  borderRadius: "20px",
  fontWeight: 0,
  marginTop: "10px"
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const Dashboard: React.FC = () => {
  useScrollToRefresh()
  const { t } = useTranslation()
  const navigate = useNavigate()
  // Remore formdata
  localStorage.removeItem("formData")

  const { userDetails, setUserDetails } = useContext(UserDetailsContext)
  const { setUserBalances } = useContext(UserBalanceContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [userTransactions, setUserTransactions] =
    useState<UserTransactionsDetails>()

  // Get user balances API
  const {
    data: userBalancesData,
    isFetching: isFetchingUserBalance,
    error: errorUserBalance
  } = useUserBalancesInfo()

  const {
    data: profileData,
    isFetching: isFetchingProfileDetails,
    refetch: refetchGetUserProfileDetails,
    error: errorProfileDetails
  } = useGetUserProfileDetails()

  // Get user transactions API
  const {
    data: userTransactionsData,
    isFetching: isFetchingUserTransactions,
    error: errorUserTransactions
  } = useUserTransactionsDetails("page=0&asset=SART")

  useEffect(() => {
    if (profileData) {
      const updatedObj: any = {
        fiatCurrency: profileData?.fiatCurrency,
        ...userDetails
      }
      setUserDetails(updatedObj)
    }
  }, [profileData])

  useEffect(() => {
    if (userBalancesData) {
      setUserBalances(userBalancesData?.SART)
    }
  }, [isFetchingUserBalance])

  useEffect(() => {
    if (userTransactionsData) {
      setUserTransactions(userTransactionsData)
      setLoading(false)
    }
  }, [isFetchingUserTransactions, errorUserBalance])

  // Function to compare two datetime strings
  const compareDatetime = (a: any, b: any) => {
    const dateA = new Date(a)
    const dateB = new Date(b)

    // Compare the dates using getTime()
    return dateB.getTime() - dateA.getTime()
  }

  useEffect(() => {
    // get token from local storage
    const token = localStorage.getItem("token")
    if (token === undefined || token == null || token === "") {
      navigate(RouteType.AUTH_ERROR)
      window.location.reload()
    }
  }, [])

  return (
    <>
      {localStorage.getItem("userStaticQr") !== "hide" && (
        <SaveUserStaticQrModal />
      )}
      <div className="geidea mb-45" style={{ background: "#f6f6f6" }}>
        <div className="row" style={{ background: "#f6f6f6" }}>
          <div
            className="col-lg-12 mb-3 shadow"
            style={{
              background: "#fff",
              padding: "20px",
              marginTop: "30px"
            }}
          >
            <h5
              style={{
                textAlign: "center",
                fontFamily: "Rubik-Medium",
                fontWeight: "bold",
                color: "darkBlackBold"
              }}
              className="welcomeHeading"
            >
              {" "}
              {t("welcome")}
            </h5>
            <Carousel
              style={{ background: "#000", marginBottom: 20 }}
              className="custom-carousel"
              effect={"scrollx"}
              easing={"linear"}
            >
              <div className="CarouselBox">
                <div className="CarouselBox-inner" style={contentStyle}>
                  {userBalancesData ? (
                    <>
                      {profileData &&
                      profileData?.isBalanceRunningLow ? null : (
                        <br />
                      )}
                      <p className="carouselItem">{t("availableBalance")}</p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <h2
                          style={{
                            marginLeft: "6px",
                            marginTop: "-10px",
                            // letterSpacing: "0.2px",
                            fontSize: "25px"
                          }}
                        >
                          <b>
                            <span>{sartTxt}</span>{" "}
                            {userBalancesData &&
                              getAmountTwoDecimal(userBalancesData?.SART)}
                          </b>
                        </h2>
                      </div>
                      {profileData && profileData?.isBalanceRunningLow ? (
                        <div className="lowBalance">
                          {/* <WarningOutlined
                            className="warningIcon"
                            style={{ color: "#fff" }}
                          /> */}

                          <span
                            className="low"
                            style={{
                              color: "#fff",
                              fontFamily: "Rubik-Regular"
                            }}
                          >
                            <img
                              style={{
                                position: "absolute",
                                left: "-15px",
                                top: "1px"
                              }}
                              src="../images/ic_alert.svg"
                            />{" "}
                            &nbsp;&nbsp; {t("lowBalanceMinimum")} {sartTxt}{" "}
                            {profileData?.lowMaintainWalletBalance}
                          </span>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <Spin
                      style={{
                        marginLeft: "5px",
                        marginTop: "40px"
                      }}
                      className="spin-style"
                      indicator={antIcon}
                    />
                  )}
                </div>
              </div>
            </Carousel>
            <h5
              className="welcomeHeading"
              style={{ marginTop: 20, fontSize: "12px" }}
            >
              * {t("representsWalletValue")}
            </h5>
          </div>
        </div>
        <div className="row" style={{ background: "#f6f6f6" }}>
          <div className="col">
            <Link
              to={RouteType.TOPUP}
              state={{ from: RouteType.DASHBOARD }}
              style={{ textDecoration: "none" }}
            >
              <div className="shadow-box-1 shadow">
                <div className="shadow-1">
                  {" "}
                  <img
                    style={{ width: "25px", marginTop: "10px" }}
                    src="/images/navigation/topup.svg"
                  ></img>
                  <p style={{ fontSize: "14px", color: "black" }}>
                    {t("topupWallet")}
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link
              to={RouteType.REFUNDPAGE}
              state={{ from: RouteType.DASHBOARD }}
              style={{ textDecoration: "none" }}
            >
              <div className="shadow-box-2 shadow">
                <div className="shadow-1">
                  {" "}
                  <img
                    style={{ width: "25px", marginTop: "10px" }}
                    src="/images/navigation/refund.svg"
                  ></img>
                  <p
                    style={{ fontWeight: 0, fontSize: "14px", color: "black" }}
                  >
                    {t("redeemUnspent")}
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row mt-3" style={{ background: "#f6f6f6" }}>
          <div>
            <p
              className="font-weight-bold float-start"
              style={{
                marginLeft: "-5px",
                color: "rgb(83 83 83)",
                letterSpacing: "0.2px"
              }}
            >
              {" "}
              <b>{t("recentTransactions")}</b>
            </p>
            <Link to={RouteType.TRANSACTIONS}>
              <h6 className="float-end main-font-color">{t("viewAll")}</h6>
            </Link>
          </div>
          <div
            className="table-responsive shadow "
            style={{ background: "#ffffff", marginBottom: "61px" }}
          >
            <table className="customTable table">
              <tbody>
                {loading ? (
                  <>
                    <Skeleton
                      style={{ marginTop: "10px" }}
                      loading={loading}
                      active
                      avatar
                    ></Skeleton>
                    <Skeleton loading={loading} active avatar></Skeleton>
                  </>
                ) : userTransactions && userTransactions?.length > 0 ? (
                  userTransactions
                    ?.slice(0, 4)
                    ?.sort((a: any, b: any) =>
                      compareDatetime(
                        a?.paymentReceivedDate
                          ? a?.paymentReceivedDate
                          : a?.createdAt,
                        b?.paymentReceivedDate
                          ? b?.paymentReceivedDate
                          : b?.createdAt
                      )
                    )
                    ?.map((element, key) => {
                      const directionPath =
                        element.status === "FAILED"
                          ? "/images/transaction_failed.png"
                          : getDirection(element, "name") === "DebitArrow"
                          ? "/images/debit_arrow.svg"
                          : "/images/credit_arrow.svg"

                      const transactionsTypes = t(
                        `transactionsTypes.${getTransactionType(
                          element,
                          element.transactionType
                        )}`
                      )

                      return (
                        <tr
                          className="tItemAlign"
                          key={key}
                          style={{
                            height: "70px"
                          }}
                        >
                          <Link
                            key={key}
                            to={RouteType.INTERNALTRANSPAYMENTDETAILS}
                            state={{
                              from: "Transactions",
                              transactionId: element.transactionId
                            }}
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <td key={key}>
                              <span className="tItem1">
                                {/* {CapitalizeFirstLetter(
                                shownames(element)[0]
                              )} */}
                                <img
                                  style={{
                                    width:
                                      element.status === "FAILED"
                                        ? "45px"
                                        : "30px",
                                    height:
                                      element.status === "FAILED" ? "" : "30px",
                                    marginLeft:
                                      element.status === "FAILED" ? "-6px" : ""
                                  }}
                                  src={directionPath}
                                ></img>{" "}
                              </span>
                              <span className="tItem2">
                                {transactionsTypes}
                              </span>
                              <span className="tItem3">
                                {getOtherPartyName(
                                  element,
                                  localStorage.getItem("email") || ""
                                )}
                              </span>
                              <span className="tItem5">
                                {dayjs(
                                  element?.paymentReceivedDate
                                    ? element?.paymentReceivedDate
                                    : element?.createdAt
                                ).format("D MMM YYYY, hh:mm a")}
                              </span>
                              <span className="tItem4">
                                {sartTxt} {getAmountTwoDecimal(element.amount)}
                              </span>
                              <span className="tItem6"></span>
                            </td>
                          </Link>
                        </tr>
                      )
                    })
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      height: "195px"
                    }}
                  >
                    {t("noTransactionsAvailable")}
                  </p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
