/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
import { useState } from "react"
import env from "src/env"
import { AutoLogin } from "src/api/models"
import { EXCHANGE_RATES_REFRESH_TIME } from "src/constants"

import {
  signInAsync,
  requestResetPasswordCodeAsync,
  submitResetPasswordCodeAsync,
  getIdTokenAsync
} from "../auth/AuthManager"
import {
  Transaction,
  TransactionsData,
  RefundTransactionsData,
  BalancesData,
  MerchantData,
  MerchantDetailsData,
  UserDetailsData,
  VerifyEmailOTPData,
  P2pTransaction,
  AcceptRejectRefundableTransaction,
  InitiateWebLinkRefund,
  SubmitRefundFormData,
  GetRefundFormToken,
  SubmitRefundWithAuth,
  RecentTransaction,
  WalletWithdraw,
  UserData,
  RefundFormFields,
  UserBalancesGeidea,
  UserTransactionsDetails,
  SendDigitalCurrencyToExternalWallet,
  fiatExchangeDataSart,
  TopupOrRefundSart,
  UserTransactionGeidea,
  UserContactGeidea,
  AddTransactionData,
  UserByEmailOrMobileData,
  addressesData,
  SaveUserContactData,
  DeleteContactOfUser,
  GetDecryptedData,
  GetEncryptedData,
  transactionValidtionConfigData,
  userProfileDetail,
  savePasscodeHashData,
  getMappedLanguagesType
} from "./models"
import { EndpointKey, useApi } from "./constants"

import { useGet, useSet } from "./index"

const useApiCallStates = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const reset = () => {
    setIsSuccess(false)
    setIsLoading(false)
    setError(null)
  }
  return {
    isLoading,
    setIsLoading,
    isSuccess,
    setIsSuccess,
    error,
    setError,
    reset
  }
}

export const useGenericApiCall = (
  apiToCall: (...params: any) => Promise<any>
) => {
  const {
    isLoading,
    setIsLoading,
    isSuccess,
    setIsSuccess,
    error,
    setError,
    reset
  } = useApiCallStates()

  return {
    mutate: async (...params: any) => {
      try {
        setIsLoading(true)
        const response = await apiToCall(...params)
        setIsSuccess(true)
        return response
      } catch (e: any | null) {
        setError(e)
      } finally {
        setIsLoading(false)
      }
    },
    isLoading,
    isSuccess,
    error,
    reset
  }
}

export const useSignIn = () => {
  return useGenericApiCall(signInAsync)
}

export const useUserDetailsAndEmailOTP = () =>
  useSet<UserDetailsData>(
    EndpointKey.USER_DETAILS_SEND_EMAIL_OTP,
    useApi().userDetailsAndEmailOTP(),
    "POST",
    {},
    EndpointKey.USER_DETAILS_SEND_EMAIL_OTP
  )

export const useMerchant = () =>
  useSet<MerchantData>(
    EndpointKey.MERCHANT,
    useApi().merchant(),
    "POST",
    {},
    EndpointKey.USER_DETAILS_SEND_EMAIL_OTP
  )

export const useVerifyEmailOTPAndCreateUser = () =>
  useSet<VerifyEmailOTPData>(
    EndpointKey.VERIFY_EMAIL_OTP_CREATE_USER,
    useApi().verifyEmailOTPAndCreateUser(),
    "POST",
    {},
    EndpointKey.VERIFY_EMAIL_OTP_CREATE_USER
  )

export const useRequestResetPasswordCode = () => {
  return useGenericApiCall(requestResetPasswordCodeAsync)
}

export const useSubmitResetPasswordCode = () => {
  return useGenericApiCall(submitResetPasswordCodeAsync)
}

export const useMerchantTransaction = (id: string) =>
  useGet<Transaction>(
    [EndpointKey.MERCHANT_TRANSACTION, id],
    useApi().merchantTransaction(id),
    { keepPreviousData: true }
  )

export const useMerchantTransactions = (query?: string) =>
  useGet<TransactionsData>(
    [EndpointKey.MERCHANT_TRANSACTIONS, query],
    useApi().merchantTransactions(query || ""),
    { keepPreviousData: true },
    "uuid"
  )
export const useMerchantTransactionsNoPage = (query?: string) =>
  useGet<TransactionsData>(
    [EndpointKey.MERCHANT_TRANSACTIONS, query],
    useApi().merchantTransactions(query || ""),
    { keepPreviousData: true },
    "uuid"
  )

export const useRefundTransactions = (query?: string) =>
  useGet<RefundTransactionsData>(
    [EndpointKey.REFUND_TRANSACTIONS, query],
    useApi().refundTransactions(query || ""),
    { keepPreviousData: true },
    "uuid"
  )
export const useRefundTransactionsNoPage = (query?: string) =>
  useGet<RefundTransactionsData>(
    [EndpointKey.REFUND_TRANSACTIONS, query],
    useApi().refundTransactions(query || ""),
    { keepPreviousData: true },
    "uuid"
  )

export const useRefundAcceptRefundTrx = () =>
  useSet<AcceptRejectRefundableTransaction>(
    EndpointKey.REFUND_ACCEPT_REJECT_TRANSACTIONS,
    useApi().refundAcceptRejectTransactions(),
    "POST",
    {},
    EndpointKey.REFUND_ACCEPT_REJECT_TRANSACTIONS
  )

export const useInitiateWebLinkRefund = () =>
  useSet<InitiateWebLinkRefund>(
    EndpointKey.REFUND_INITIATE_WEBLINK,
    useApi().refundInitiateWebLink(),
    "POST",
    {},
    EndpointKey.REFUND_INITIATE_WEBLINK
  )

export const useSubmitRefundformWithAuth = () =>
  useSet<SubmitRefundWithAuth>(
    EndpointKey.REFUND_SUBMIT_WITHAUTH,
    useApi().refundSubmitFormWithAuth(),
    "POST",
    {},
    EndpointKey.REFUND_SUBMIT_WITHAUTH
  )

export const useUserBalances = () =>
  useGet<BalancesData>(EndpointKey.USER_BALANCES, useApi().userBalances(), {})

export const useP2pTransaction = () =>
  useSet<P2pTransaction>(
    EndpointKey.P2P_TRANSACTIONS,
    useApi().p2pTransaction(),
    "POST",
    {},
    EndpointKey.P2P_TRANSACTIONS
  )

export const useMerchantDetails = () =>
  useGet<MerchantDetailsData>(
    EndpointKey.MERCHANT_DETAILS,
    useApi().merchantDetails(),
    {}
  )

export const useSubmitRefundFormData = () =>
  useSet<SubmitRefundFormData>(
    EndpointKey.SUBMIT_REFUND_FORM_DATA,
    useApi().submitRefundForm(),
    "POST",
    {},
    EndpointKey.SUBMIT_REFUND_FORM_DATA
  )

export const useRefundFormTokenDetails = () =>
  useSet<GetRefundFormToken>(
    EndpointKey.GET_REFUND_FORM,
    useApi().getRefundForm(),
    "POST",
    {},
    EndpointKey.GET_REFUND_FORM
  )

export const useRecentPayment = () =>
  useSet<RecentTransaction>(
    EndpointKey.RECENT_PAYMENT,
    useApi().recentPayment(),
    "POST",
    {},
    EndpointKey.RECENT_PAYMENT
  )

export const useWalletWithdraw = () =>
  useSet<WalletWithdraw>(
    EndpointKey.ATM_WITHDRAW,
    useApi().atmWithDraw(),
    "POST",
    {},
    EndpointKey.ATM_WITHDRAW
  )

export const useUserVerify = (query: string) =>
  useGet<UserData>(
    [EndpointKey.USER_VERIFY, query],
    useApi().userVerify(query),
    { enabled: query != "" }
  )

export const useUserVerifyReset = (query: string) =>
  useGet<UserData>(
    [EndpointKey.USER_VERIFY_RESET, query],
    useApi().userVerifyReset(query),
    { enabled: query != "" }
  )

export const useRefundFormFields = (query: string) =>
  useGet<RefundFormFields>(
    [EndpointKey.REFUND_FORM_FIELDS, query],
    useApi().refundFormFields(query),
    { enabled: query != "" }
  )

export const useUserBalancesInfo = () =>
  useGet<UserBalancesGeidea>(
    EndpointKey.USER_BALANCES_GEIDEA,
    useApi().userBalancesGeidea()
  )

export const useUserTransactionsDetails = (query: string) =>
  useGet<UserTransactionsDetails>(
    [EndpointKey.USER_TRANSACTION_GEIDEA, query],
    useApi().UserTransactionsDetails(query),
    { enabled: query != "" }
  )

export const useSendDigitalCurrencyToExternalWallet = () =>
  useSet<SendDigitalCurrencyToExternalWallet>(
    EndpointKey.SEND_DIGITAL_CRTPTO_TO_EXTERNAL_DATA,
    useApi().sendDigitalCurrencyToExternalWallet(),
    "POST",
    {},
    [
      EndpointKey.SEND_DIGITAL_CRTPTO_TO_EXTERNAL_DATA,
      EndpointKey.USER_BALANCES,
      EndpointKey.USER_TRANSACTIONS
    ]
  )

export const useGetFiatExchangeRate = (fiat: string) =>
  useGet<fiatExchangeDataSart>(
    [EndpointKey.FIAT_EXCHANGE_RATE, fiat],
    useApi().fiatExchangeRates(fiat),
    {
      staleTime: EXCHANGE_RATES_REFRESH_TIME
    }
  )

export const useUserTransactionGeidea = (transactionId: string) =>
  useGet<UserTransactionGeidea>(
    [EndpointKey.USER_TRANSACTION_GEIDEA, transactionId],
    useApi().userTransactionGeidea(transactionId),
    { keepPreviousData: true }
    //useApi().exchangeRates(transactionId)
  )

export const useAddTransaction = () =>
  useSet<AddTransactionData>(
    EndpointKey.ADD_TRANSACTION,
    useApi().addUserTransaction(),
    "POST",
    {},
    [
      EndpointKey.ADD_TRANSACTION,
      EndpointKey.USER_BALANCES_GEIDEA,
      EndpointKey.USER_TRANSACTIONS
    ]
  )

export const useUserContactsGeidea = (query: string) =>
  useGet<UserContactGeidea>(
    [EndpointKey.USER_CONTACTS_GEIDEA, query],
    useApi().userContactsGeidea(query),
    { enabled: query != "" }
  )

export const useTopupSart = () =>
  useSet<TopupOrRefundSart>(
    EndpointKey.TOPUP,
    useApi().loadToken(),
    "POST",
    {},
    [
      EndpointKey.TOPUP,
      EndpointKey.USER_BALANCES,
      EndpointKey.USER_TRANSACTIONS
    ]
  )

export const useGetDecryptedEmail = (query: string, flag: boolean) =>
  useGet<{ email: string }>(
    [EndpointKey.GET_DECRYPTED_EMAIL, query],
    useApi().getDecryptedEmail(query),
    {
      enabled: flag
    }
  )

export const useGetJwtToken = (query: string) =>
  useSet<AutoLogin>(
    [EndpointKey.GET_JWT_TOKEN, query],
    useApi().getJwtToken(query),
    "POST",
    {},
    [EndpointKey.GET_JWT_TOKEN]
  )
export const useRefundSart = () =>
  useSet<TopupOrRefundSart>(
    EndpointKey.REFUND,
    useApi().refundToken(),
    "POST",
    {},
    [
      EndpointKey.REFUND,
      EndpointKey.USER_BALANCES,
      EndpointKey.USER_TRANSACTIONS
    ]
  )
export const useUser = (query: string, hitAPI?: boolean) => {
  return useGet<UserData>(
    [EndpointKey.GET_USER, query],
    useApi().getUser(query || ""),
    { enabled: hitAPI }
  )
}

export const useUserByEmailOrMobile = (query: string) =>
  useGet<UserByEmailOrMobileData>(
    [EndpointKey.GET_USER_BY_EMAIL_OR_MOBILE, query],
    useApi().getUserByEmailOrMobile(query || ""),
    {},
    EndpointKey.GET_USER_BY_EMAIL_OR_MOBILE
  )

export const useAddresses = () =>
  useGet<addressesData>(EndpointKey.ADDRESSES, useApi().addresses(), {})

export const useAddUserContact = () =>
  useSet<SaveUserContactData>(
    EndpointKey.ADD_USER_CONTACT,
    useApi().addUserContact(),
    "POST",
    {},
    [EndpointKey.ADD_USER_CONTACT, EndpointKey.USER_CONTACTS_GEIDEA]
  )

export const useUpdateUserContact = () =>
  useSet<SaveUserContactData>(
    EndpointKey.UPDATE_USER_CONTACT,
    useApi().updateUserContact(),
    "PATCH",
    {},
    [EndpointKey.UPDATE_USER_CONTACT, EndpointKey.USER_CONTACTS_GEIDEA]
  )

export const useDeleteContactOfUser = () =>
  useSet<DeleteContactOfUser>(
    EndpointKey.DELETE_CONTACT_OF_USER,
    useApi().deleteContactOfUser(),
    "DELETE",
    {},
    [EndpointKey.USER_CONTACTS_GEIDEA]
  )

export const useGetDecryptedData = () =>
  useSet<GetDecryptedData>(
    EndpointKey.DECRYPT_STRING,
    useApi().getDecryptedQR(),
    "POST",
    {},
    []
  )

// added by swati
export const useGetEncryptedData = () =>
  useSet<GetEncryptedData>(
    EndpointKey.ENCRYPT_STRING,
    useApi().getEncryptedQR(),
    "POST",
    {},
    []
  )

export const useTransactionValidationConfig = (
  query: string,
  hitAPI?: boolean
) =>
  useGet<transactionValidtionConfigData>(
    EndpointKey.GET_TRANSACTION_VALIDATION,
    useApi().getTransactionValidation(query || ""),
    { enabled: hitAPI }
  )

export const useGetUserProfileDetails = () =>
  useGet<userProfileDetail>(
    EndpointKey.GET_PROFILE_DETAILS,
    useApi().getProfileDetails(),
    {}
  )

export const useSavePasscodeHash = () =>
  useSet<savePasscodeHashData>(
    EndpointKey.SAVE_PASSCODE,
    useApi().savePasscode(),
    "POST",
    {},
    [EndpointKey.GET_PROFILE_DETAILS]
  )

export const useGetMappedLanguages = (query?: string) =>
  useGet<getMappedLanguagesType>(
    [EndpointKey.GET_MAPPED_LANGUAGES, query],
    useApi().getMappedLanguages(query || ""),
    { keepPreviousData: true },
    "uuid"
  )

export const verifyToken = async (token: string) => {
  return fetch(env.PUBLIC_API_URL + "user/account/verifyToken", {
    method: "POST",
    headers: {
      Accept: "*",
      Host: env.PUBLIC_API_URL,
      "Access-Control-Allow-Origin": "*",
      authorization: `Bearer ${await getIdTokenAsync()}`
    }
  })
}

export const saveToken = async (token: string) => {
  return fetch(env.PUBLIC_API_URL + "user/account/saveToken", {
    method: "POST",
    headers: {
      Accept: "*",
      Host: env.PUBLIC_API_URL,
      "Access-Control-Allow-Origin": "*",
      authorization: `Bearer ${await getIdTokenAsync()}`
    }
  })
}
