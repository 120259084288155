/* eslint-disable @typescript-eslint/no-empty-function */
import React, { PropsWithChildren, useState } from "react"

type SearchContextType = {
  isSearch: boolean
  setIsSearch: (value: boolean) => void
}
export const SearchContext = React.createContext<SearchContextType>({
  isSearch: false,
  setIsSearch: () => {}
})

type FilterContextType = {
  isFilter: boolean
  setIsFilter: (value: boolean) => void
}
export const FilterContext = React.createContext<FilterContextType>({
  isFilter: false,
  setIsFilter: () => {}
})
type AddContactContextType = {
  isAddContact: boolean
  setAddContact: (value: boolean) => void
}
export const AddContactContext = React.createContext<AddContactContextType>({
  isAddContact: false,
  setAddContact: () => {}
})
type Props = PropsWithChildren<{}>

export const SearchContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [isSearch, setIsSearch] = useState(false)
  const [isFilter, setIsFilter] = useState(false)
  const [isAddContact, setAddContact] = useState(false)

  return (
    <>
      <SearchContext.Provider
        value={{
          isSearch,
          setIsSearch
        }}
      >
        <FilterContext.Provider
          value={{
            isFilter,
            setIsFilter
          }}
        >
          <AddContactContext.Provider
            value={{
              isAddContact,
              setAddContact
            }}
          >
            {children}
          </AddContactContext.Provider>
        </FilterContext.Provider>
      </SearchContext.Provider>
    </>
  )
}
