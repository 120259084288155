import React, { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { notification } from "antd"
import { useTranslation } from "react-i18next"
import { useGetJwtToken } from "src/api/user"
import { RouteType } from "src/constants/routeTypes"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useDispatch } from "react-redux"
import { setAccounts } from "src/utils/slices/accountsSlice"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const AutoLogin = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const institutionId = searchParams.get("institutionId")
  const email = searchParams.get("userId")
  const accounts = searchParams.getAll("account[]")

  if (accounts.length >= 0) {
    // Dispatch the setAccounts action
    dispatch(setAccounts(accounts))

    // store in local storage
    localStorage.setItem("accounts", JSON.stringify(accounts))
  }

  if (institutionId) {
    const functionInstitution = async () => {
      await localStorage.removeItem("institutionToken")

      localStorage.setItem("institutionToken", institutionId)
    }
    functionInstitution()
  }

  if (institutionId) {
    localStorage.setItem("institutionToken", institutionId)
  }

  const query = `institutionId=${institutionId}&username=${email}`
  const {
    mutate: getJwtToken,
    error: jwtTokenError,
    data: jwtTokenDetails
  } = useGetJwtToken(query)

  useEffect(() => {
    getJwtToken({ username: "", password: "" })
  }, [])

  useEffect(() => {
    if (institutionId) {
      localStorage.setItem("institutionToken", institutionId)
    }

    if (email) {
      // Clear local storage
      // localStorage.clear()
      localStorage.removeItem("email")
      localStorage.removeItem("token")

      localStorage.setItem("email", email)
    }

    if (jwtTokenDetails) {
      if (jwtTokenDetails?.jwtToken == "") {
        navigate(RouteType.AUTH_ERROR, {
          state: { jwtTokenDetails }
        })
      } else {
        localStorage.setItem("token", jwtTokenDetails.jwtToken)
        localStorage.removeItem("previusLocation")

        navigate(RouteType.AUTH_CHECK)
      }
    }

    if (jwtTokenError) {
      navigate(RouteType.AUTH_ERROR)
    }
  }, [jwtTokenDetails, jwtTokenError])

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <Spin style={{ color: "#000", marginLeft: "5px" }} indicator={antIcon} />
    </div>
  )
}

export default AutoLogin
