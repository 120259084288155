import { useEffect, useState } from "react"
import { usePublicApiUrl } from "src/api"
import { useApi } from "src/api/constants"

import i18n from "../../../i18n"

interface UseFetchTranslationsConfig {
  selectedLanguageFile?: string
  defaultLanguageCode?: string
  fallbackLanguageCode?: string
  languageKey?: string
}

const useFetchTranslations = (
  config: UseFetchTranslationsConfig = {},
  dependencies: any
) => {
  const {
    selectedLanguageFile,
    defaultLanguageCode = "en",
    fallbackLanguageCode = "en",
    languageKey = "translation"
  } = config

  const [languages, setLanguages] = useState<any[]>([])
  const [token, setToken] = useState<string | null>(null)
  const [translations, setTranslations] = useState<any>(null)
  const [error, setError] = useState<string | null>(null)

  const apiEndPoint = `${usePublicApiUrl()}${useApi().getMappedLanguages()}`

  const getToken = () => localStorage.getItem("institutionToken")

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const token = await waitForToken()
        setToken(token)

        if (token) {
          const { translations, languages } = await loadTranslations(token)
          setTranslations(translations)
          setLanguages(languages)
          i18n.addResourceBundle(
            defaultLanguageCode,
            languageKey,
            translations,
            true,
            true
          )
          i18n.changeLanguage(fallbackLanguageCode)
        }
      } catch (error: any) {
        setError(error.message)
      }
    }

    if (selectedLanguageFile && dependencies.length > 0) {
      const fetchSelectedTranslations = async () => {
        try {
          const response = await fetch(selectedLanguageFile)
          const translations = await response.json()

          setTranslations(translations)

          i18n.addResourceBundle(
            defaultLanguageCode,
            languageKey,
            translations,
            true,
            true
          )

          i18n.changeLanguage(defaultLanguageCode)
        } catch (error: any) {
          setError(error.message)
        }
      }
      fetchSelectedTranslations()
    } else {
      fetchTranslations()
    }
  }, [selectedLanguageFile, dependencies])

  const waitForToken = () =>
    new Promise<string>((resolve, reject) => {
      const checkToken = () => {
        const token = getToken()
        if (token) {
          resolve(token)
        } else {
          setTimeout(checkToken, 100)
        }
      }
      checkToken()
    })

  const loadTranslations = async (token: string) => {
    try {
      const query = `institutionId=${token}`
      const response = await fetch(apiEndPoint + query)
      const data = await response.json()

      let defaultResourceFileURL = data?.find(
        (item: any) => item.isDefault
      )?.resourceFileURL

      if (!defaultResourceFileURL && data.length > 0) {
        defaultResourceFileURL = data[0]?.resourceFileURL
      }

      // get Localstorage User Specific
      const localstorageUserSpecific = localStorage.getItem(
        `${localStorage.getItem("email")}`
      )

      const lang_code =
        localstorageUserSpecific !== null
          ? JSON.parse(localstorageUserSpecific).selectedLanguage
          : null

      if (data.length > 0) {
        const oldFile =
          lang_code !== null
            ? data?.find((item: any) => item.languageName === lang_code)
                ?.resourceFileURL
            : null

        if (oldFile) {
          defaultResourceFileURL = oldFile
        }
      }

      let fileUrl = selectedLanguageFile || defaultResourceFileURL

      let translationResponse = await fetch(fileUrl)

      // If file missing, fall back to
      if (translationResponse.status === 403) {
        fileUrl = data?.find(
          (item: any) => item.languageName === "English"
        )?.resourceFileURL

        translationResponse = await fetch(fileUrl)
      }

      const translations = await translationResponse.json()

      data.sort((a: any, b: any) =>
        a.languageDisplayName.localeCompare(b.languageDisplayName)
      )

      if (lang_code) {
        data.sort((a: any, b: any) => {
          if (a.languageName === lang_code) return -1
          if (b.languageName === lang_code) return 1
          return 0
        })
      } else {
        data.sort((a: any, b: any) => b.isDefault - a.isDefault)
      }

      return { translations, languages: data }
    } catch (error) {
      console.error("Error fetching translations:", error)
      throw error
    }
  }

  return { languages, token, translations, error, setToken }
}

export default useFetchTranslations
