import React, { useState, useContext, useEffect } from "react"
import {
  Input,
  Drawer,
  DrawerProps,
  Space,
  Button,
  Col,
  Select,
  Form,
  Popconfirm,
  AutoComplete,
  notification
} from "antd"
import { SearchOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons"
import DOMPurify from "dompurify"
import { useTranslation } from "react-i18next"
import { AddContactContext, FilterContext } from "src/context/Search"
import {
  useAddUserContact,
  useDeleteContactOfUser,
  useUpdateUserContact,
  useUserByEmailOrMobile
} from "src/api/user"

import { CapitalizeFirstLetter } from "../../utils/Utils"

const Option = Select.Option

type Props = {
  isOpen: boolean
  setContactPopup: (value?: string) => void
  contact: any
  updateContact: (value?: boolean) => void
}

interface IUser {
  email: string
  phoneNumber: string
}

const AddContact: React.FC<Props> = ({
  setContactPopup,
  isOpen,
  contact,
  updateContact
}: Props) => {
  const { t } = useTranslation()
  const { setAddContact } = useContext(AddContactContext)
  const [open, setOpen] = useState<boolean>(isOpen)
  const [placement] = useState<DrawerProps["placement"]>("bottom")
  const [query, setQuery] = useState<string>("email=")
  const [userList, setUserList] = useState<any>([])
  const [selectedOption, setSelectedOption] = useState<any>()
  const [nickname, setNickName] = useState("")
  const [inputFieldError, setInputFieldError] = useState(false)
  const isEditing = contact != undefined
  const useSaveUserContact = (isEditing: boolean) => {
    const addUserContactQueryResult = useAddUserContact()
    const updateUserContactQueryResult = useUpdateUserContact()

    return isEditing ? updateUserContactQueryResult : addUserContactQueryResult
  }
  const selectRef = React.useRef(null)

  const {
    mutate: saveUserContact,
    isLoading: saveContactLoading,
    error: saveContactError,
    isSuccess: saveContactSuccess,
    reset: saveContactReset,
    data: contactData
  } = useSaveUserContact(isEditing)
  const {
    data: userByEmailMobileData,
    isSuccess: isUserByEmailMobileSuccess,
    refetch: userByEmailMobileRefetch,
    isFetching: isUserByEmailMobileFetching,
    isFetched: isUserByEmailMobileFetched,
    error: userByEmailMobileError
  } = useUserByEmailOrMobile(query)

  const {
    mutate: deleteContact,
    isLoading: isdeleteContactLoading,
    error: isDeleteContactError,
    isSuccess: isDeleteContactSuccess
  } = useDeleteContactOfUser()

  useEffect(() => {
    // for edit or delete the user
    if (contact) {
      setSelectedOption(contact)
      setNickName(contact.nickname)
    }
  }, [])

  useEffect(() => {
    if (userByEmailMobileData) {
      setUserList(userByEmailMobileData)
    }
  }, [userByEmailMobileData])

  useEffect(() => {
    // API error handling
    if (saveContactError) {
      showNotifications("error", saveContactError.message)
    }
    if (userByEmailMobileError) {
      showNotifications("error", userByEmailMobileError.message)
    }
    if (isDeleteContactError) {
      showNotifications("error", isDeleteContactError.message)
    }
  }, [saveContactError, userByEmailMobileError, isDeleteContactError])

  useEffect(() => {
    console.log("resp", isDeleteContactSuccess)
    if (saveContactSuccess || isDeleteContactSuccess) {
      setOpen(false)
      setAddContact(false)

      if (isEditing) {
        updateContact(true)
        setSelectedOption(undefined)
        setNickName("")
      }

      if (isDeleteContactSuccess) {
        showNotifications("success", t("contactDeletedSuccessfully"))
      }
      // Showing alert update
      if (!isDeleteContactSuccess) {
        if (isEditing) {
          showNotifications("success", t("contactUpdatedSuccessfully"))
        } else {
          showNotifications("success", t("contactAddedSuccessfully"))
        }
      }
    }
  }, [saveContactSuccess, isDeleteContactSuccess])

  const onChange = (key: number, element: object) => {
    setSelectedOption(element)
    setInputFieldError(false)
  }

  const onHandleChangeNicknameInput = (value: string) => {
    // Regular expression to allow only alphanumeric characters
    const pattern = /^[a-zA-Z0-9 ]*$/
    if (pattern.test(value)) {
      const sanitizedInput = DOMPurify.sanitize(value)
      setNickName(sanitizedInput)
      setInputFieldError(false)
    }
  }

  const onClose = () => {
    setOpen(false)
    setAddContact(false)
    if (isEditing) {
      updateContact(true)
      setSelectedOption(undefined)
      setNickName("")
    }
  }

  const onSaveContact = () => {
    if (!selectedOption) {
      setInputFieldError(true)
      return
    }

    setInputFieldError(false)

    const obj: any = {
      cognitoUsername: selectedOption.cognitoUsername,
      nickname: null
    }

    if (nickname !== null && nickname !== "") {
      obj.nickname = CapitalizeFirstLetter(nickname?.toLocaleLowerCase())
    } else {
      obj.nickname = null
    }

    saveUserContact(obj)
  }

  const deleteContactConfirmation = () => {
    deleteContact({
      nickname: selectedOption.nickname,
      cognitoUsername: selectedOption.cognitoUsername
    })
  }

  const showNotifications = (
    type: string,
    description: string,
    message: any = t("anErrorOccurred")
  ) => {
    // notification.destroy(1)
    if (type === "success") {
      notification["success"]({
        message: t("success"),
        description,
        placement: "bottom"
      })
    } else {
      notification["error"]({
        message: message,
        description,
        placement: "bottom"
      })
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target }: any = event

    // Prevent input if it exceeds 50 characters
    if (target.value.length >= 50) {
      event.preventDefault()
    }
  }

  return (
    <>
      <Drawer
        title={isEditing ? t("editContact") : t("addContact")}
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
        size="large"
        extra={
          <Space>
            <h6 onClick={onClose}>{t("cancel")}</h6>
          </Space>
        }
      >
        <div className="row">
          <div className="search mb-2 mt-2">
            {!isEditing && (
              <Form.Item label={t("searchUserByUserIdOrPhoneNumber")}>
                <AutoComplete
                  size="large"
                  showSearch
                  placeholder={t("userIdOrPhoneNumber")}
                  optionFilterProp="children"
                  listHeight={128}
                  notFoundContent={t(
                    "noUserWasFoundForTheGivenContactInformation"
                  )}
                  onSelect={(key, element) => onChange(key, element)}
                  filterOption={(inputValue, option: any) =>
                    option.email
                      .toLowerCase()
                      ?.includes(inputValue.toLowerCase()) ||
                    option.phoneNumber?.includes(inputValue)
                  }
                  status={inputFieldError ? "error" : undefined}
                  className="topup-input"
                >
                  {userList.map((element: any, key: number) => {
                    //console.log("inside userlist ", element)
                    return (
                      <>
                        <Option
                          key={key}
                          value={element?.email}
                          email={element?.email}
                          phoneNumber={element.phoneNumber}
                          cognitoUsername={element.cognitoUsername}
                        >
                          <span>{element.email}</span>
                          <br />
                          <span style={{ color: "#a39898" }}>
                            {element.phoneNumber}
                          </span>
                        </Option>
                      </>
                    )
                  })}
                </AutoComplete>
                <DownOutlined
                  style={{
                    position: "absolute",
                    top: "1px",
                    right: "7px",
                    background: "#fff",
                    width: "15px",
                    height: "16px",
                    padding: "14px"
                  }}
                />
                <SearchOutlined
                  style={{
                    position: "absolute",
                    top: "3px",
                    right: "7px",
                    background: "#fff",
                    width: "15px",
                    height: "16px",
                    padding: "14px"
                  }}
                />
              </Form.Item>
            )}

            <label className="mb-1 main-font-color">{t("nickname")}</label>
            <Input
              size="large"
              placeholder={t("nickname")}
              value={nickname}
              allowClear={{
                clearIcon: <CloseOutlined style={{ color: "#000" }} />
              }}
              onChange={(e) => onHandleChangeNicknameInput(e.target.value)}
              style={{ height: "50px" }}
              onKeyPress={handleKeyPress}
            />
            {selectedOption && Object.keys(selectedOption).length !== 0 ? (
              <div>
                <table className="customTable table">
                  <tbody>
                    <tr
                      className="tItemAlign"
                      style={{
                        height: "70px"
                      }}
                    >
                      <td>
                        <span
                          className="tItem1 dot"
                          style={{ marginTop: "15px" }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              left: "13px",
                              top: "6px",
                              fontSize: "15px"
                            }}
                          >
                            {nickname === null || nickname === ""
                              ? selectedOption.email[0]?.toUpperCase()
                              : nickname[0]?.toUpperCase()}
                          </span>
                        </span>
                        <span
                          className="tItem2"
                          style={{
                            marginTop: "5px",
                            fontSize: "14px",
                            marginLeft: "35px",
                            color: "#a39898"
                          }}
                        >
                          {nickname === ""
                            ? isEditing
                              ? t("enterNickname")
                              : t("newContact")
                            : nickname}
                        </span>
                        <span
                          className="tItem3"
                          style={{
                            marginTop: "25px",
                            marginLeft: "35px",
                            color: "#000"
                          }}
                        >
                          {selectedOption.email}
                        </span>
                        <span
                          className="tItem5"
                          style={{
                            marginLeft: "33px",
                            marginTop: "45px",
                            color: "#a39898"
                          }}
                        >
                          {selectedOption.phoneNumber}
                        </span>
                        <span className="tItem4">
                          {!isEditing && (
                            <img
                              onClick={() => setAddContact(true)}
                              src="/images/navigation/AddNewUser.svg"
                              style={{
                                width: "30px",
                                marginRight: "30px",
                                marginTop: "11px"
                              }}
                              className="active-tab"
                            />
                          )}
                          {isEditing && (
                            <Popconfirm
                              placement="top"
                              title={t("deleteContact")}
                              description={t(
                                "doYouReallyWishToDeleteTheContact"
                              )}
                              onConfirm={deleteContactConfirmation}
                              okText={t("delete")}
                              cancelText={t("cancel")}
                            >
                              <img
                                src="/images/deleteusersvg.svg"
                                style={{ width: "30px" }}
                                //className="active-tab"
                              />
                            </Popconfirm>
                          )}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : null}
            <Col className="mt-5">
              <Button
                style={{
                  width: "100%",
                  height: "50px",
                  top: "160px",
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#000"
                }}
                className="alignCenter active-btn-style"
                size={"large"}
                onClick={() => onSaveContact()}
              >
                {isEditing ? t("saveChanges") : t("addContact")}
              </Button>
            </Col>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default AddContact
